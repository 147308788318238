import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import UnitService from "../../../../../API/orders/warehouse/UnitService";
import EstimateService from "../../../../../API/orders/estimate/organization/EstimateService";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import {estimateStatuses} from "../../../../../data/EstimateState";
import ModalOpenSetStatus from "./ModalOpenSetStatus";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";

const RequestsPurchaseTable = () => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const navigate = useNavigate();
    const [currents, setCurrents] = useState({});
    const [change, setChange] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [modalStatusOpen, setModalStatusOpen] = useState(false);
    const [estimateState, setEstimateState] = useState(null);

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    useEffect(() => {
        EstimateService.getAllNotCalculationAndNotArchive().then(function (response) {
            const data = response.data;
            setData(data);
            data.forEach((row) => {
                currents[row.id] = row.current;

            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        UnitService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };


    const handleDeleteRow = useCallback(
        (row) => {
            UnitService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const redirectOrders = () => {
        navigate('/organization/orders/');
    };

    const updateStatusByIds = async () => {
        EstimateService.setStatus(selectedIds, estimateState)
            .then(function () {
            })
            .catch(function (error) {
                console.log(error);
            })
        handleCloseModalStatus();
    }

    const handleCloseModalStatus = () => {
        setModalStatusOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                id: 'number',
                header: 'Номер',
                accessorKey: 'number',
                minSize: 20,
                maxSize: 20,
            },
            {
                id: 'order',
                header: 'Заказ',
                accessorKey: 'order.number',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/requests-purchase/' + row.original.id + '/' + row.original.order.id + '/' + row.original.order.number + '/' + row.original.estimateState}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimateState',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },
            {
                accessorFn: (row) => {
                    if (row.date === null) {
                    } else {
                        return new Date(row.date)
                    }
                },
                id: 'date',
                header: 'Дата',
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!row.original.current ?
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>}
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
                getRowId={(originalRow) => originalRow.id}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderTopToolbarCustomActions={({table}) => {
                    const setStatus = () => {
                        setEstimateState(Object.keys(estimateStatuses)[0])
                        setModalStatusOpen(true)
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                    };

                    return (
                        <div>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectOrders}
                                    variant="contained"
                                >
                                    Вернуться в заказы
                                </Button>
                                {/*    <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={() => setStatus()}
                                    variant="contained"
                                >
                                    Установить статус
                                </Button>*/}

                            </Box>
                        </div>
                    );
                }}
            />
            <ModalOpenSetStatus
                title={'Изменить статус'}
                label={'статус'}
                modalOpen={modalStatusOpen}
                handleClose={handleCloseModalStatus}
                setValue={setEstimateState}
                handleSubmit={updateStatusByIds}
            />
        </>
    );
};

export default RequestsPurchaseTable;