import {Shape} from "three";

export const createShape = (data) => {
    const shape = new Shape();
    shape.moveTo(data[0].x, data[0].y)
    data.forEach((el) => {
        if (el.arcParams) {
            shape.absarc(el.arcParams.x, el.arcParams.y, el.arcParams.radius, el.arcParams.startAngle, el.arcParams.endAngle, el.arcParams.clockwise)
        } else {
            shape.lineTo(el.x, el.y)
        }
    })

    return shape
}
