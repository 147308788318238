import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class NotchControllerService {
    static async add(detailId, data) {
        return await axios.post(`${detailControllerRestUrl}1/details/${detailId}/notches/rect`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(detailId, notchId) {
        return await axios.delete(`${detailControllerRestUrl}1/details/${detailId}/notches/${notchId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async put(detailId, notchId,data) {
        return await axios.put(`${detailControllerRestUrl}1/details/${detailId}/notches/${notchId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async addRound(detailId, data) {
        return await axios.post(`${detailControllerRestUrl}1/details/${detailId}/round-notches`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteRound(detailId, notchId) {
        return await axios.delete(`${detailControllerRestUrl}1/details/${detailId}/round-notches/${notchId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async putRound(detailId, notchId, data) {
        return await axios.put(`${detailControllerRestUrl}1/details/${detailId}/round-notches/${notchId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
