import React, {useEffect, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import MaterialPriceUnitService from "../../../../../../API/orders/warehouse/MaterialPriceUnitService";
import {useNavigate} from "react-router-dom";
import {ArrivalMaterialPriceUnitColumns} from "../../../../../../colums/ArrivalMaterialPriceUnitColumns";
import {Button} from "@mui/material";

const ArrivalMaterialPriceUnitTable = ({
                                           arrivalMaterialId, currentCurrency,
                                           baseCurrency, alternativeCurrency
                                       }) => {

        const [isFullScreen, setIsFullScreen] = useState(false);

        const [data, setData] = useState([]);

        useEffect(() => {
            MaterialPriceUnitService.getAllByArrivalMaterialId(parseInt(arrivalMaterialId)).then(function (response) {
                let data = response.data;
                setData(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const navigate = useNavigate();

        const redirectByArrivalMaterials = () => {
            navigate('/materials-arrival');
        };

        const redirectByArrivalMaterialsArchive = () => {
            navigate('/materials-arrival-archive');
        };

        const getIsBaseCurrency = () => {
            return currentCurrency !== baseCurrency;
        };

        const getIsAlternativeCurrency = () => {
            return currentCurrency !== alternativeCurrency;
        };

        const columns = ArrivalMaterialPriceUnitColumns({
            data,
            setData,
            currentCurrency,
            alternativeCurrency,
            baseCurrency
        }).columns;

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                            exchangeRateToBaseCurrencyBaseMaterials: getIsBaseCurrency(),
                            exchangeRateToAlternativeCurrencyBaseMaterials: getIsAlternativeCurrency(),
                            priceInCurrencyBase: getIsBaseCurrency(),
                            priceInCurrencyAlternative: getIsAlternativeCurrency(),
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={() => {
                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => redirectByArrivalMaterials()}
                                        variant="contained"
                                    >
                                        Вернутся в заявки
                                    </Button>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => redirectByArrivalMaterialsArchive()}
                                        variant="contained"
                                    >
                                        Вернутся в архив
                                    </Button>
                                </Box>
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
;

export default ArrivalMaterialPriceUnitTable;