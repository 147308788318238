import React from 'react';
import {TextField} from "@mui/material";
import {Box} from "@mui/material";

const TextFieldValidation = ({
                                 children,
                                 select,
                                 type = "text",
                                 name,
                                 value,
                                 label,
                                 disabled,
                                 setCurrValues,
                                 validationError,
                                 setValidationState,
                                 required=true
                             }) => {

    const onChange = (e) => {
        if (select) {
            setValidationState((errors) => Object.keys(errors).reduce((acc, it) => ({...acc, [it]: ""}), {}))
        }

        if (validationError) {
            setValidationState((errors) => {
                return {...errors, [name]: ""}
            })
        }
        setCurrValues((values) => {
            return {...values, [name]: e.target.value}
        })
    }

    return (<Box height={70}>
        <TextField
            type={type}
            select={select}
            error={!!validationError}
            helperText={validationError}
            size={"small"}
            margin="dense"
            sx={{
                width: '100%', gap: '0.01rem',
            }}
            required={required}
            label={label}
            name={name}
            disabled={disabled}
            value={value}
            onChange={onChange}
        >{children}</TextField>
    </Box>);
};

export default TextFieldValidation;
