import React, {useState} from 'react';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Box, Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {COLORS} from "../../../../../../Constants";
import FormControl from "@mui/material/FormControl";

const EdgeModalContent = ({colorEdge, onClose, onSubmit}) => {
    const [selectedValue, setSelectedValue] = useState(colorEdge[0].id);

    return (
        <>
            <DialogTitle textAlign="center"> Добавить кромку</DialogTitle>
            <DialogContent style={{paddingBottom: 0}}>
                <FormControl component="fieldset" sx={{minWidth: 500}}>
                    <RadioGroup
                        aria-label="edge"
                        name="radio-buttons-group"
                    >
                        {colorEdge.map((el, i) => (
                            <FormControlLabel
                                style={{borderBottom: "1px solid black", margin: 0}}
                                key={i} checked={selectedValue === el.id}
                                control={<Radio onChange={() => setSelectedValue(el.id)} sx={{
                                    padding: 0,
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 15,
                                    },
                                }}/>}
                                label={<Box style={{display: "flex", alignItems: "center",}}>
                                    <Box style={{
                                        width: "13px",
                                        height: "13px",
                                        borderRadius: "4px",
                                        margin: "5px",
                                        backgroundColor: COLORS[el.id][400],

                                    }}/>
                                    <span style={{
                                        fontSize: "12px",
                                        width: "130px"
                                    }}>{el.articleNumber}</span>
                                    <span style={{fontSize: "12px"}}>{el.name}</span>
                                </Box>}>
                            </FormControlLabel>
                        ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button size="small" onClick={onClose}> отменить </Button>
                <Button size="small" color="secondary" onClick={() => onSubmit(selectedValue)} variant="contained">
                    выбрать
                </Button>
            </DialogActions>
        </>
    )
}
export default EdgeModalContent
