import React from 'react';

const PriceWithCurrency = ({cell, row}) => {
    return (
        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
            <div style={{display: 'flex', alignItems: 'flex-start', height: '13px', lineHeight: '13px'}}>
                {cell === null ? '' : `${cell.getValue()} ${row.original.materialPriceUnit.materialPriceInCurrencyArrival.currency?.curAbbreviation}`}
            </div>
        </div>
    );
}

export default PriceWithCurrency;


