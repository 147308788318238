import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Stack, Tooltip} from "@mui/material";
import {Cached, Delete} from "@mui/icons-material";
import MaterialEstimateUnitService
    from "../../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import ModalConfirm from "../../../../modal/ModalConfirm";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import {Link} from "react-router-dom";
import ReplacementSimilarMaterials
    from "../../../../../../pages/organization/orders/estimate/ReplacementSimilarMaterials";

const CalculationTable = ({
                              data,
                              setData,
                              baseCurrency,
                              alternativeCurrency,
                              estimateId,
                              orderId,
                              orderNumber,
                              orderHeaderId,
                              year,
                              isButtonPurchase,
                              isActual,
                              enableEditing,
                              inWork
                          }) => {

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
    const [rowSelection, setRowSelection] = useState({});

    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
    const [base, setBase] = useState(true);
    const [alternative, setAlternative] = useState(false);

    const [change, setChange] = useState(false);
    const [currentInWork, setCurrentInWork] = useState(inWork);

    const [searchSimilarMaterials, setSearchSimilarMaterials] = useState(false);

    const sumAmountBasePriceWithJobPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.basePriceWithJobPrice !== null) {
                return acc + mEU.basePriceWithJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.amountAlternativePrice !== null) {
                return acc + mEU.amountAlternativePrice;
            } else {
                return acc;
            }
        }, 0),

        [data]
    );

    const sumCalculationAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.calculationAmountBasePrice !== null) {
                return acc + mEU.calculationAmountBasePrice;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumProfitCalculationBaseMaterialPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.profitCalculationBaseMaterialPrice !== null) {
                return acc + mEU.profitCalculationBaseMaterialPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumProfitCalculationBaseJobPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.profitCalculationBaseJobPrice !== null) {
                return acc + mEU.profitCalculationBaseJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumProfitCalculationBaseTotalPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.profitCalculationBaseTotalPrice !== null) {
                return acc + mEU.profitCalculationBaseTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const averageCalculationGrossMargin = useMemo(
        () => {
            if (data.length === 0) {
                return 0;
            }
            const totalGrossMargin = data.reduce((acc, mEU) => acc + mEU.calculationGrossMargin, 0);
            return totalGrossMargin / data.length;
        },
        [data]
    );

    const sumCalculationCostJobAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.calculationAmountBaseJobPrice !== null) {
                return acc + mEU.calculationAmountBaseJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationCostTotalAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.totalCostBasePrice !== null) {
                return acc + mEU.totalCostBasePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.calculationAmountAlternativePrice, 0),
        [data]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            const materialId = row.original.id
            MaterialEstimateUnitService.delete(id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                    materialPriceUnits.delete(materialId);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleSubmitSetPurchase = async () => {
        EstimateService.setPurchase(estimateId)
            .then(function () {
                handleCloseSetPurchase()
                setCurrentInWork(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleCloseSetPurchase = () => {
        setSetPurchaseModalOpen(false);
    };

    const openSearchSimilarMaterials = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setSearchSimilarMaterials(true)
        },
        [],
    );

    const materialReplacement = () => {
        setSetPurchaseModalOpen(false);
    };

    const selectInMaterialOOId = async (row) => {
        const id = currentEditRowData.id
        MaterialEstimateUnitService.replacementByMaterialOrganizationOptionId(id, row.original.id)
            .then(function () {
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const selectInMaterialId = async (row) => {
        const id = currentEditRowData.id
        MaterialEstimateUnitService.replacementByMaterialId(id, row.original.id)
            .then(function () {
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const sumAmountAlternativePrice2 = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativePriceWithJobPrice && mEU.alternativePriceWithJobPrice.price !== 0) {
                return acc + mEU.alternativePriceWithJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationAmountBasePrice2 = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.basePriceWithJobPrice && mEU.basePriceWithJobPrice.price !== 0) {
                return acc + mEU.basePriceWithJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.basePrice && mEU.basePrice.price !== 0) {
                return acc + mEU.basePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumJobBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseJobPrice && mEU.baseJobPrice.price !== 0) {
                return acc + mEU.baseJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumJobAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeJobPrice && mEU.alternativeJobPrice.price !== 0) {
                return acc + mEU.alternativeJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativePrice && mEU.alternativePrice.price !== 0) {
                return acc + mEU.alternativePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.articleNumber : '';
                }
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                            </span>
                    );
                }
            },
            {
                id: 'unit',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.unit.name : row.original.tempUnit.name;
                }
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return (row.original.materialOrganizationOption === null) ?
                        '' : row.original.materialOrganizationOption.typeMaterial === 'SLAB' ?
                            (row.original.optimizationQuantity === null || row.original.optimizationQuantity === undefined
                                ? row.original.quantity : row.original.optimizationQuantity.toFixed(3)) : value.toFixed(3);
                },
            },
            {
                id: 'calculationAmountBasePrice',
                header: `СБ мат.`,
                accessorKey: 'calculationAmountBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'calculationAmountBaseJobPrice',
                header: `СБ раб.`,
                accessorKey: 'calculationAmountBaseJobPrice.price',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostJobAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'totalCostBasePrice.price',
                header: `СБ итого:`,
                accessorKey: 'totalCostBasePrice.price',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostTotalAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'calculationAmountAlternativePrice',
                header: `СБ мат. ${alternativeCurrency}`,
                accessorKey: 'calculationAmountAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row, rowIndex}) => {
                    const temp = row.original.materialOrganizationOption === null
                    const value = cell.getValue();
                    const isSlab = temp ? null : row.original.materialOrganizationOption.typeMaterial === 'SLAB';
                    const link = temp ? null : <Link
                        to={'/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId
                            + '/' + year + '/estimates/' + estimateId + '/' + isButtonPurchase + '/reservation/'
                            + row.original.id + '/' + isSlab + '/' + encodeURIComponent(row.original.materialOrganizationOption.name)
                            + '/' + encodeURIComponent(row.original.materialOrganizationOption.articleNumber) + '/' + 'calculation'}>
                        {value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}
                    </Link>
                    return (
                        temp ? '' :
                            row.original.materialOrganizationOption.typeMaterial === 'SLAB' ?
                                row.original.requestsCutting === null ? 'Ожидание' : (
                                    link
                                ) : link
                    );
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'basePrice',
                header: `Материал`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'baseJobPrice',
                header: `Работа`,
                accessorKey: 'baseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumJobBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'basePriceWithJobPrice',
                header: `Итого:`,
                accessorKey: 'basePriceWithJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePriceWithJobPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativePrice',
                header: `Материал ${alternativeCurrency}`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativeJobPrice',
                header: `Работа ${alternativeCurrency}`,
                accessorKey: 'alternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumJobAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativePriceWithJobPrice',
                header: `Итого: ${alternativeCurrency}`,
                accessorKey: 'alternativePriceWithJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'profitCalculationBaseMaterialPrice',
                header: `Прибыль мат.`,
                accessorKey: 'profitCalculationBaseMaterialPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseMaterialPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'profitCalculationBaseJobPrice',
                header: `Прибыль раб.`,
                accessorKey: 'profitCalculationBaseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseJobPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },

            {
                id: 'profitCalculationBaseTotalPrice',
                header: `Прибыль итого:`,
                accessorKey: 'profitCalculationBaseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseTotalPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'calculationGrossMargin',
                header: `Валовая маржа`,
                accessorKey: 'calculationGrossMargin',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {"СР"} {averageCalculationGrossMargin?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                        alternativePrice: alternative,
                        calculationAmountAlternativePrice: alternative,
                        alternativeJobPrice: alternative,
                        alternativePriceWithJobPrice: alternative
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    sorting: [{id: 'unit', desc: false}],
                }}
                enableEditing={false}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!row.original.isBlocked ?
                            <Tooltip arrow placement="left" title="Заменить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => openSearchSimilarMaterials(row)}>
                                    <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                                 onClick={() => openSearchSimilarMaterials(row)}>
                                <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        }
                        {!row.original.isBlocked ? <Tooltip arrow placement="right" title="Удалить">
                            <Button
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip> : <Button
                            size="small"
                            sx={{minWidth: 'auto', marginLeft: 'auto'}}
                            color="error"
                            onClick={() => handleDeleteRow(row)}
                            disabled={true}
                        >
                            <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>}
                    </Box>
                )}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const setCurrency = (typeCurrentCurrency) => {
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                            </Box>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                {/*{isButtonPurchase === 'true' && <Button
                                    size="small"
                                    color="success"
                                    onClick={() => setSetPurchaseModalOpen(true)}
                                    variant="contained"
                                    disabled={!currentInWork}
                                >
                                    Подать заявку на закупку
                                </Button>}*/}
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalConfirm
                title={"Подтвердить создание заявки на закупку?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
            <ReplacementSimilarMaterials
                open={searchSimilarMaterials}
                setOpen={setSearchSimilarMaterials}
                selectInMaterialId={selectInMaterialId}
                selectInMaterialOOId={selectInMaterialOOId}
            />
        </div>
    );
};

export default CalculationTable;

