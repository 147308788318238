import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";
import MaterialPriceUnitService from "../../../../../../API/orders/warehouse/MaterialPriceUnitService";
import PriceWithCurrency from "../PriceWithCurrency";
import MaterialWarehouseService from "../../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../../../сheckbox/CellCheckboxWithUnable";
import CellCheckbox from "../../../../сheckbox/CellCheckbox";
import {materialWarehouseStatuses} from "../../../../../../data/MaterialWarehouseState";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ModalCreateCutOff from "./ModalCreateCutOff";
import ModalSetTrimming from "./ModalSetTrimming";
import MaterialPriceUnitUtil from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import {Link} from "react-router-dom";

const MaterialWarehouseTable = ({
                                    nameMaterial,
                                    isPriceInCurrencyArrival,
                                    isPriceInCurrencyBase,
                                    isPriceInCurrencyAlternative,
                                    isWarehouse,
                                    isPlace,
                                    baseIndents,
                                    tempBaseIndents,
                                    setTempBaseIndents,
                                    data,
                                    setData,
                                    unableCut,
                                    setUnableCut,
                                    isAddCutOffButton,
                                    isIndentsButton,
                                    isActions,
                                    isColumnCut,
                                    isColumnReserve,
                                    baseCurrency,
                                    alternativeCurrency
                                }) => {


        const [materialWarehouseData, setMaterialWarehouseData] = useState(null);
        const [materialPriceUnitData, setMaterialPriceUnitData] = useState(null);
        const [openModalCreateCutOff, setOpenModalCreateCutOff] = useState(false);
        const [openModalSetTrimming, setOpenModalSetTrimming] = useState(false);

        const [selectedRows, setSelectedRows] = useState([]);
        const [selectedIds, setSelectedIds] = useState([]);

        const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
        const [rowSelection, setRowSelection] = useState({});

        const handleUnableCheckboxCut = (id, status) => {
            const materialPriceUnitId = MaterialPriceUnitUtil.getMaterialPriceUnitIdById(id, data);
            MaterialPriceUnitService.unableCut(materialPriceUnitId, status).then(function () {
                handleUnableCut(id, status)
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleUnableCut = (id, status) => {
            unableCut[id] = status
            setUnableCut(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const handleAddCutOff = () => {

            const materialPriceUnit = {
                length: 2800,
                width: 0,
                quantity: 0,
                cut: true,
                cutOff: true,
                trimmingLeft: 20,
                trimmingRight: 20,
                trimmingTop: 20,
                trimmingBottom: 20,
            }

            const materialWarehouse = {
                quantity: 0,
                inReserve: 0,
                materialWarehouseState: "PLANNED",
                materialPriceUnit: null,
                accountOverallBudget: false
            };

            setMaterialPriceUnitData(materialPriceUnit);
            setMaterialWarehouseData(materialWarehouse);
            setOpenModalCreateCutOff(true);
        };


        const handleCloseEditTrimming = () => {
            setTempBaseIndents(baseIndents)
            setOpenModalSetTrimming(false);
        }

        const handleSubmitEditTrimming = () => {

            MaterialPriceUnitService.updateTrimmingByIds(selectedIds,
                tempBaseIndents.trimmingLeft, tempBaseIndents.trimmingRight, tempBaseIndents.trimmingTop,
                tempBaseIndents.trimmingBottom)
                .then(function () {
                    selectedRows.forEach((row) => {
                        row.original.materialPriceUnit.trimmingLeft = tempBaseIndents.trimmingLeft
                        row.original.materialPriceUnit.trimmingRight = tempBaseIndents.trimmingRight
                        row.original.materialPriceUnit.trimmingTop = tempBaseIndents.trimmingTop
                        row.original.materialPriceUnit.trimmingBottom = tempBaseIndents.trimmingBottom
                    });
                    handleCloseEditTrimming();
                })
                .catch(function (error) {
                    console.log(error);
                })
        }


        const handleSubmitCreateCutOff = () => {
            materialWarehouseData.materialPriceUnit = materialPriceUnitData;

            MaterialWarehouseService.createPlanned(materialWarehouseData, nameMaterial).then(function (response) {
                const responseData = response.data;
                handleCreateNewRow(responseData)
                data.forEach((row) => {
                    unableCut[row.id] = row.materialPriceUnit.cut;
                });
                handleCloseCreateCutOff();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const handleCloseCreateCutOff = () => {
            setMaterialWarehouseData(null);
            setOpenModalCreateCutOff(false);
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.original.id;
                const materialId = row.original.id
                MaterialPriceUnitService.delete(id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                        materialPriceUnits.delete(materialId);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'cutOff',
                    header: 'Обрезок',
                    accessorKey: 'materialPriceUnit.cutOff',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckbox
                        cell={cell}
                        disabled={true}
                    />,
                },
                {
                    id: 'length',
                    header: 'W',
                    accessorKey: 'materialPriceUnit.length',
                    minSize: 10,
                    maxSize: 10,
                },
                {
                    id: 'width',
                    header: 'H',
                    accessorKey: 'materialPriceUnit.width',
                    minSize: 10,
                    maxSize: 10,
                },
                {
                    id: 'trimming',
                    header: 'Отступы',
                    accessorKey: 'materialPriceUnit.trimmingLeft',
                    minSize: 10,
                    maxSize: 10,
                    Cell: ({row}) => row.original.materialPriceUnit.cutOff ? '['
                        + row.original.materialPriceUnit.trimmingLeft + ', '
                        + row.original.materialPriceUnit.trimmingRight + ', '
                        + row.original.materialPriceUnit.trimmingTop + ', '
                        + row.original.materialPriceUnit.trimmingBottom + ']' : '',
                },
                {
                    id: 'materialPriceUnit.quantity',
                    header: 'Кол.',
                    accessorKey: 'materialPriceUnit.quantity',
                    minSize: 10,
                    maxSize: 10,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                    },
                },
                {
                    id: 'quantity',
                    header: 'Наличие.',
                    accessorKey: 'quantity',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                    },
                },
                {
                    id: 'materialWarehouseState',
                    header: 'Статус',
                    accessorKey: 'materialWarehouseState',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => materialWarehouseStatuses[cell.getValue()],
                },
                {
                    id: 'note',
                    header: 'Ком.',
                    accessorKey: 'note',
                    size: 8,
                    maxSize: 8,
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    enableHiding: false,
                },
            ];

            if (isColumnCut) {
                baseColumns.splice(1, 0, {
                    id: 'cut',
                    header: 'H',
                    Header: () => (<div>
                        <ContentCutIcon sx={{padding: 0, fontSize: '15px', marginTop: '6px'}}/>
                    </div>),
                    accessorKey: 'materialPriceUnit.cut',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unableCut}
                        setUnable={handleUnableCheckboxCut}
                        disabled={!row.original.materialPriceUnit.cutOff}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            if (isColumnCut) {
                baseColumns.splice(8, 0, {
                    id: 'workingCuttingMap.number',
                    header: 'К. раскроя',
                    accessorKey: 'workingCuttingMap.number',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => (
                        cell.getValue() ? (
                            <li key={cell.getValue()}>
                                <Link
                                    to={'/working-cutting-map/' + row.original.workingCuttingMap.id + '/' + encodeURIComponent(nameMaterial)}>
                                    {cell.getValue()}
                                </Link>
                            </li>
                        ) : ''
                    ),
                });
            }

            if (isColumnReserve) {
                baseColumns.splice(7, 0, {
                    id: 'inReserve',
                    header: 'Резерв',
                    accessorKey: 'inReserve',
                    size: 8,
                    maxSize: 8,
                });
            }

            if (isPriceInCurrencyArrival) {
                baseColumns.splice(9, 0, {
                    id: 'PriceInCurrencyArrival',
                    header: 'Цена в валюте прихода',
                    accessorKey: 'materialPriceUnit.materialPriceInCurrencyArrival.price',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({cell, row}) => row.original.materialPriceUnit.materialPriceInCurrencyArrival === null ? '' :
                        <PriceWithCurrency cell={cell} row={row}/>,
                });
            }
            if (isPriceInCurrencyBase) {
                baseColumns.splice(10, 0, {
                    id: 'PriceInCurrencyBase',
                    header: `Цена в ${baseCurrency}`,
                    accessorKey: 'materialPriceUnit.materialPriceInCurrencyBase.price',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({
                               cell,
                               row
                           }) => row.original.materialPriceUnit.materialPriceInCurrencyBase === null ? '' : cell.getValue(),
                });
            }
            if (isPriceInCurrencyAlternative) {
                baseColumns.splice(11, 0, {
                    id: 'PriceInCurrencyAlternative',
                    header: `Цена в ${alternativeCurrency}`,
                    accessorKey: 'materialPriceUnit.materialPriceInCurrencyAlternative.price',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({
                               cell,
                               row
                           }) => row.original.materialPriceUnit.materialPriceInCurrencyAlternative === null ? '' : cell.getValue(),
                });

            }
            if (isWarehouse) {
                baseColumns.splice(12, 0, {
                    id: 'warehouse.name',
                    header: 'Склад',
                    accessorKey: 'warehouse.name',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({cell, row}) => row.original.warehouse === null ? '' : cell.getValue(),
                });
            }

            if (isPlace) {
                baseColumns.splice(13, 0, {
                    id: 'place',
                    header: 'Место',
                    accessorKey: 'warehouse.name',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                });
            }

            return baseColumns;

        }, [data, setData]);

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                        sorting: [
                            {
                                id: 'materialWarehouseState',
                                desc: false,
                            },]
                    }}
                    enableEditing={isActions}
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection={row => row.original.materialPriceUnit.cutOff}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            {row.original.materialPriceUnit.cutOff && row.original.materialWarehouseState === 'PLANNED' ?
                                <Tooltip arrow placement="right" title="Удалить">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                        <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                    </Button>
                                </Tooltip> : <Button
                                    disabled={true}
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="error"
                                >
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>}
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const setIndents = () => {
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            setSelectedRows(selectedRows);
                            const selectedIds = selectedRows.map((row) => row.original.materialPriceUnit.id);
                            setSelectedIds(selectedIds);
                            setTempBaseIndents(baseIndents);
                            if (selectedIds.length === 1) {
                                const mWH = MaterialPriceUnitUtil.getById(selectedIds[0], data);
                                tempBaseIndents.trimmingLeft = mWH.materialPriceUnit.trimmingLeft
                                tempBaseIndents.trimmingRight = mWH.materialPriceUnit.trimmingRight
                                tempBaseIndents.trimmingTop = mWH.materialPriceUnit.trimmingTop
                                tempBaseIndents.trimmingBottom = mWH.materialPriceUnit.trimmingBottom
                                setTempBaseIndents(tempBaseIndents);
                            }
                            setOpenModalSetTrimming(true)
                        };

                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    {isAddCutOffButton && <Button
                                        size="small"
                                        color="success"
                                        onClick={handleAddCutOff}
                                        variant="contained"
                                    >Добавить обрезок
                                    </Button>}
                                    {isIndentsButton && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => setIndents()}
                                        variant="contained"
                                    >
                                        Установть оступы обрезков
                                    </Button>}
                                </Box>
                            </div>
                        );
                    }}
                />
                <ModalSetTrimming
                    open={openModalSetTrimming}
                    onClose={handleCloseEditTrimming}
                    title={'Редактировать отсупы'}
                    values={tempBaseIndents}
                    setValues={setTempBaseIndents}
                    labelButtonSSubmit={'Установить'}
                    handleSubmit={handleSubmitEditTrimming}
                />
                <ModalCreateCutOff
                    open={openModalCreateCutOff}
                    onClose={handleCloseCreateCutOff}
                    title={'Создать обрезок'}
                    valuesMaterialPriceUnitData={materialPriceUnitData}
                    setValuesMaterialPriceUnitData={setMaterialPriceUnitData}
                    labelButtonSSubmit={'Создать'}
                    handleSubmit={handleSubmitCreateCutOff}
                />
            </div>
        );
    }
;

export default MaterialWarehouseTable;