import React from 'react';
import {TextField} from "@mui/material";

const TextFieldObjectWithEntityListSetValue = ({
                                                   entityList,
                                                   label,
                                                   value,
                                                   setValue,
                                                   excludeStatus,
                                                   minWidthXs,
                                                   minWidthSm,
                                                   minWidthMd,
                                               }) => {

    const currentMinWidthXs = minWidthXs ? minWidthXs : '300px';
    const currentMinWidthSm = minWidthSm ? minWidthSm : '360px';
    const currentMinWidthMd = minWidthMd ? minWidthMd : '400px';

    return (
        <div>
            <TextField
                size={"small"}
                key={label}
                sx={{
                    width: '100%',
                    minWidth: {xs: currentMinWidthXs, sm: currentMinWidthSm, md: currentMinWidthMd},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                value={value === null ? 'all' : value}
                name={label}
                onChange={(e) =>
                    setValue(e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {Object.keys(entityList).map((statusKey) => (
                    excludeStatus !== statusKey && <option key={statusKey} value={statusKey}>
                        {entityList [statusKey]}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default TextFieldObjectWithEntityListSetValue;