import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import RowRadioButtonsGroup from "./RowRadioButtonsGroup";
import MaterialTable from "../../../../components/UI/table/warehouse/material/material/MaterialTable";
import AllMaterialTable from "../../../../components/UI/table/warehouse/material/material/AllMaterialTable";
import ReplacementSimilarMaterialsFilter from "./ReplacementSimilarMaterialsFilter";
import UnitService from "../../../../API/orders/warehouse/UnitService";
import * as TypeMaterial from "../../../../data/TypeMaterial";
import ManufacturerService from "../../../../API/orders/warehouse/manufacturer/ManufacturerService";
import GroupMaterialService from "../../../../API/orders/orders/organization/GroupMaterialService";
import MaterialOrganizationOptionService
    from "../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import UserMaterialMaterialCloudService from "../../../../API/material-cloud/user/UserMaterialMaterialCloudService";

const ReplacementSimilarMaterials = ({
                                         open,
                                         setOpen,
                                         selectInMaterialId,
                                         selectInMaterialOOId
                                     }) => {

    const [dataMaterials, setDataMaterials] = useState([]);
    const [dataAllMaterials, setAllDataMaterials] = useState([]);
    const [selectedValue, setSelectedValue] = useState("material");

    const [typeMaterial, setTypeMaterial] = useState(null);
    const [typeMaterialOrg, setTypeMaterialOrg] = useState(null);
    const [listUnits, setListUnits] = useState([]);
    const [unitId, setUnitId] = useState(null);
    const [unitIdOrg, setUnitIdOrg] = useState(null);
    const [listManufacturers, setListManufacturers] = useState([]);
    const [manufacturerId, setManufacturerId] = useState(null);
    const [listManufacturersOrg, setListManufacturersOrg] = useState([]);
    const [manufacturerIdOrg, setManufacturerIdOrg] = useState(null);
    const [listGroupMaterial, setListGroupMaterial] = useState([]);
    const [groupMaterialId, setGroupMaterialId] = useState(null);
    const [listGroupMaterialOrg, setListGroupMaterialOrg] = useState([]);
    const [groupMaterialIdOrg, setGroupMaterialIdOrg] = useState(null);

    const all = {
        id: 0,
        name: 'Все'
    }

    useEffect(() => {

        UnitService.getAll().then(function (response) {
            let data = response.data;
            data.push(all);
            setListUnits(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        ManufacturerService.getAll().then(function (response) {
            let data = response.data;
            data.push(all);
            setListManufacturers(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        ManufacturerService.getAllByOrganization().then(function (response) {
            let data = response.data;
            data.push(all);
            setListManufacturersOrg(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        GroupMaterialService.getAll().then(function (response) {
            let data = response.data;
            data.push(all);
            setListGroupMaterial(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        GroupMaterialService.getAllByOrganization().then(function (response) {
            let data = response.data;
            data.push(all);
            setListGroupMaterialOrg(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const listTypeMaterial = {...TypeMaterial.typeMaterial, all: "Все"}

    const cleanFilter = () => {
        setTypeMaterial(null);
        setUnitId(unitId);
        setGroupMaterialId(null);
        setManufacturerId(null);
        setGroupMaterialIdOrg(null);
        setManufacturerIdOrg(null);
    };

    const onClose = () => {
        setOpen(false);
        cleanFilter();
    };

    const search = () => {
        const currentTypeMaterial = typeMaterial === 'all' ? null : typeMaterial;
        const currentTypeMaterialOrg = typeMaterialOrg === 'all' ? null : typeMaterialOrg;

        const currentUnitIdOrg = unitIdOrg === 0 ? null : unitIdOrg
        const currentUnitId = unitId === 0 ? null : unitId

        const currentGroupMaterialIdOrg = groupMaterialIdOrg === 0 ? null : groupMaterialIdOrg;
        const currentGroupMaterialId = groupMaterialId === 0 ? null : groupMaterialId;

        const currentManufacturerIdOrg = manufacturerIdOrg === 0 ? null : manufacturerIdOrg;
        const currentManufacturerId = manufacturerId === 0 ? null : manufacturerId;

        if ((selectedValue === 'material' || selectedValue === 'all')) {
            MaterialOrganizationOptionService.getWithFilter(currentTypeMaterialOrg, currentUnitIdOrg, currentGroupMaterialIdOrg, currentManufacturerIdOrg).then(function (response) {
                let data = response.data;
                setDataMaterials(data);

            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        if ((selectedValue === 'allMaterial' || selectedValue === 'all')) {
            UserMaterialMaterialCloudService.getWithFilter(currentTypeMaterial, currentUnitId, currentGroupMaterialId, currentManufacturerId).then(function (response) {
                let data = response.data;
                setAllDataMaterials(data);

            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth='lg'>
                <DialogTitle textAlign="center">Заменить материал</DialogTitle>
                <DialogContent>
                    <Box>
                        <RowRadioButtonsGroup
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                        />

                    </Box>
                    <Box sx={{mt: '0.2rem'}}>
                        {(selectedValue === 'material' || selectedValue === 'all') && (
                            <Box>
                                <ReplacementSimilarMaterialsFilter
                                    typeMaterial={typeMaterialOrg}
                                    setTypeMaterial={setTypeMaterialOrg}
                                    listTypeMaterial={listTypeMaterial}
                                    listUnits={listUnits}
                                    unitId={unitIdOrg}
                                    setUnitId={setUnitIdOrg}
                                    manufacturers={listManufacturersOrg}
                                    setManufacturerId={setManufacturerIdOrg}
                                    setGroupMaterialId={setGroupMaterialIdOrg}
                                    groupMaterial={listGroupMaterialOrg}
                                />
                                <MaterialTable
                                    currentData={dataMaterials}
                                    pageSize={50}
                                    enableEditing={true}
                                    isAddMaterial={false}
                                    isSelectInArrival={false}
                                    isSelectInEstimate={true}
                                    selectInEstimate={selectInMaterialOOId}
                                />
                            </Box>
                        )}
                        {(selectedValue === 'allMaterial' || selectedValue === 'all') && (
                            <Box sx={{mt: '0.8rem'}}>
                                <ReplacementSimilarMaterialsFilter
                                    typeMaterial={typeMaterial}
                                    setTypeMaterial={setTypeMaterial}
                                    listTypeMaterial={listTypeMaterial}
                                    listUnits={listUnits}
                                    unitId={unitId}
                                    setUnitId={setUnitId}
                                    manufacturers={listManufacturers}
                                    setManufacturerId={setManufacturerId}
                                    setGroupMaterialId={setGroupMaterialId}
                                    groupMaterial={listGroupMaterial}
                                />
                                <AllMaterialTable
                                    currentData={dataAllMaterials}
                                    enableRowSelection={false}
                                    pageSize={50}
                                    enableEditing={true}
                                    isAddMaterial={false}
                                    isSelectInArrival={false}
                                    isSelectInEstimate={true}
                                    selectInEstimate={selectInMaterialId}
                                />
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={search}>Поиск</Button>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ReplacementSimilarMaterials;