import {Box} from "@mui/material";
import {COLORS} from "../../../../../Constants";

const EdgeContent = ({colorEdge}) => {

    return <Box style={{
        width: "400px", display: "flex", flexDirection: "column", margin: "0 auto"
    }}>
        {colorEdge.map((el, i) => (<Box style={{display: "flex", alignItems: "center",}} key={i}>
            <Box style={{
                width: "13px",
                height: "13px",
                borderRadius: "4px",
                margin: "2px",
                backgroundColor: COLORS[el.id][400],

            }}/>
            <span style={{fontSize: "12px", width: "110px"}}>{el.articleNumber}</span>
            <span style={{fontSize: "12px"}}>{el.name}</span>
        </Box>))}
    </Box>
}
export default EdgeContent
