import axios, {head} from "axios";
import {gatewayUrl} from "../../Constants";

const userRestRoleUrl = gatewayUrl + '/admin/realms/upmebel/roles'
const userRestUrl = gatewayUrl + '/upme-authorization-proxy-server/api/v1/users'

export default class UserService {

    static async authorized() {
        return axios.head(userRestUrl + "/authorized");
    }

    static async calculation() {
        return axios.head(userRestUrl + "/calculation");
    }

    static async calculationRequestsCutting() {
        return axios.head(userRestUrl + "/calculation-requests-cutting");
    }

    static async calculationOptimizations() {
        return axios.head(userRestUrl + "/calculation-optimizations");
    }

    static async calculationWorkingCuttingMaps() {
        return axios.head(userRestUrl + "/calculation-working-cutting-maps");
    }

    static async ordersUser() {
        return axios.head(userRestUrl + "/orders-user")
    }

    static async constructorsUser() {
        return axios.head(userRestUrl + "/constructors-user")
    }

    static async ordersArchive() {
        return axios.head(userRestUrl + "/orders-archive")
    }

    static async ordersToArchive() {
        return axios.head(userRestUrl + "/orders-to-archive")
    }

    static async ordersSettings() {
        return axios.head(userRestUrl + "/orders-settings")
    }

    static async ordersTechnicalMap() {
        return axios.head(userRestUrl + "/orders-technical-map")
    }

    static async ordersWorkerJob() {
        return axios.head(userRestUrl + "/orders-worker-job")
    }

    static async ordersEstimates() {
        return axios.head(userRestUrl + "/orders-estimates")
    }

    static async optimization() {
        return axios.head(userRestUrl + "/optimization")
    }

    static async requestsCutting() {
        return axios.head(userRestUrl + "/requests-cutting")
    }

    static async requestsCustomizedMaterials() {
        return axios.head(userRestUrl + "/requests-customized-materials")
    }

    static async customizedMaterialsDetails() {
        return axios.head(userRestUrl + "/customized-materials-details")
    }

    static async plan() {
        return axios.head(userRestUrl + "/plan")
    }

    static async optimizationOptimization() {
        return axios.head(userRestUrl + "/optimization-optimization")
    }

    static async storage() {
        return axios.head(userRestUrl + "/storage")
    }

    static async storageMaterials() {
        return axios.head(userRestUrl + "/storage-materials")
    }

    static async storageRequestsPurchase() {
        return axios.head(userRestUrl + "/storage-requests-purchase")
    }

    static async storageReservationPieceMaterial() {
        return axios.head(userRestUrl + "/storage-reservation-piece-material")
    }

    static async storageReservationSlabMaterial() {
        return axios.head(userRestUrl + "/storage-reservation-slab-material")
    }

    static async storageReservationLinearMaterial() {
        return axios.head(userRestUrl + "/storage-reservation-linear-material")
    }

    static async storageMaterialsArrival() {
        return axios.head(userRestUrl + "/storage-materials-arrival")
    }

    static async storageCurrentCurrencies() {
        return axios.head(userRestUrl + "/storage-current-currencies")
    }

    static async storageSettingsStorage() {
        return axios.head(userRestUrl + "/storage-Settings-storage")
    }

    static async cutting() {
        return axios.head(userRestUrl + "/cutting")
    }

    static async cuttingWorkingCuttingMaps() {
        return axios.head(userRestUrl + "/cutting-working-cutting-maps")
    }

    static async cuttingQueueCuttingMaps() {
        return axios.head(userRestUrl + "/cutting-queue-cutting-maps")
    }

    static async cuttingWarehouseFinishedDetail() {
        return axios.head(userRestUrl + "/cutting-warehouse-finished-detail")
    }

    static async orderProduction() {
        return axios.head(userRestUrl + "/order-production")
    }

    static async orderProductionProduction() {
        return axios.head(userRestUrl + "/order-production-production")
    }

    static async orderProductionIssuingEdges() {
        return axios.head(userRestUrl + "/order-production-issuing-edges")
    }

    static async orderProductionIssuancePieceMaterials() {
        return axios.head(userRestUrl + "/order-production-Issuance-piece-materials")
    }

    static async economic() {
        return axios.head(userRestUrl + "/economic")
    }

    static async economicIssuanceMaterialReserve() {
        return axios.head(userRestUrl + "/economic-issuance-material-reserve")
    }

    static async economicInWork() {
        return axios.head(userRestUrl + "/economic-in-work")
    }

    static async materialCloudAdminRoles() {
        return axios.head(userRestUrl + "/material-cloud-admin-roles")
    }

    static async materialCloud() {
        return axios.head(userRestUrl + "/material-cloud")
    }
    static async customer() {
        return axios.head(userRestUrl + "/customer")
    }

    static async getAll() {
        return axios.get(userRestUrl);
    }

    static async getAllRoles() {
        return axios.get(userRestRoleUrl);
    }

    static async getAllRoles2() {
        return axios.get(userRestRoleUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllRolesUser(id) {
        return axios.get(userRestUrl + '/' + id + '/role-mappings/realm');
    }

    static async get(id) {
        return axios.get(userRestUrl + '/' + id);
    }

    static async delete(id) {
        await axios.delete(userRestUrl + '/' + id);
    }

    static async save(data) {
        return await axios.post(userRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data) {
        return await axios.put(userRestUrl + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addRole(id, role) {
        let data = []
        data.push(role)
        return await axios.post(userRestUrl + '/' + id + '/role-mappings/realm', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteRole(id, role) {
        let data = []
        data.push(role)
        /* await fetch(userRestUrl + '/' + id + '/role-mappings/realm', {
             method: 'DELETE',
             ,
             body: JSON.stringify(data),
         })*/
    }

    static async resetPassword(id, data) {
        return await axios.put(userRestUrl + '/' + id + '/reset-password', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
