import React from 'react';
import {Path} from "three";
import {groupBy} from "../../../../../utils/processing/groupByUtils";
import EndFace from "./CanvasComponents/EndFace";
import LineElement from "./CanvasComponents/LineElement";
import {calcEndHoleOutline} from "./CanvasCalcPositions/calcEndHoleOutline";
import Border from "./CanvasComponents/Border";
import Detail from "./CanvasComponents/Detail";
import Notch from "./CanvasComponents/Notch";
import {calcSidePos} from "./CanvasCalcPositions/calcSidePos";
import {calcSideHolePos} from "./CanvasCalcPositions/calcSideHolePos";
import {Text, useTexture} from "@react-three/drei";
import Point from "./CanvasComponents/Point";
import Layer from "./CanvasComponents/Layer";
import {createShape} from "./CanvasCalcPositions/createShape";
import * as THREE from "three";
import image from "../../../../../components/Icons/hatch.png";


const DetailCanvas = ({
                          detailData,
                          editableElement,
                          processingHandle,
                          selectedHole,
                          setSelectedHole,
                          hoveredHole,
                          setHoveredHole,
                          cutData,
                          prevArc,
                          openPopoverActives,
                          ...props
                      }) => {

    const mainContour = createShape(detailData.detailContour.points)
    const notches = detailData.notches.map((el) => ({shape: createShape(el.contour.points), data: el}))

    const groupByHoles = (data) => groupBy(
        (it) => ["TOP", "BOTTOM"].includes(it.contour.position) ? "holes" : "sideHoles",
        data,
    );

    const {holes, sideHoles} = groupByHoles([...detailData.holes, ...detailData.roundNotches]);

    holes?.forEach((el, i) => {
        mainContour.holes.push(new Path().absellipse(holes[i].center.x, holes[i].center.y, holes[i].radius, holes[i].radius, 0, Math.PI * 2))
    })

    const initialDetailShape = [
        {position: 'LEFT', side: [[0, 0], [0, detailData.width]]},
        {position: 'BACK', side: [[0, detailData.width], [detailData.length, detailData.width]]},
        {position: 'RIGHT', side: [[detailData.length, detailData.width], [detailData.length, 0]]},
        {position: 'FRONT', side: [[detailData.length, 0], [0, 0]]},
    ]

    const [textureFromNotchRound, textureFromNotch] = useTexture([image, image])
    textureFromNotchRound.wrapS = THREE.RepeatWrapping;
    textureFromNotchRound.wrapT = THREE.RepeatWrapping;
    textureFromNotchRound.repeat.set(15 * props.scale, 15 * props.scale);
    textureFromNotch.wrapS = THREE.RepeatWrapping;
    textureFromNotch.wrapT = THREE.RepeatWrapping;
    textureFromNotch.repeat.set(0.2 * props.scale, 0.2 * props.scale);

    return (<>
            <Detail shape={mainContour} holes={holes} sideHoles={sideHoles} detailData={detailData}
                    selectedHole={selectedHole}
                    setSelectedHole={setSelectedHole}
                    setHoveredHole={setHoveredHole}
                    processingHandle={processingHandle}
                    texture={textureFromNotchRound}
                    {...props}/>

            {notches.map((el, i) => <React.Fragment key={i}>
                <Notch notch={el} detailData={detailData} scale={props.scale} position={props.position}
                       texture={textureFromNotch}/>
                {el.data.contour.points.map((point, i, arr) => {
                    return i < arr.length - 1 && <React.Fragment key={i}>
                        <Border
                            start={point}
                            next={arr[i + 1]}
                            prev={i === 0 ? arr[arr.length - 2] : arr[i - 1]}
                            editableElement={editableElement}
                            processingHandle={processingHandle}
                            cutData={cutData}
                            scale={props.scale}
                            position={props.position}
                            prevArc={prevArc}
                        />
                        <Point
                            point={point}
                            editableElement={editableElement}
                            scale={props.scale}
                            position={props.position}
                            openPopoverActives={openPopoverActives}/>
                    </React.Fragment>
                })}
            </React.Fragment>)}

            {initialDetailShape.map((el, i) => {
                const holes = sideHoles?.filter(hole => el.position === hole.contour.position)

                return <React.Fragment key={i}>
                    <EndFace side={el.position} holes={holes} {...props} detailData={detailData}
                             selectedHole={selectedHole}
                             hoveredHole={hoveredHole}
                             setSelectedHole={setSelectedHole}
                             setHoveredHole={setHoveredHole}
                             processingHandle={processingHandle}/>
                    <Layer layer={1}>
                        <LineElement key={i} position={props.position} scale={props.scale}
                                     points={[el.side[0], el.side[1]]}
                                     color={"red"} dashSize={0.07} gapSize={0.07}/>
                    </Layer>
                </React.Fragment>
            })}

            {sideHoles?.map(hole => {
                    const {xPos, yPos, x, y} = calcSidePos(hole.contour.position, detailData, props.scale)
                    const {
                        scaleRadius,
                        scaleX,
                        scaleY,
                        nameX,
                        nameY
                    } = calcSideHolePos(hole, hole.contour.position, detailData, props.scale, xPos, yPos, x, y)

                    const xWidthDirection = hole.contour.position === "LEFT" ? x : -x
                    const yWidthDirection = hole.contour.position === "FRONT" ? y : -y
                    const textOffsetY = hole.contour.position === "FRONT" ? 0.4 : hole.contour.position === "BACK" ? -0.4 : -0.2
                    const textOffsetX = hole.contour.position === "LEFT" ? 0.4 : hole.contour.position === "RIGHT" ? -0.4 : -0.2

                    return <React.Fragment key={hole.id}>
                        {
                            (hole.id === hoveredHole) &&
                            <Layer layer={2}>
                                <LineElement
                                    scale={props.scale}
                                    position={[props.position[0] + xPos, props.position[1] + yPos, props.position[2] + props.scale]}
                                    points={[[scaleX / props.scale, scaleY / props.scale], [scaleX / props.scale, yWidthDirection / props.scale / 2]]}
                                    color={"black"}
                                    dashSize={0.05}
                                    gapSize={0.05}
                                />
                                <Text
                                    color={"black"}
                                    fontSize={.2}
                                    position={[props.position[0] + xPos + scaleX, props.position[1] + yPos + yWidthDirection / 2 + textOffsetY, props.position[2] + props.scale]}
                                    maxWidth={6}
                                >{nameY}</Text>

                                <LineElement
                                    scale={props.scale}
                                    position={[props.position[0] + xPos, props.position[1] + yPos, props.position[2] + props.scale]}
                                    points={[[scaleX / props.scale, scaleY / props.scale], [xWidthDirection / props.scale / 2, scaleY / props.scale]]}
                                    color={"black"}
                                    dashSize={0.05}
                                    gapSize={0.05}
                                />
                                <Text
                                    color={"black"}
                                    fontSize={.2}
                                    position={[props.position[0] + xPos + xWidthDirection / 2 + textOffsetX, props.position[1] + yPos + scaleY, props.position[2] + props.scale]}
                                    maxWidth={6}
                                >{nameX}</Text>
                            </Layer>}
                        {calcEndHoleOutline(hole, scaleRadius / props.scale).map((el, i) => {
                            return <Layer layer={0} key={i}>
                                <LineElement
                                    scale={props.scale}
                                    position={[props.position[0], props.position[1], props.position[2]]}
                                    points={[el[0], el[1]]}
                                    color={"red"}
                                    dashSize={0.05} gapSize={0.05}
                                />
                            </Layer>
                        })}
                    </React.Fragment>
                }
            )}
            {holes?.map(hole => {
                if (`${hole.id}${hole.name}` === hoveredHole)
                    return <Layer layer={2} key={`${hole.id}${hole.name}`}>
                        <LineElement
                            scale={props.scale}
                            position={[props.position[0], props.position[1], props.position[2] + props.scale]}
                            points={[[hole.center.x, hole.center.y], [hole.center.x, 0]]}
                            color={"black"}
                            dashSize={0.05}
                            gapSize={0.05}/>
                        <Text
                            color={"black"}
                            fontSize={.2}
                            position={[props.position[0] + hole.center.x * props.scale, props.position[1] - 0.4, props.position[2] + props.scale]}
                            maxWidth={6}
                        >{hole.center.y}</Text>
                        <LineElement
                            scale={props.scale}
                            position={[props.position[0], props.position[1], props.position[2] + props.scale]}
                            points={[[hole.center.x, hole.center.y], [0, hole.center.y]]} color={"black"}
                            dashSize={0.05}
                            gapSize={0.05}/>
                        <Text
                            rotation={[0, 0, 20.4]}
                            color={"black"}
                            fontSize={.2}
                            position={[props.position[0] - 0.4, props.position[1] + hole.center.y * props.scale, props.position[2] + props.scale]}
                            maxWidth={6}
                        >{hole.center.x}</Text>
                    </Layer>
            })}

            {detailData.detailContour.points.map((el, i, arr) => {
                return i < arr.length - 1 && <React.Fragment key={i}>
                    <Border
                        start={el}
                        next={arr[i + 1]}
                        prev={i === 0 ? arr[arr.length - 2] : arr[i - 1]}
                        editableElement={editableElement}
                        processingHandle={processingHandle}
                        cutData={cutData}
                        scale={props.scale}
                        position={props.position}
                        prevArc={prevArc}
                    />
                    <Point
                        point={el}
                        editableElement={editableElement}
                        scale={props.scale}
                        position={props.position}
                        openPopoverActives={openPopoverActives}/>
                </React.Fragment>
            })}
        </>
    )
}

export default DetailCanvas
