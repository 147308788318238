import React, {useMemo, useState} from 'react';
import {Box, Button} from "@mui/material";
import HoleControllerService from "../../../../API/details/HoleControllerService";
import ProcessingTable from "../../../../components/UI/table/customer-detales/processing/ProcessingTable";
import {Canvas} from "@react-three/fiber";
import {Text, PerspectiveCamera} from "@react-three/drei";
import ModalProcessing from "./Modals/ModalProcessing";
import DetailCanvas from "./Canvas/DetailCanvas";
import PointControllerService from "../../../../API/details/PointController";
import ModalConfirm from "./Modals/ModalConfirm";
import Layer from "./Canvas/CanvasComponents/Layer";
import SideBar from "./SideBar/SideBar";
import EdgeContent from "./EdgeContent/EdgeContent";
import DetailControllerService from "../../../../API/details/DetailControllerService";
import {useNavigate} from "react-router-dom";
import NotchControllerService from "../../../../API/details/NotchController";
import {calcCutPossible} from "../../../../utils/processing/calcCutPossible";
import PopoverProcessing from "./Canvas/CanvasComponents/PopoverProcessing";

const colorEdge = [
    {
        id: 4,
        name: "Polkemic N41\\2 ABS гасиенда черная H3081 0,8\\22\n",
        articleNumber: "N41\\2 H3081 0,8\\22"
    },
    {
        id: 5, name: "Polkemic 60В крем 1\\22", articleNumber: "60В 1\\22"
    },
]

const DetailProcessing = ({detailId, detailData, refCanvas, currScale, setChange, handleError, orderId}) => {
    const [processingModal, setProcessingModal] = useState("")
    const [confirmModal, setConfirmModal] = useState("");
    const [popoverActives, setPopoverActives] = useState({pointId: null, x: 0, y: 0});

    const [editableElementId, setEditableElementId] = useState(null);
    const [selectedHole, setSelectedHole] = useState(null)
    const [hoveredHole, setHoveredHole] = useState(null)
    const [prevArc, setPrevArc] = useState(null)

    const navigate = useNavigate();
    const returnToOrders = () => {
        navigate(`/customer/orders/${detailData.orderId}`);
    };

    const cutPossible = useMemo(() => {
        const possibleArr = []

        detailData.detailContour.points.forEach((el, i, arr) => {
            const possible = calcCutPossible(el, i, arr)
            possible && possibleArr.push(possible)
        })
        detailData.notches.forEach(el => el.contour.points.forEach((el, i, arr) => {
            const possible = calcCutPossible(el, i, arr)
            possible && possibleArr.push(possible)
        }))
        return possibleArr
    }, [detailData])


    const [cutData, setCutData] = useState({
        pointId: null,
        possible: cutPossible,
        distancePrev: "",
        distanceNext: ""
    })

    const processingHandle = (type, editId) => {
        type === "cut" && setCutData((value) => {
            return {...value, pointId: editId}
        })
        setEditableElementId(editId)
        setProcessingModal(type)
    }

    const openConfirmModal = (type, deleteId) => {
        setConfirmModal(type)
        setEditableElementId(deleteId)
    }

    const openPopoverActives = (data) => {
        setPopoverActives(data)
        setEditableElementId(data.pointId)
    }
    const closePopoverActives = () => {
        setPopoverActives({pointId: null, x: 0, y: 0})
        setEditableElementId(null)
    }

    const onCloseModal = () => {
        setProcessingModal("")
        setEditableElementId(null)
        setPrevArc(null)
        setCutData(values => ({...values, distancePrev: "", distanceNext: ""}))
    }

    const onCloseConfirmModal = () => {
        setConfirmModal("")
    }

    const onConfirm = () => {
        if (confirmModal === "reset") {
            DetailControllerService.resetContour(orderId, detailData.id)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deletePoint") {
            PointControllerService.delete(detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteHole") {
            HoleControllerService.delete(detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        }else if (confirmModal === "deleteNotchRound") {
            NotchControllerService.deleteRound(detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        }
    }

    const onSubmitHandle = (data) => {
        if (processingModal === "addPoint") {
            PointControllerService.save(detailId, data.afterPointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editPoint") {
            PointControllerService.patch(detailId, editableElementId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "arc") {
            PointControllerService.patchArc(detailId, data.pointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "round") {
            PointControllerService.patchRound(detailId, data.pointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "cut") {
            PointControllerService.patchCut(detailId, data.pointId, data.params).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "notch") {
            NotchControllerService.add(detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "notchRound") {
            NotchControllerService.addRound(detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editNotchRound") {
            NotchControllerService.putRound(detailId, editableElementId,data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        }else if (processingModal === "addHole") {
            HoleControllerService.save(detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editHole") {
            HoleControllerService.put(detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "addEdge") {
            console.log(editableElementId, "edgeType:", data)
            onCloseModal()
        }
    }

    const getPrevContour = (data) => {
        if (!data.params.radius) {
            setPrevArc(null)
        } else if (processingModal === "arc") {
            PointControllerService.previewArc(detailId, data.pointId, data.params).then(function (response) {
                setPrevArc(response.data)
            })
                .catch(function (error) {
                    handleError(error)
                })
        } else {
            PointControllerService.previewRound(detailId, data.pointId, data.params).then(function (response) {
                setPrevArc(response.data)
            })
                .catch(function (error) {
                    handleError(error)
                })
        }
    }

    return (<Box>
        <ModalProcessing
            type={processingModal}
            onClose={onCloseModal}
            onSubmit={onSubmitHandle}
            colorEdge={colorEdge}
            detailData={detailData}
            editableElementId={editableElementId}
            setEditableElementId={setEditableElementId}
            openConfirmModal={openConfirmModal}
            cutData={cutData}
            setCutData={setCutData}
            getPrevContour={getPrevContour}
            pointId={popoverActives.pointId}
        />
        <ModalConfirm
            confirmModal={confirmModal}
            onClose={onCloseConfirmModal}
            onSubmit={onConfirm}
        />
        {popoverActives.pointId &&
            <PopoverProcessing popoverActives={popoverActives}
                               processingHandle={processingHandle}
                               openConfirmModal={openConfirmModal}
                               confirmModal={confirmModal}
                               processingModal={processingModal}
                               cutData={cutData}
                               onClose={closePopoverActives}>
            </PopoverProcessing>
        }
        {detailData.id ? <Box>
            <p style={{margin: 10}}> Деталь {detailData.name} [{detailData.length}x{detailData.width}x{detailData.height}] </p>
            <Box style={{marginBottom: 10}}>
                <Button style={{marginRight: 10}} size="small" color="secondary" variant="contained"
                        onClick={returnToOrders}>
                    вернуться к деталям
                </Button>
                <Button size="small" color="success" variant="contained"
                        onClick={() => openConfirmModal("reset")}>
                    К начальному контуру
                </Button>
            </Box>
            <Box style={{display: "flex", justifyContent: "space-between", height: "calc(100vh - 220px)",}}>
                <SideBar detailData={detailData} processingHandle={processingHandle} cutPossible={cutPossible}/>
                <Box style={{
                    minWidth: "500px",
                    width: "calc(100% - 840px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <Box
                        ref={refCanvas}
                        style={{
                            minWidth: "500px",
                            height: "100%",
                            display: "flex",
                        }}>
                        <Canvas
                            onClick={(event) => {
                            }}>
                            <PerspectiveCamera/>
                            <Layer layer={2}>
                                <Text
                                    color={"black"}
                                    fontSize={.2}
                                    position={[0, (detailData.width / 2 * currScale) + 0.45, 0]}
                                    maxWidth={6}
                                >← {detailData.length} →</Text>
                                <Text
                                    rotation={[0, 0, 20.4]}
                                    color={"black"}
                                    fontSize={.2}
                                    position={[(detailData.length / 2 * currScale) + 0.45, 0, 0]}
                                    maxWidth={6}
                                >← {detailData.width} →</Text>
                            </Layer>
                            <DetailCanvas
                                detailData={detailData}
                                editableElement={{
                                    type: processingModal,
                                    id: editableElementId
                                }}
                                scale={currScale}
                                processingHandle={processingHandle}
                                hoveredHole={hoveredHole}
                                setHoveredHole={setHoveredHole}
                                selectedHole={selectedHole}
                                setSelectedHole={setSelectedHole}
                                position={[-(detailData.length / 2 * currScale), -(detailData.width / 2 * currScale), 0]}
                                cutData={cutData}
                                prevArc={prevArc}
                                openPopoverActives={openPopoverActives}
                                openConfirmModal={openConfirmModal}
                            />

                            <ambientLight intensity={Math.PI / 2}/>
                            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0}
                                       intensity={Math.PI}/>
                            <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI}/>
                        </Canvas>
                    </Box>
                    <EdgeContent colorEdge={colorEdge}/>
                </Box>
                <Box>
                    <ProcessingTable
                        data={detailData}
                        processingHandle={processingHandle}
                        openConfirmModal={openConfirmModal}
                        hoveredHole={hoveredHole}
                        setHoveredHole={setHoveredHole}
                        selectedHole={selectedHole}
                        setSelectedHole={setSelectedHole}/>
                </Box>
            </Box>
        </Box> : <p style={{color: "red"}}> the detail does not exist </p>}
    </Box>)
}
export default DetailProcessing
