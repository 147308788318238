import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const warehouseMaterialRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/materials-warehouse'

export default class MaterialWarehouseService {

    static async getAllByMaterialId(id) {
        return axios.get(warehouseMaterialRestUrl + '/material/' + id);
    }

    static async getAllByMaterialName(nameMaterial) {
        return axios.get(warehouseMaterialRestUrl + '/by-material-name' + '?nameMaterial=' + nameMaterial);
    }

    static async getAllByIssuingEdgeId(issuingEdgeId) {
        return axios.get(warehouseMaterialRestUrl + '/by-issuing-edge-id' + '?issuingEdgeId=' + issuingEdgeId);
    }

    static async createPlanned(data, nameMaterial) {
        return await axios.post(warehouseMaterialRestUrl + '/create-planned' + "?nameMaterial=" + nameMaterial, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async confirmedOrNotConfirmedCutOff(id, confirmedAfterCutting) {
        return await axios.post(warehouseMaterialRestUrl + '/' + id + '/set-confirmed' + "?confirmedAfterCutting=" + confirmedAfterCutting, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateNote(id, note) {
        return await axios.post(warehouseMaterialRestUrl + '/' + id + '/update-note-cut-off' + "?note=" + note, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateCutOff(data) {
        return await axios.post(warehouseMaterialRestUrl + '/update-cut-off', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCutOffsByWorkingCuttingMapId(workingCuttingMapId) {
        return axios.get(warehouseMaterialRestUrl + '/get-all-cut-offs-by-working-cutting-map-id' + '?workingCuttingMapId=' + workingCuttingMapId);
    }
}