import React from 'react';
import {Tooltip} from "@mui/material";

const CellMaterialReserveOptimizationQuantity = ({value, row}) => {

    const isSlab = row.original.materialOrganizationOption.typeMaterial === 'SLAB'

    const requestsCutting = row.original.requestsCutting

    const isExpectation = isSlab ? (requestsCutting !== null ? requestsCutting.requestsCuttingState === 'EXPECTATION' : false) : false;

    const currentValue = isSlab ? (value ? value.toFixed(3) : 'Ожидание') : '-';
    const title = isSlab ? (isExpectation ? 'Ожидание' : "Площадь раскроенных карт") : 'только для заявок на раскрой'

    return (
        <div>
            <Tooltip arrow placement="right" title={title}>
                <span
                    size="small"
                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                    color="error">
                   {currentValue}
                </span>
            </Tooltip>
        </div>
    );
};

export default CellMaterialReserveOptimizationQuantity;