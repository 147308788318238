import React, {useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {SIDE} from "../../../../../../Constants";

const NotchModalContent = ({onClose, onSubmit, setEditableElementId}) => {

    const initFormState = {
        "position": SIDE[0].value,
        "length": "",
        "width": "",
        "depth": '',
        "x": "",
        "y": ""
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "position": "",
        "length": "",
        "width": "",
        "depth": '',
        "x": "",
        "y": ""
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}
        console.log(formState, newErrors)

        Object.entries(formState).forEach(([name, value]) => {

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                "basePoint": {x: formState.x, y: formState.y},
                "length": formState.length,
                "width": formState.width,
                "depth": formState.depth,
                "position": formState.position
            })
        }
    }
    const selectPoint = (getData) => {
        const data = getData()
        setFormState(values => ({...values, ...data}))

        data.pointId && setEditableElementId(data.pointId)
    }

    return (<>
        <DialogTitle
            textAlign="center">Внутрений контур</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                <TextFieldValidation
                    select
                    name="position"
                    label="Позиция"
                    value={formState.position}
                    setCurrValues={selectPoint}
                    setValidationState={setValidationState}
                >
                    {[SIDE[0], SIDE[1]].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextFieldValidation>
                <TextFieldValidation
                    type={"number"}
                    name="length"
                    label="Длинна"
                    value={formState.length}
                    setCurrValues={selectPoint}
                    validationError={validationState?.length}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    type={"number"}
                    name={"width"}
                    value={formState?.width}
                    label="Ширина"
                    setCurrValues={selectPoint}
                    validationError={validationState?.width}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    type={"number"}
                    name={"depth"}
                    value={formState?.depth}
                    label="Глубина"
                    setCurrValues={selectPoint}
                    validationError={validationState?.depth}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    type={"number"}
                    name={"x"}
                    value={formState?.x}
                    label="X"
                    setCurrValues={selectPoint}
                    validationError={validationState?.x}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    type={"number"}
                    name={"y"}
                    value={formState?.y}
                    label="Y"
                    setCurrValues={selectPoint}
                    validationError={validationState?.y}
                    setValidationState={setValidationState}
                />
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button size="small" onClick={onClose}> отменить </Button>
            <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                выбрать
            </Button>
        </DialogActions>
    </>)
}
export default NotchModalContent
