import React from 'react';
import {Dialog} from "@mui/material";
import EdgeModalContent from "./ModalsContent/EdgeModalContent";
import HoleModalContent from "./ModalsContent/HoleModalContent";
import PointModalContent from "./ModalsContent/PointModalContent";
import ArcModalContent from "./ModalsContent/ArcModalContent";
import CutModalContent from "./ModalsContent/CutModalContent";
import NotchModalContent from "./ModalsContent/NotchModalContent";
import NotchRoundModalContent from "./ModalsContent/NotchRoundModalContent";

const ModalProcessing = ({
                             type,
                             onClose,
                             onSubmit,
                             colorEdge,
                             detailData,
                             editableElementId,
                             setEditableElementId,
                             openConfirmModal,
                             cutData,
                             setCutData,
                             getPrevContour,
                             pointId
                         }) => {
    const getContent = () => {
        switch (type) {
            case 'addEdge':
                return <EdgeModalContent colorEdge={colorEdge} onClose={onClose} onSubmit={onSubmit}/>
            case 'addHole':
            case "editHole":
                return <HoleModalContent editableElementId={editableElementId}
                                         onClose={onClose} onSubmit={onSubmit} detailData={detailData}
                                         openConfirmModal={openConfirmModal}/>
            case "addPoint":
            case "editPoint":
                return <PointModalContent type={type} editableElementId={editableElementId}
                                          onClose={onClose} onSubmit={onSubmit} detailData={detailData}
                                          setEditableElementId={setEditableElementId}
                />
            case "arc":
            case "round":
                return <ArcModalContent type={type} onClose={onClose} onSubmit={onSubmit}
                                        detailData={detailData} pointId={pointId}
                                        setEditableElementId={setEditableElementId}
                                        getPrevContour={getPrevContour}/>
            case "cut":
                return <CutModalContent onClose={onClose} onSubmit={onSubmit}
                                        detailData={detailData} pointId={pointId}
                                        setEditableElementId={setEditableElementId}
                                        cutData={cutData}
                                        setCutData={setCutData}/>
            case "notch":
                return <NotchModalContent onClose={onClose} onSubmit={onSubmit}
                                          setEditableElementId={setEditableElementId}
                />
            case "notchRound":
            case "editNotchRound":
                return <NotchRoundModalContent type={type} detailData={detailData}
                                               onClose={onClose} onSubmit={onSubmit}
                                               editableElementId={editableElementId}
                                               openConfirmModal={openConfirmModal}
                                               setEditableElementId={setEditableElementId}
                />
        }
    }
    return <Dialog
        open={!!type}
        BackdropProps={{
            style: {
                opacity: 0.1,
            },
        }}>
        {getContent()}
    </Dialog>
}
export default ModalProcessing;
