import React from "react";
import {COLORS} from "../../../../../../Constants";
import Layer from "./Layer";

const Notch = ({
                   notch,
                   detailData,
                   scale,
                   position,
                   texture
               }) => {

    const notThrough = notch.data.depth < detailData.height

    return (
        <Layer layer={1}>
            <mesh
                scale={scale}
                position={[position[0], position[1], position[2]]}
                onPointerOver={(event) => (console.log())}
                onClick={(event) => {
                    event.stopPropagation()
                }}>

                <extrudeGeometry args={[notch.shape, {
                    bevelEnabled: false,
                }]}/>
                <meshBasicMaterial
                    toneMapped={notThrough}
                    map={notThrough && texture}
                    color={notch.data.contour.position === "TOP" ? "white" : COLORS[6][600]}
                />
            </mesh>
        </Layer>
    )
}
export default Notch
