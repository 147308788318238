export const calcSideHolePos = (el, side, detailData, scale, xPos, yPos, x, y) => {
    let scaleRadius
    let scaleX
    let scaleY
    let nameX
    let nameY

    const xWidthDirection = side === "LEFT" ? -1 : 1
    const yWidthDirection = side === "FRONT" ? -1 : 1

    if (side === 'LEFT' || side === 'RIGHT') {
        scaleRadius = x * el.radius / detailData.height
        scaleX = (x * el.center.z / detailData.height - x / 2) * xWidthDirection
        scaleY = el.center.y * scale - yPos
        nameX = el.center.z
        nameY = el.center.y

    } else {
        scaleRadius = y * el.radius / detailData.height
        scaleX = el.center.x * scale - xPos
        scaleY = (y * el.center.z / detailData.height - y/2) * yWidthDirection
        nameX = el.center.x
        nameY = el.center.z
    }

    return {scaleRadius, scaleX, scaleY, nameX, nameY}
}