export const groupBy = (fn, list) => {
    const result = {};

    for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        const key = fn(item);

        if (!result[key]) {
            result[key] = [];
        }

        result[key].push(item);
    }

    return result;
};
