import React, {useEffect, useState} from 'react';
import EstimateTableItem from "../../../../components/UI/table/orders/organization/estimate/EstimateTableItem";
import {useNavigate, useParams} from "react-router-dom";
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Button, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import JobService from "../../../../API/orders/orders/organization/job/JobService";
import ProductionCosts from "../../../../components/UI/table/orders/organization/estimate/ProductionCosts";
import EstimateService from "../../../../API/orders/estimate/organization/EstimateService";
import {
    UpdateOrAddBasicModelsModule
} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddBasicModelsModule";
import {UpdateOrAddModelsModule} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddModelsModule";
import UploadOrderEstimateFileForm from "../../../../components/form/UploadOrderEstimateFileForm";
import Typography from "@mui/material/Typography";
import CalculationTable from "../../../../components/UI/table/orders/organization/estimate/CalculationTable";
import JobMetricOrderOrganizationTable
    from "../../../../components/UI/table/orders/organization/estimate/JobMetricOrderOrganizationTable";
import MaterialEstimateUnitService from "../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import WarehouseMaterialSettingsService from "../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import MaterialCostTable from "../../../../components/UI/table/orders/organization/estimate/MaterialCostTable";
import JobMetricOrderOrganizationService
    from "../../../../API/orders/metric/organization/JobMetricOrderOrganizationService";

const EstimateItem = ({
                          estimateId,
                          orderId,
                          orderNumber,
                          orderHeaderId,
                          year,
                          isButtonPurchase,
                          enableEditing,
                          isActual,
                          inWork,
                          orderCloud
                      }) => {

    const params = useParams();
    const [value, setValue] = useState('1');
    const [technicalMapData, setTechnicalMapData] = useState([]);
    const [currentEstimateState, setCurrentEstimateState] = useState(params.estimateState)
    const [dataModel, setDataModel] = useState([]);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");

    useEffect(() => {

        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        JobService.getJobsByOrderId(orderId).then(function (response) {
            setTechnicalMapData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const isCalculation = currentEstimateState === 'CALCULATION'
    const isButtonInWork = currentEstimateState === 'CALCULATION';

    const navigate = useNavigate();

    const redirectEstimate = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year + '/estimates');
    };

    const redirectOrder = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year);
    };

    const redirectOrders = () => {
        navigate('/organization/orders/');
    };

    const redirectRequestsCutting = () => {
        navigate('/organization/requests-cutting');
    };

    useEffect(() => {
        EstimateService.getAllIdsModelByEstimateId(estimateId).then(function (response) {
            let data = response.data;
            setDataModel(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const [modalBasicModelsOpen, setBasicModalModelsOpen] = useState(false);
    const [modalModelsOpen, setModalModelsOpen] = useState(false);
    const [basicModelId, setBasicModelId] = useState(null);
    const [change, setChange] = useState(false);

    const openBasicModels = () => {
        setBasicModalModelsOpen(true);
    };

    const closeModels = () => {
        setModalModelsOpen(false);
        setBasicModelId(null);
    };

    const closeBasicModels = () => {
        setBasicModalModelsOpen(false);
    };


    const [materialEstimateUnits, setMaterialEstimateUnits] = useState([]);
    useEffect(() => {
        MaterialEstimateUnitService.getAllByOrderId(orderId).then(function (response) {
            const data = response.data;
            setMaterialEstimateUnits(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const [JobMetricOrderOrganization, setJobMetricOrderOrganization] = useState([]);

    useEffect(() => {
        JobMetricOrderOrganizationService.getAllByOrderIdForOrganization(orderId).then(function (response) {
            let data = response.data;
            setJobMetricOrderOrganization(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (

        <Box sx={{
            '& > *': {
                marginTop: '0px',
            }
        }}>
            <TabContext value={value}>
                <Box sx={{
                    borderBottom: 1, borderColor: 'divider'
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="ИТОГИ" value='1'/>
                        <Tab label="Себестоимость материалов" value='2'/>
                        <Tab label="Себестоимость работ" value='3'/>
                        <Tab label="Стоимость материалов" value='4'/>
                        <Tab label="Стоимость работ" value='5'/>
                        <Tab label="Импорт" value='6'/>
                    </Tabs>
                </Box>
                <TabPanel value='1'>
                    <CalculationTable
                        data={materialEstimateUnits}
                        setData={setMaterialEstimateUnits}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                        estimateId={estimateId}
                        orderId={orderId}
                        orderNumber={orderNumber}
                        orderHeaderId={orderHeaderId}
                        year={year}
                        isButtonPurchase={isButtonPurchase}
                        enableEditing={enableEditing}
                        isActual={true}
                        inWork={currentEstimateState === 'IN_WORK'}
                    />
                </TabPanel>
                <TabPanel value='2'>
                    <EstimateTableItem
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                        estimateId={estimateId}
                        orderId={orderId}
                        orderNumber={orderNumber}
                        orderHeaderId={orderHeaderId}
                        year={year}
                        isButtonPurchase={isButtonPurchase}
                        enableEditing={enableEditing}
                        isActual={true}
                        inWork={currentEstimateState === 'IN_WORK'}
                        orderCloud={orderCloud}
                    />
                </TabPanel>
                <TabPanel value='3'>
                    <ProductionCosts
                        orderId={orderId}
                    />
                </TabPanel>
                <TabPanel value='4'>
                    <MaterialCostTable
                        materialEstimateUnits={materialEstimateUnits}
                        setMaterialEstimateUnits={setMaterialEstimateUnits}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                        orderId={orderId}
                        orderNumber={orderNumber}
                        orderHeaderId={orderHeaderId}
                        year={year}
                        isButtonPurchase={isButtonPurchase}
                        enableEditing={enableEditing}
                        isActual={true}
                    />
                </TabPanel>
                <TabPanel value='5'>
                    <JobMetricOrderOrganizationTable
                        data={JobMetricOrderOrganization}
                        setData={setJobMetricOrderOrganization}
                        orderId={orderId}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                    />
                </TabPanel>
                <TabPanel value='6'>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <Typography variant="h6" component="h6">
                            Импорт сметы из файла сметы базис-мебельщика *.xls
                        </Typography>
                        <UploadOrderEstimateFileForm
                            year={year}
                            orderId={orderId}
                            label={'Загрузить смету проекта'}
                            change={change}
                            setChange={setChange}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <Typography variant="h6" component="h6">
                            Создание сметы на основе моделей (с заявками на раскрой, необходимо наличие модуля
                            облако-моделей)
                        </Typography>
                        <Button
                            size="small"
                            color="success"
                            onClick={openBasicModels}
                            variant="contained"
                        >
                            Добавить модель
                        </Button>
                    </Box>


                    <UpdateOrAddBasicModelsModule
                        open={modalBasicModelsOpen}
                        setBasicModelId={setBasicModelId}
                        setModalModelsOpen={setModalModelsOpen}
                        onClose={closeBasicModels}
                        orderIdHeader={orderHeaderId}
                    />
                    <UpdateOrAddModelsModule
                        open={modalModelsOpen}
                        setModelsOpen={setModalModelsOpen}
                        setBasicModalModelsOpen={setBasicModalModelsOpen}
                        onClose={closeModels}
                        basicModelId={basicModelId}
                        orderId={orderId}
                        change={change}
                        setChange={setChange}
                    />
                </TabPanel>

            </TabContext>
        </Box>
    );
};

export default EstimateItem;