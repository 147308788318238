import React, {useMemo} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";

const JobMetricOrderOrganizationTable = ({
                                             data,
                                             setData,
                                             baseCurrency,
                                             alternativeCurrency
                                         }) => {

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseWorth !== null) {
                return acc + mEU.baseWorth.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeWorth !== null) {
                return acc + mEU.alternativeWorth.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                id: "name",
                header: 'Материал',
                accessorKey: 'materialName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                id: "metricName",
                header: 'Найменование операции',
                accessorKey: 'metricName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                id: "unit",
                header: 'Ед.изм.',
                accessorKey: 'unitName',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'Кол',
                accessorKey: 'quantity',
                enableColumnActions: false,
                enableEditing: false,
            },
            {
                header: `Стоимость: ${baseCurrency}`,
                accessorKey: 'baseWorth.price',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: `Стоимость: ${alternativeCurrency}`,
                accessorKey: 'alternativeWorth.price',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            }
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableExpanding={true}
                initialState={{
                    expanded: true,
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    grouping: ['name'],
                    sorting: [{id: 'name', desc: false}, {id: 'metricName', desc: false}],
                }}
                getRowId={(originalRow) => originalRow.id}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default JobMetricOrderOrganizationTable;