import React from 'react';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

const MainMenu = ({name, labelName, handleMenu, anchorEl, handleClose, menuItems}) => {
    return (
        <div>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls={`${name}-menu-appbar`}
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <MenuIcon/>
                </IconButton>
                <Menu
                    id={`${name}-menu-appbar`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {menuItems.filter(
                        (menuItem) => (menuItem.allowInDev)
                    ).map((menuItem, index) => (
                        <MenuItem key={index} onClick={menuItem.onClick}>
                            {menuItem.label}
                        </MenuItem>
                    ))}
                </Menu>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    {labelName}
                </Typography>
            </Box>
        </div>
    );
};

export default MainMenu;