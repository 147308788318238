import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import ProductionCostsService from "../../../../../../API/orders/estimate/organization/ProductionCostsService";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import WarehouseMaterialSettingsService
    from "../../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";

const ProductionCosts = ({orderId}) => {

    const [data, setData] = useState([]);

    useEffect(() => {

        ProductionCostsService.getAllByOrderId(orderId).then(function (response) {
            let data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");

    useEffect(() => {

        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.costInOrderBasePrice, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.costInOrderAlternativePrice, 0),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                id: "materialName",
                header: 'Материал',
                accessorKey: 'materialName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                id: "signJobName",
                header: 'Найменование операции',
                accessorKey: 'signJobName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                id: "unitName",
                header: 'Ед.изм.',
                accessorKey: 'unitName',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'Объем в заказе',
                accessorKey: 'orderQuantity',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 50,
            },
            {
                header: 'Трудоемкость в заказе',
                accessorKey: 'laborIntensityInOrder',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 10,
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
            },
            {
                header: 'Стоимость в заказе BYN',
                accessorKey: 'costInOrderBasePrice',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: 'Стоимость в заказе USD',
                accessorKey: 'costInOrderAlternativePrice',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: "metricName",
                header: 'Метрика',
                accessorKey: 'metricName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableExpanding={true}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    grouping: ['materialName', 'signJobName'],
                    sorting: [{id: 'materialName', desc: false}, {id: 'signJob.name', desc: false}],
                }}
                getRowId={(originalRow) => originalRow.id}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default ProductionCosts;