import React from 'react';

const CellCheckboxAMPUTPrice = ({price}) => {

    return (
        price && <div style={{display: 'flex', alignItems: 'center', height: '13px', lineHeight: '13px'}}>
            <div style={{marginLeft: '0px'}}>{price.price} {price.currency.curAbbreviation}</div>
        </div>
    );
};

export default CellCheckboxAMPUTPrice;