import React, {useEffect, useState} from 'react';
import OrderCloudTable from "../../../components/UI/table/orders/organization/order-cloud/OrderCloudTable";
import OrderService from "../../../API/orders/orders/organization/OrderService";

const OrdersEconomic = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        OrderService.getOrdersByEconomic().then(function (response) {
            let data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <OrderCloudTable
                data={data}
                setData={setData}
                enableEditing={true}
            />
        </div>
    );
};

export default OrdersEconomic;