import React, {useState} from 'react';
import {Button, Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {hasOnlyDigits} from "../../../../../../utils/processing/hasOnlyDigits";
import {SIDE} from "../../../../../../Constants";

const HoleModalContent = ({onClose, onSubmit, detailData, editableElementId, openConfirmModal}) => {
    const initData = detailData.holes.find(el => el.id === editableElementId)

    const initFormState = editableElementId ? {
        "position": initData.contour.position,
        "radius": initData.radius,
        "depth": initData.depth,
        "x": initData.center.x,
        "y": initData.center.y,
        "z": initData.center.z,
    } : {
        "position": SIDE[0].value,
        "radius": "",
        "depth": "",
        "x": "",
        "y": "",
        "z": 0,
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "position": "",
        "radius": "",
        "depth": "",
        "x": "",
        "y": "",
        "z": "",
    });

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {

            if (value.length && !hasOnlyDigits(value)) return

            const maxX = !newErrors.radius && formState.radius ? detailData.length - formState.radius : detailData.length - 1
            const minX = !newErrors.radius && formState.radius ? formState.radius : 1
            const maxY = !newErrors.radius && formState.radius ? detailData.width - formState.radius : detailData.width - 1
            const minY = !newErrors.radius && formState.radius ? formState.radius : 1
            const minZ = !newErrors.radius && formState.radius ? formState.radius : 1
            const maxZ = !newErrors.radius && formState.radius ? detailData.height - formState.radius : detailData.height - 1

            if (formState.position === "TOP" || formState.position === "BOTTOM") {
                const maxRadius = detailData.width > detailData.length
                    ? detailData.length / 2 < 30 ? detailData.length / 2 : 30
                    : detailData.width / 2 < 30 ? detailData.width / 2 : 30
                if (name === "radius" && (Number(value) > maxRadius || Number(value) < 1)) {
                    newErrors.radius = `от 1 до ${maxRadius}`
                } else if (name === "depth" && (Number(value) > detailData.height || Number(value) < 0.1)) {
                    newErrors.depth = `от 0.1 до ${detailData.height}`
                } else if (name === "x" && (Number(value) > maxX || Number(value) < minX)) {
                    newErrors.x = `от ${minX} до ${maxX}`
                } else if (name === "y" && (Number(value) > maxY || Number(value) < minY)) {
                    newErrors.y = `от ${minY} до ${maxY}`
                } else if (name === "z" && (Number(value) > detailData.height || Number(value) > formState.depth || Number(value) < 0)) {
                    newErrors.z = `от 0 до ${!newErrors.depth && formState.depth ? formState.depth : detailData.height}`
                }
            } else if (formState.position === "LEFT" || formState.position === "RIGHT") {
                if (name === "radius" && (Number(value) > detailData.height / 2 || Number(value) < 1)) {
                    newErrors.radius = `от 1 до ${detailData.height / 2}`
                } else if (name === "depth" && (Number(value) > 20 || Number(value) < 0.1)) {
                    newErrors.depth = `от  0.1 до 20`
                } else if (name === "x" && (Number(value) < 0 || Number(value) > detailData.length)) {
                    newErrors.x = `от 0 до ${detailData.length}`
                } else if (name === "y" && (Number(value) > maxY || Number(value) < minY)) {
                    newErrors.y = `от ${minY} до ${maxY}`
                } else if (name === "z" && (Number(value) > maxZ || Number(value) < minZ)) {
                    newErrors.z = `от ${minZ} до ${maxZ}`
                }
            } else {
                if (name === "radius" && (Number(value) > detailData.height / 2 || Number(value) < 1)) {
                    newErrors.radius = `от 1 до ${detailData.height / 2}`
                } else if (name === "depth" && (Number(value) > 20 || Number(value) < 0.1)) {
                    newErrors.depth = `от 0.1 до 20}`
                } else if (name === "x" && (Number(value) > maxX || Number(value) < minX)) {
                    newErrors.x = `от ${minX} до ${maxX}`
                } else if (name === "y" && (Number(value) < 0 || Number(value) > detailData.width)) {
                    newErrors.y = `от 0 до ${detailData.width}`
                } else if (name === "z" && (Number(value) > maxZ || Number(value) < minZ)) {
                    newErrors.z = `от ${minZ} до ${maxZ}`
                }
            }

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                "radius": formState.radius,
                "center": {
                    "x": formState.x,
                    "y": formState.y,
                    "z": formState.z,
                },
                "depth": formState.depth,
                "position": formState.position
            })
        }
    }
    const selectPosition = (getSelectedPosition) => {
        const position = getSelectedPosition().position
        const zData = ["TOP", "BOTTOM"].includes(position) && !editableElementId ? 0 : formState.z

        setFormState((values) => {
            return {...values, position: position, z: zData}
        })
    }

    return (
        <>
            <DialogTitle
                textAlign="center">{editableElementId ? "Изменить отверстие" : "Добавить отверстие"}</DialogTitle>
            <DialogContent style={{paddingBottom: 7}}>
                <FormControl sx={{minWidth: 350}}>

                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Box sx={{
                            width: 170,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}>
                            <TextFieldValidation
                                select
                                name="position"
                                label="Позиция"
                                value={formState.position}
                                setCurrValues={selectPosition}
                                setValidationState={setValidationState}
                            >
                                {SIDE.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextFieldValidation>
                            <TextFieldValidation
                                name={"radius"}
                                value={formState?.radius}
                                label="Радиус"
                                setCurrValues={setFormState}
                                validationError={validationState?.radius}
                                setValidationState={setValidationState}
                            />
                            <TextFieldValidation
                                name={"depth"}
                                value={formState?.depth}
                                label="Глубина"
                                setCurrValues={setFormState}
                                validationError={validationState?.depth}
                                setValidationState={setValidationState}
                            />

                        </Box>
                        <Box sx={{width: 170}}>
                            <TextFieldValidation
                                label="x"
                                name={"x"}
                                value={formState?.x}
                                setCurrValues={setFormState}
                                validationError={validationState?.x}
                                setValidationState={setValidationState}/>
                            <TextFieldValidation
                                label="y"
                                name={"y"}
                                value={formState?.y}
                                setCurrValues={setFormState}
                                validationError={validationState?.y}
                                setValidationState={setValidationState}/>
                            <TextFieldValidation
                                label="z"
                                name={"z"}
                                value={formState?.z}
                                setCurrValues={setFormState}
                                validationError={validationState?.z}
                                setValidationState={setValidationState}
                                disabled={!!["TOP", "BOTTOM"].includes(formState.position)}/>
                        </Box>
                    </Box>
                    {editableElementId && <Button
                        size="small"
                        color="secondary"
                        onClick={() => openConfirmModal("deleteHole", editableElementId)}
                        variant="outlined"
                    >
                        Удалить отверстие
                    </Button>}
                </FormControl>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button size="small" onClick={onClose}> отменить </Button>
                <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                    выбрать
                </Button>
            </DialogActions>
        </>
    )
}
export default HoleModalContent
