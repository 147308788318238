import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import MaterialEstimateUnitService from "../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import {Link} from "react-router-dom";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import ModalConfirm from "../../../modal/ModalConfirm";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const IssuanceEstimateUnitTable = ({
                                       orderId, orderNumber
                                   }) => {
    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [change, setChange] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [openModalIssuing, setOpenModalIssuing] = useState(false);
    const [openModalPackaging, setOpenModalPackaging] = useState(false);

    useEffect(() => {
        MaterialEstimateUnitService.getAllByCurrentEstimate(orderId).then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleSubmitIssuing = async () => {
        MaterialEstimateUnitService.issues(selectedIds)
            .then(function () {
                handleCloseIssuing();
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleSubmitPackaging = async () => {
        MaterialEstimateUnitService.packaging(selectedIds)
            .then(function () {
                handleClosePackaging();
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleClosePackaging = () => {
        setOpenModalPackaging(false);
    };

    const handleCloseIssuing = () => {
        setOpenModalIssuing(false);
    };

    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.articleNumber : '';
                }
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/issuance-piece-materials/' + orderId + '/' + orderNumber + '/' + data[row.index].id + '/' + data[row.index].materialOrganizationOption.typeMaterial}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.unit.name : row.original.tempUnit.name;
                }
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'packaging',
                header: 'Собрано',
                accessorKey: 'packaging',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => {
                    return row.original.packaging === true ? <span style={{color: 'green'}}>
      <CheckBoxIcon/>
    </span> : <span style={{color: 'gray'}}>
      <CheckBoxOutlineBlankIcon/>
    </span>;
                }
            },
            {
                id: 'issue',
                header: 'Выдано',
                accessorKey: 'issue',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => {
                    return row.original.issue === true ? <span style={{color: 'green'}}>
      <CheckBoxIcon/>
    </span> : <span style={{color: 'gray'}}>
      <CheckBoxOutlineBlankIcon/>
    </span>;
                }
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection={true}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                    </Box>
                )}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {

                    const issue = () => {
                        choice(table);
                        setOpenModalIssuing(true)
                    };

                    const pack = () => {
                        choice(table);
                        setOpenModalPackaging(true)
                    };

                    const choice = (table) => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                            </Box>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                <Button
                                    color="success"
                                    onClick={() => pack()}
                                    variant="contained"
                                    size="small"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                >
                                    Упаковать
                                </Button>
                                <Button
                                    color="success"
                                    onClick={() => issue()}
                                    variant="contained"
                                    size="small"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                >
                                    Выдать
                                </Button>
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalConfirm
                title={"Подтвердить выдачу материалов?"}
                modalOpen={openModalIssuing}
                handleClose={handleCloseIssuing}
                handleSubmit={handleSubmitIssuing}
                action={"Подтвердить"}
            />
            <ModalConfirm
                title={"Подтвердить упаковку материалов?"}
                modalOpen={openModalPackaging}
                handleClose={handleClosePackaging}
                handleSubmit={handleSubmitPackaging}
                action={"Подтвердить"}
            />
        </div>
    );
};

export default IssuanceEstimateUnitTable;

