export const calcCutPossible = (el, i, arr) => {
    const next = i === arr.length - 2 ? arr[0] : arr[i + 1]
    const prev = i === 0 ? arr[arr.length - 2] : arr[i - 1]
    const allPointsLieLine = (next?.x - prev?.x) / (el?.x - prev?.x) === (next?.y - prev?.y) / (el?.y - prev?.y)

    if (!allPointsLieLine && !el.arcParams && !prev?.arcParams && !!next) {
        return {prev: prev, curr: el, next: next}
    }

}
