import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import ErrorModal from "../../../modal/ErrorModal";
import InfoModal from "../../../modal/InfoModal";
import {Link, useNavigate} from "react-router-dom";
import ArrivalMaterialService from "../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import ModalUpdateOrAddMaterialArrival from "../material/arrival/ModalUpdateOrAddMaterialArrival";
import WarehouseMaterialSettingsService
    from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import CurrencyService from "../../../../../API/orders/warehouse/currency/CurrencyService";
import SupplierService from "../../../../../API/orders/warehouse/supplier/SupplierService";
import WarehouseService from "../../../../../API/orders/warehouse/WarehouseService";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import {materialReserveState} from "../../../../../data/MaterialReserveState";
import ModalSetSupplier from "./ModalSetSupplier";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";

const ReservationMaterialTable = ({
                                      data,
                                      setData,
                                      isColumnOrder,
                                      isColumnMaterialWarehouse,
                                      isColumnOptimization,
                                      enableGrouping,
                                      expanded,
                                      pageSize,
                                      orderList,
                                      change,
                                      setChange,
                                      columnGrouping,
                                      titleModalUpdateOrAddMaterialArrival,
                                      typeMaterial
                                  }) => {

        const [errorModalOpen, setErrorModalOpen] = useState(false);
        const [infoModalOpen, setInfoModalOpen] = useState(false);


        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
        const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);

        const [messageError, setMessageError] = useState(null);
        const [messageInfo, setMessageInfo] = useState(null);
        const [rowSelection, setRowSelection] = useState({});

        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [setSupplierModalOpen, setSetSupplierModalOpen] = useState(false);
        const [date, setDate] = useState(null);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [materialDeliveryDate, setMaterialDeliveryDate] = useState(null);
        const [baseCurrency, setBaseCurrency] = useState("");
        const [alternativeCurrency, setAlternativeCurrency] = useState("");
        const [currencyList, setCurrencyList] = useState([]);
        const [currencyId, setCurrencyId] = useState(null);
        const [supplierList, setSupplierList] = useState([]);
        const [supplierId, setSupplierId] = useState(null);
        const [warehouseList, setWarehouseList] = useState([]);
        const [warehouseId, setWarehouseId] = useState(null);
        const [selectedIds, setSelectedIds] = useState([]);

        useEffect(() => {
            WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
                let data = response.data;
                setBaseCurrency(data.baseCurrency.curAbbreviation);
                setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
            })
                .catch(function (error) {
                    console.log(error);
                });
            CurrencyService.getAll().then(function (response) {
                let data = response.data;
                data.sort((a, b) =>
                    a.defaultBase === b.defaultBase ? 0 : a.defaultBase ? -1 : 1
                );
                setCurrencyList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            SupplierService.getAllByOrganization().then(function (response) {
                let data = response.data;
                setSupplierList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            WarehouseService.getAll().then(function (response) {
                let data = response.data;
                setWarehouseList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleDeleteRow = useCallback(
            (row) => {
            },
            [data],
        );

        const handleUpdateReserve = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setSetSupplierModalOpen(true);
                setSupplierId(row.original.supplierMaterial.supplier.id);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleCloseUpdateReserve = () => {
            setSetSupplierModalOpen(false);
            setSupplierId(null);
        };

        const handleCloseCreateOrUpdateModalOpen = () => {
            setCreateOrUpdateModalOpen(false)
            setCurrencyId(null);
            setSupplierId(null);
            setWarehouseId(null);
        };

        const handleSetSupplier = async () => {
            MaterialReserveService.setSupplier(currentEditRowData.id, supplierId, typeMaterial)
                .then(function (response) {
                    let data = response.data;
                    handleUpdate(data)
                    handleCloseUpdateReserve()
                })
                .catch(function (error) {
                    console.log(error);
                })
        };

        const handleUpdate = (values) => {
            data[currentEditRowId].supplierMaterial.supplier = values.supplierMaterial.supplier;
            setData([...data]);
        };

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'materialArticleNumber',
                    header: 'Артикул',
                    accessorKey: 'materialArticleNumber',
                    minSize: 70,
                },
                {
                    id: 'materialName',
                    header: 'Найменование',
                    accessorKey: 'materialName',
                    minSize: 70,

                },
                {
                    id: 'quantity',
                    header: 'Необходимо.',
                    accessorKey: 'quantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'primary.main',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),
                },
                {
                    id: 'availabilityQuantity',
                    header: 'Наличие',
                    accessorKey: 'availabilityQuantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'success.main',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),

                },
                {
                    id: 'orderedQuantity',
                    header: 'Заказано',
                    accessorKey: 'orderedQuantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'secondary.main',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),
                },
                {
                    id: 'needToOrderQuantity',
                    header: 'Необходимо заказать',
                    accessorKey: 'needToOrderQuantity',
                    minSize: 15,
                    maxSize: 15,
                    aggregationFn: 'sum',
                    AggregatedCell: ({cell, table}) => (
                        <>
                            <Box sx={{
                                color: 'error.light',
                                fontWeight: 'bold'
                            }}>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                })}
                            </Box>
                        </>
                    ),
                },
                {
                    id: 'supplier',
                    header: 'Поставщик',
                    accessorKey: 'supplierMaterial.supplier.name',
                    minSize: 55,
                    maxSize: 55,
                },
                {
                    id: 'materialReserveState',
                    header: 'Статус',
                    accessorKey: 'materialReserveState',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({cell}) => materialReserveState[cell.getValue()],
                },
                {
                    id: 'orderNumber',
                    header: 'Заказы',
                    accessorKey: 'orders',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => {
                        return OrderUtil.convertNumberOrders(cell.getValue())
                    },
                    filterVariant: 'multi-select',
                    filterSelectOptions: orderList,
                    filterFn: (row, id, filterValue) => {
                        return OrderUtil.checkNumberOrder(row.getValue(id), filterValue)
                    },
                },
                {
                    id: 'plan.number',
                    header: 'План',
                    accessorKey: 'plan.number',
                    minSize: 15,
                    maxSize: 15,
                },

                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (isColumnMaterialWarehouse) {
                baseColumns.splice(2, 0, {
                    id: 'size',
                    header: 'Размеры',
                    accessorKey: 'size',
                    minSize: 15,
                    maxSize: 15,
                });
            }

            if (isColumnOptimization) {
                baseColumns.splice(2, 0, {
                    id: 'optimization.number',
                    header: 'Оптимизация',
                    accessorKey: 'optimization.number',
                    minSize: 150,
                    maxSize: 150,
                    Cell: ({cell, row}) => (
                        <li key={cell.getValue()}>
                            <Link
                                to={'/requests-cutting/optimization/' + row.original.optimization.id + '/' + row.original.optimization.number}>{cell.getValue()}</Link>
                        </li>
                    ),
                });
            }

            return baseColumns;

        }, [data, setData, isColumnOrder]);

        const handleCreateData = () => {
            const ArrivalMaterial = {
                arrivalState: 'EXECUTION_OF_APPLICATION',
                comment: '',
                currencyArrival: null,
                date: null,
                id: null,
                materialDeliveryDate: null,
                supplier: null,
                warehouse: null,
            };
            setCurrentEditRowData(ArrivalMaterial);
            setDate(new Date());
            setMaterialDeliveryDate(new Date())
            setCreateOrUpdateModalOpen(true);
        };

        const handleSubmitCreateByReserves = () => {


            currentEditRowData.date = date === null ? null : DateTimeUtil.dateConvert(date);
            currentEditRowData.materialDeliveryDate = materialDeliveryDate === null ? null : DateTimeUtil.dateConvert(materialDeliveryDate);

            const currentCurrencyId = currencyId === null ? currencyList[0].id : currencyId;
            const currentWarehouseId = warehouseId == null ? warehouseList[0].id : warehouseId;

            ArrivalMaterialService.createByReserves(selectedIds, currentEditRowData, currentCurrencyId, currentWarehouseId,
            ).then(function () {
                setChange(!change)
                handleCloseCreateOrUpdateModalOpen();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const navigate = useNavigate();

        const redirectMaterialsArrival = () => {
            navigate('/materials-arrival');
        };


        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        expanded: expanded,
                        grouping: columnGrouping,
                        pagination: {pageIndex: 0, pageSize: pageSize},
                    }}
                    enableEditing={true}
                    enableGrouping={enableGrouping}
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateReserve(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const createByReserves = () => {
                            handleCreateData();
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            setSelectedIds(selectedIds);
                        };

                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        color="secondary"
                                        onClick={() => redirectMaterialsArrival()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Приход материалов
                                    </Button>
                                    <Button
                                        size="small"
                                        color="success"
                                        onClick={() => createByReserves()}
                                        variant="contained"
                                    >
                                        Создать закупку
                                    </Button>
                                </Box>
                            </div>
                        );
                    }}

                />
                <ErrorModal
                    open={errorModalOpen}
                    setOpen={setErrorModalOpen}
                    message={messageError}
                />
                <InfoModal
                    open={infoModalOpen}
                    setOpen={setInfoModalOpen}
                    message={messageInfo}
                />
                <ModalUpdateOrAddMaterialArrival
                    open={createOrUpdateModalOpen}
                    handleClose={() => handleCloseCreateOrUpdateModalOpen()}
                    title={titleModalUpdateOrAddMaterialArrival}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    date={date}
                    setDate={setDate}
                    materialDeliveryDate={materialDeliveryDate}
                    setMaterialDeliveryDate={setMaterialDeliveryDate}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    setCurrencyId={setCurrencyId}
                    supplierList={supplierList}
                    supplierId={supplierId}
                    setSupplierId={setSupplierId}
                    warehouseList={warehouseList}
                    warehouseId={warehouseId}
                    setWarehouseId={setWarehouseId}
                    handleSubmit={handleSubmitCreateByReserves}
                    isNew={true}
                />
                <ModalSetSupplier
                    open={setSupplierModalOpen}
                    handleClose={() => handleCloseUpdateReserve()}
                    title={"Изменить поставщика"}
                    setSupplierId={setSupplierId}
                    supplierList={supplierList}
                    supplierId={supplierId}
                    handleSubmit={handleSetSupplier}
                />
            </div>
        );
    }
;

export default ReservationMaterialTable;