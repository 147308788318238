import React from "react";
import Layer from "./Layer";

const NotchRound = ({data, setSelectedHole, setHoveredHole, processingHandle, texture,scale}) => {
    const onclickHoleHandle = () => {
        processingHandle("editNotchRound", data.id)
        setSelectedHole(data.id)
    }

    return (
        <Layer layer={2}>
            <mesh
                scale={scale}
                onClick={(event) => (event.stopPropagation(), onclickHoleHandle())}
                onPointerOver={(event) => (event.stopPropagation(), setHoveredHole(`${data.id}${data.name}`))}
                onPointerOut={(event) => (event.stopPropagation(), setHoveredHole(null))}
                position={[data.x, data.y, data.z]}>
                <circleGeometry args={[data.radius]}/>
                <meshBasicMaterial
                    toneMapped={false}
                    color={"black"}/>
                <mesh>
                    <circleGeometry args={[data.radius - 5]}/>
                    <meshBasicMaterial map={!data.through && texture} toneMapped={!data.through} color={data.color}/>
                </mesh>
            </mesh>
        </Layer>
    )
}
export default NotchRound
