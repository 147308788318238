import React, {useState} from 'react';
import {Button, Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {createPointArray} from "../../../../../../utils/processing/createPointArray";
import {noExponents} from "../../../../../../utils/processing/noExponents";

const PointModalContent = ({
                               type,
                               onClose,
                               onSubmit,
                               detailData,
                               editableElementId,
                               setEditableElementId
                           }) => {


    const pointArray = createPointArray(detailData)

    const initData = pointArray.find(el => el.id === editableElementId)

    const initFormState = type === "editPoint" ? {
        "x": +parseFloat(noExponents(initData.x)).toFixed(2),
        "y": +parseFloat(noExponents(initData.y)).toFixed(2),
        "afterPointId": editableElementId
    } : {
        "x": "", "y": "", "afterPointId": pointArray[0].id
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "x": "", "y": "", "afterPointId": "",
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (name === "x" && (value < 0 || value > detailData.length)) {
                newErrors.x = `от 0 до ${detailData.length}`
            } else if (name === "y" && (value < 0 || value > detailData.width)) {
                newErrors.y = `от 0 до ${detailData.width}`
            }
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                afterPointId: formState.afterPointId,
                point: {
                    "x": formState.x,
                    "y": formState.y,
                }
            })
        }
    }
    const selectPoint = (getSelectedPoint) => {
        const pointId = getSelectedPoint().afterPointId
        setFormState((values) => {
            return {...values, afterPointId: pointId}
        })
        setEditableElementId(pointId)
    }

    return (<>
        <DialogTitle textAlign="center">{type === "editPoint" ? "Изменить узел" : "Добавить узел"}</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                {type === "addPoint" && <TextFieldValidation
                    select
                    name="afterPointId"
                    label="Предыдущий узел"
                    value={formState.afterPointId}
                    setCurrValues={selectPoint}
                    setValidationState={setValidationState}
                >
                    {pointArray.map((option, i, arr) => (
                        <MenuItem key={option.id} value={option.id}>
                            x= {+option.x.toFixed(2)}, y= {+option.y.toFixed(2)}
                        </MenuItem>))}
                </TextFieldValidation>}
                <TextFieldValidation
                    name={"x"}
                    value={formState?.x}
                    label="x"
                    setCurrValues={setFormState}
                    validationError={validationState?.x}
                    setValidationState={setValidationState}/>
                <TextFieldValidation
                    name={"y"}
                    value={formState?.y}
                    label="y"
                    setCurrValues={setFormState}
                    validationError={validationState?.y}
                    setValidationState={setValidationState}
                />
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button size="small" onClick={onClose}> отменить </Button>
            <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                выбрать
            </Button>
        </DialogActions>
    </>)
}
export default PointModalContent
