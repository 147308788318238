import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";

import {useNavigate} from "react-router-dom";
import MaterialEstimateUnitService from "../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import MaterialPriceUnitService from "../../../../../API/orders/warehouse/MaterialPriceUnitService";
import EstimateService from "../../../../../API/orders/estimate/organization/EstimateService";
import ModalConfirm from "../../../modal/ModalConfirm";
import ModalOpenSetValues from "../material/arrival/ModalOpenSetValues";

const RequestsPurchaseTableItem = ({estimateId, orderId, orderNumber, status}) => {

        const [isFullScreen, setIsFullScreen] = useState(false);

        const [data, setData] = useState([]);

        const [modalCurrencyOpen, setModalCurrencyOpen] = useState(false);
        const [currentCurrencyBM, setCurrentCurrencyBM] = useState([]);
        const [typeCurrentCurrency, setTypeCurrentCurrency] = useState([]);

        const [selectedRows, setSelectedRows] = useState([]);
        const [selectedIds, setSelectedIds] = useState([]);

        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [addModalOpen, setAddModalOpen] = useState(false);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
        const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);
        const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
        const [currentBasePriceCurrency, setCurrentBasePriceCurrency] = useState(null);
        const [rowSelection, setRowSelection] = useState({});
        const [disabledButtonBasePriceCurrency, setDisabledButtonBasePriceCurrency] = useState(true);
        const [showConfirmationModalArrivalMaterial, setConfirmationModalArrivalMaterial] = useState(false);
        const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
        const [purchaseStatus, setPurchaseStatus] = useState(status !== 'IN_WORK');


        useEffect(() => {
            MaterialEstimateUnitService.getAllByEstimateId(estimateId).then(function (response) {
                const data = response.data;
                setData(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        useEffect(() => {
            data.forEach((row) => {
                if (rowSelection[row.id]) {
                    setCurrentBasePriceCurrency(row.supplierMaterial.basePrice.currency.curAbbreviation)
                }
            });
        }, [rowSelection]);

        const isNotSelected = () => {
            return Object.keys(rowSelection).length === 0;
        };

        const unlockBaseCoursePrices = () => {
        };

        const disabledOrUnableButtonBasePriceCurrency = () => {
            setDisabledButtonBasePriceCurrency(!disabledButtonBasePriceCurrency);
        };

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.original.id;
                const materialId = row.original.id
                MaterialPriceUnitService.delete(id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                        materialPriceUnits.delete(materialId);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );


        const handleSubmitSetPurchase = async () => {
            EstimateService.setPurchase(estimateId)
                .then(function () {
                    setPurchaseStatus(true);
                    handleCloseSetPurchase()
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

        const handleCloseSetPurchase = () => {
            setSetPurchaseModalOpen(false);
        };

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const updateCurrencyByIds = async () => {
        }


        const handleCloseModalCurrency = () => {
            setModalCurrencyOpen(false);
        };

        const navigate = useNavigate();

        const redirectRequestsPurchase = () => {
            navigate('/requests-purchase');
        }

        const columns = useMemo(
            () => [
                {
                    id: 'articleNumber',
                    header: 'Артикул',
                    accessorKey: 'materialOrganizationOption.articleNumber',
                    minSize: 200,
                    maxSize: 200,
                },
                {
                    id: 'name',
                    header: 'Найменование',
                    accessorKey: 'materialOrganizationOption.name',
                    minSize: 300,
                    maxSize: 300,
                    enableEditing: false,
                },
                {
                    id: 'unit.name',
                    header: 'Ед. изм.',
                    accessorKey: 'materialOrganizationOption.unit.name',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    id: 'quantity',
                    header: 'Кол.',
                    accessorKey: 'quantity',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    id: 'requestsCuttingOptimizationQuantity',
                    header: 'Кол. Опт.',
                    accessorKey: 'requestsCutting.optimizationQuantity',
                    minSize: 15,
                    maxSize: 15,
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                },
            ],
            [data, setData],
        );

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection={false}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const setCurrency = (typeCurrentCurrency) => {
                        };

                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={redirectRequestsPurchase}
                                    >
                                        Вернутся в заявки
                                    </Button>
                                </Box>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        size="small"
                                        color="success"
                                        onClick={() => setSetPurchaseModalOpen(true)}
                                        variant="contained"
                                        disabled={purchaseStatus}
                                    >
                                        Создать заявку на закупку
                                    </Button>
                                </Box>
                            </div>
                        );
                    }}
                />
                <ModalOpenSetValues
                    title={titleModalOpenSetValues}
                    label={labelModalOpenSetValues}
                    modalOpen={modalCurrencyOpen}
                    handleClose={handleCloseModalCurrency}
                    setValue={setCurrentCurrencyBM}
                    handleSubmit={updateCurrencyByIds}
                />
                <ModalConfirm
                    title={"Подтвердить создание заявки на закупку?"}
                    modalOpen={setPurchaseModalOpen}
                    handleClose={handleCloseSetPurchase}
                    handleSubmit={handleSubmitSetPurchase}
                    action={"Подтвердить"}/>
            </div>
        );
    }
;

export default RequestsPurchaseTableItem;

