import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class HoleControllerService {
    static async save(detailId, data) {
        return await axios.post(`${detailControllerRestUrl}1/details/${detailId}/holes`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(detailId, holeId) {
        return await axios.delete(`${detailControllerRestUrl}1/details/${detailId}/holes/${holeId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async put(detailId, holeId, data) {
        return await axios.put(`${detailControllerRestUrl}1/details/${detailId}/holes/${holeId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
