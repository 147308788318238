import React, {useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link} from "react-router-dom";
import CellShortenedName from "../../../orders/organization/estimate/CellShortenedName";
import {Button, Tooltip} from "@mui/material";
import {Cached, PlaylistAdd} from "@mui/icons-material";
import Box from "@mui/material/Box";

const AllMaterialTable = ({
                              currentData,
                              setCurrentData,
                              refMaterial,
                              addMaterialInArrival,
                              addMaterial,
                              enableEditing,
                              isAddMaterial,
                              enableRowSelection,
                              selectInEstimate,
                          }) => {

    const [rowSelection, setRowSelection] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);

    const columns = useMemo(
        () => [
            {
                header: 'Артикул',
                accessorKey: 'articleNumber',
                minSize: 50,
                maxSize: 75,
                Cell: ({cell}) => <CellShortenedName
                    value={cell.getValue()}
                    numberOfCharacters={15}
                />,
            },
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 600,
                Cell: ({cell, row}) => (refMaterial ?
                        <li key={cell.getValue()}>
                            <Link
                                to={'/all-materials/' + currentData[row.index].id + '/' + currentData[row.index].unit.name + '/' + currentData[row.index].typeMaterial}>
                                {cell.getValue()}</Link>
                        </li> : cell.getValue()
                ),
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'unit.name',
                minSize: 15,
            },
            {
                id: 'groupMaterial.name',
                header: 'Группа',
                accessorKey: 'groupMaterial.name',
                minSize: 100,
            },
            {
                id: 'manufacturer.name',
                header: 'Производитель',
                accessorKey: 'manufacturer.name',
                minSize: 100,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [currentData, setCurrentData, refMaterial],
    );

    return <MaterialReactTable
        muiTableBodyProps={{
            sx: () => ({
                '& tr:nth-of-type(odd)': {
                    backgroundColor: 'lightgray',
                },
            }),
        }}
        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 10px',
                m: '0px 0px 0px 0px',
            },
        }}
        muiTableBodyRowProps={{
            sx: {
                height: '10px',
            },
        }}
        displayColumnDefOptions={{
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center',
                },
                size: 120,
            },
        }}
        columns={columns}
        data={currentData}
        onRowSelectionChange={setRowSelection}
        state={{rowSelection}}
        enableRowSelection={enableRowSelection}
        getRowId={(originalRow) => originalRow.id}
        selectionPadding={1}
        selectionCss={{
            backgroundColor: 'lightblue',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'blue',
            borderRadius: '4px',
        }}
        initialState={{
            columnVisibility: {
                id: false,
            },
            isFullScreen: false,
            density: "compact",
            pagination: {pageIndex: 0, pageSize: 40},
        }}
        localization={MRT_Localization_RU}
        enableEditing={enableEditing}
          renderTopToolbarCustomActions={({table}) => {
              const setCurrency = () => {
                  const selectedRows = table.getSelectedRowModel().flatRows;
                  const selectedIds = selectedRows.map((row) => row.getValue('id'));
                  setSelectedIds(selectedIds);
              };

              return (
                  <div>
                      <Box
                          sx={{
                              display: 'flex',
                              gap: '0.5rem',
                              paddingLeft: '0.5rem',
                              paddingTop: '0.5rem',
                              paddingBottom: '0.2rem',
                              flexWrap: 'wrap',
                          }}
                      >
                      </Box>
                      <Box
                          sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                      >
                          {isAddMaterial && <Button
                              size="small"
                              color="secondary"
                              onClick={addMaterial}
                              variant="contained"
                          >
                              Добавить материал
                          </Button>}
                          {false && <Button
                              size="small"
                              color="success"
                              disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                              onClick={() => setCurrency('base')}
                              variant="contained"
                          >
                              Добавить материалы
                          </Button>}
                      </Box>
                  </div>
              );
          }}

          renderRowActions={({row}) => (
              <Box sx={{
                  display: 'flex',
                  gap: '0.1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxWidth: '50px'
              }}>
                  {false && <Tooltip arrow placement="left" title="Добавить">
                      <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                              onClick={() => addMaterialInArrival(row)}>
                          <PlaylistAdd sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                      </Button>
                  </Tooltip>}
                  {false && <Tooltip arrow placement="left" title="Заменить">
                      <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                              onClick={() => selectInEstimate(row)}>
                          <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                      </Button>
                  </Tooltip>}
              </Box>
          )}
    />;
};

export default AllMaterialTable;