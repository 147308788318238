import React, {useCallback, useMemo, useState} from 'react';
import {Button, Tooltip} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import AttributeUtil from "../../../../../../utils/orders/AttributeUtil";
import OrderUtil from "../../../../../../utils/orders/OrderUtil";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import CustomerService from "../../../../../../API/orders/orders/organization/CustomerService";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import {Delete, Edit, Unarchive} from "@mui/icons-material";
import ModalConfirm from "../../../../modal/ModalConfirm";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import UpdateOrderModal from "./UpdateOrderModal";
import CreateNewOrderModal from "./CreateNewOrderModal";
import ModalSetPlan from "./ModalSetPlan";

const OrderTable = ({
                        data,
                        setData,
                        customerList,
                        nameList,
                        attributeList,
                        planningList,
                        statusList,
                        isIssuingEdge,
                        enableEditing,
                        change,
                        setChange,
                        createIssuingEdgeOpen,
                        deleteOrdersInIssuingEdge,
                        forWriteOff,
                        archive

                    }) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const [updateModalIssuingEdgeOpen, setUpdateModalIssuingEdgeOpen] = useState(false);
    const [deleteModalIssuingEdgeOpen, setDeleteModalIssuingEdgeOpen] = useState(false);
    const [sendArchiveModalOpen, setSendArchiveModalOpen] = useState(false);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [dateOfReceipt, setDateOfReceipt] = useState(null);
    const [dateOfPlannedProduction, setDateOfPlannedProduction] = useState(null);
    const [plannedDateOfShipment, setPlannedDateOfShipment] = useState(null);
    const [manufactured, setManufactured] = useState(null);
    const [shipped, setShipped] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [setPlanModalOpen, setSetPlanModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [defaultAttributeId, setDefaultAttributeId] = useState(null);

    const navigate = useNavigate();

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const handleUpdate = (row, values) => {
        data[row[currentEditRowId]] = values;
        setData([...data]);
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setDateOfReceipt(row.original.dateOfReceipt);
            setDateOfPlannedProduction(row.original.dateOfPlannedProduction)
            setPlannedDateOfShipment(row.original.plannedDateOfShipment)
            setManufactured(row.original.manufactured)
            setShipped(row.original.shipped)
            setCurrentEditRowId(row.index);
            setUpdateModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const sendToArchive = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setSendArchiveModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleSubmitSendToArchive = () => {
        OrderService.sendToArchive(currentEditRowData.id)
            .then(function () {
                data.splice(currentEditRowData.index, 1);
                setData([...data]);
                setCurrentEditRowData(null);
                setSendArchiveModalOpen(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseModalSendToArchive = () => {
        setSendArchiveModalOpen(false);
    };

    const handleDeleteRow = useCallback(
        (row) => {
            CustomerService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleCloseModalIssuingEdge = () => {
        setUpdateModalIssuingEdgeOpen(false);
    };

    const handleCloseModalDeleteOrdersInIssuingEdge = () => {
        setDeleteModalIssuingEdgeOpen(false);
    };

    const handleSubmitCreateIssuingEdge = () => {
        createIssuingEdgeOpen(selectedIds);
        setUpdateModalIssuingEdgeOpen(false)
    };

    const handleSubmitDeleteIssuingEdge = () => {
        deleteOrdersInIssuingEdge(selectedIds);
        setDeleteModalIssuingEdgeOpen(false);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: '№ заказа',
                accessorKey: 'number',
                filterFn: 'contains',
                maxSize: 50,
                minSize: 50,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/orders/' + data[row.index].id + '/' + cell.getValue() + '/' + data[row.index].header.id + '/' + data[row.index].year}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'customer',
                header: 'Заказчик',
                accessorKey: 'customerOrder.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: customerList.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                minSize: 15,
            },
            {
                id: 'header',
                header: 'Найменование',
                accessorKey: 'header.name',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                minSize: 15,
            },
            {
                id: 'plan',
                header: 'План',
                accessorKey: 'plan.name',
                filterVariant: 'multi-select',
                filterSelectOptions: planningList,
                minSize: 6,
                maxSize: 6,
                Cell: ({cell, row}) => (
                    <>
                        {data[row.index]?.plan !== null && (
                            <li key={row.id}>
                                <Link to={'/organization/plans/' + data[row.index]?.plan?.id}>{data[row.index]?.plan?.name}</Link>
                            </li>
                        )}
                    </>
                ),
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfPlannedProduction === null) {
                    } else {
                        return new Date(row.dateOfPlannedProduction)
                    }
                },
                id: 'dateOfPlannedProduction',
                header: 'План. пр.',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },

            {
                id: 'year',
                header: 'год                                                                     ',
                accessorKey: 'year',
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        if (!isIssuingEdge) {
            baseColumns.splice(2, 0, {
                id: 'numberCustomer',
                header: '№ заказчика',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(4, 0, {
                accessorFn: (row) => {
                    if (row.dateOfReceipt === null) {
                    } else {
                        return new Date(row.dateOfReceipt)
                    }
                },
                id: 'dateOfReceipt',
                header: 'Поступление',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(5, 0, {
                id: 'status',
                header: 'Статус',
                accessorKey: 'status.name',
                filterVariant: 'multi-select',
                filterSelectOptions: statusList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: statusList.map((status) => (
                        <MenuItem key={status} value={status}>
                            {status}
                        </MenuItem>
                    )),
                },
                enableGrouping: true,
                maxSize: 25,
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(6, 0, {
                id: 'attributes',
                header: 'Атрибуты',
                accessorKey: 'attributes',
                filterVariant: 'multi-select',
                filterSelectOptions: attributeList,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkStatus(row.getValue(id), filterValue)
                },
                minSize: 20,
                Cell: ({cell, row}) => (
                    <li key={row.id}>
                        <Link
                            to={'/organization/orders/set-attribute/' + data[row.index].id + '/' + data[row.index].number + '/'
                                + data[row.index].header.id + '/' + data[row.index].year}
                        >{data[row.index].attributes.length === 0 ? 'Не устанволен' : AttributeUtil.convertAttributes(cell.getValue())}</Link>
                    </li>
                ),
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(10, 0, {
                id: 'manufactured',
                header: 'Произв.',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            });
        }

        if (!isIssuingEdge) {
            baseColumns.splice(11, 0, {
                accessorFn: (row) => {
                    if (row.plannedDateOfShipment === null) {
                    } else {
                        return new Date(row.plannedDateOfShipment)
                    }
                },
                id: 'plannedDateOfShipment',
                header: 'План. отг.',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            });
        }

        if (!isIssuingEdge && (forWriteOff || archive)) {
            baseColumns.splice(11, 0, {
                accessorFn: (row) => {
                    if (row.shipped === null) {
                    } else {
                        return new Date(row.shipped)
                    }
                },
                id: 'shipped',
                header: 'Отгружено',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            });
        }

        return baseColumns;

    }, [data, setData, isIssuingEdge]);

    return (
        <>
            <MaterialReactTable
                enableEditing={enableEditing}
                enableColumnFilterModes
                enableGrouping
                enableStickyHeader
                enablePinning
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 5px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                initialState={{
                    columnVisibility: {
                        id: false,
                        model: false,
                        year: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                    columnPinning: {left: ['number']}
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const setPlan = () => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                        setSetPlanModalOpen(true)
                    };

                    const createIssuingEdge = () => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                        setUpdateModalIssuingEdgeOpen(true)
                    };

                    const openModalDeleteOrdersInIssuingEdge = () => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                        setDeleteModalIssuingEdgeOpen(true)
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >

                                {!isIssuingEdge && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectPlans}
                                    variant="contained"
                                >
                                    Планы
                                </Button>}
                                {!isIssuingEdge && <Button
                                    size="small"
                                    color="success"
                                    onClick={() => setCreateModalOpen(true)}
                                    variant="contained"
                                >
                                    Создать заказ
                                </Button>}
                                {!isIssuingEdge && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={() => setPlan()}
                                    variant="contained"
                                >
                                    Установить план
                                </Button>}
                                {isIssuingEdge && createIssuingEdgeOpen && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={() => createIssuingEdge()}
                                    variant="contained"
                                >
                                    Создать выдачу материалов
                                </Button>}
                                {isIssuingEdge && deleteOrdersInIssuingEdge && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={() => openModalDeleteOrdersInIssuingEdge()}
                                    variant="contained"
                                >
                                    Удалить заказы
                                </Button>}
                            </Box>
                        </div>
                    );
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!forWriteOff && <Tooltip arrow placement="left" title="Редактировать">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => handleUpdateModel(row)}>
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>}
                        {/*{!forWriteOff && <Tooltip arrow placement="right" title="Удалить">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>}*/}
                        {forWriteOff &&
                            <Tooltip arrow placement="right" title="Поместить в архив">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => sendToArchive(row)}>
                                    <Unarchive sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                    </Box>
                )}
            />
            <CreateNewOrderModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                customerList={customerList}
                defaultAttributeId={defaultAttributeId}
                setDefaultAttributeId={setDefaultAttributeId}
            />
            <UpdateOrderModal
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                onSubmit={handleUpdate}
                values={currentEditRowData}
                currentEditRowId={currentEditRowId}
                change={change}
                setChange={setChange}
                setValues={setCurrentEditRowData}
                dateOfReceipt={dateOfReceipt}
                setDateOfReceipt={setDateOfReceipt}
                dateOfPlannedProduction={dateOfPlannedProduction}
                setDateOfPlannedProduction={setDateOfPlannedProduction}
                plannedDateOfShipment={plannedDateOfShipment}
                setPlannedDateOfShipment={setPlannedDateOfShipment}
                manufactured={manufactured}
                setManufactured={setManufactured}
                shipped={shipped}
                setShipped={setShipped}
            />
            <ModalSetPlan
                open={setPlanModalOpen}
                handleClose={() => setSetPlanModalOpen(false)}
                selectedIds={selectedIds}
                data={data}
                setData={setData}
            />
            <ModalConfirm
                title={"Подтвердить создание выдачи материалов?"}
                modalOpen={updateModalIssuingEdgeOpen}
                handleClose={handleCloseModalIssuingEdge}
                handleSubmit={handleSubmitCreateIssuingEdge}
                action={"Подтвердить"}
            />
            <ModalConfirm
                title={"Подтвердить удаление заказов из выдачи?"}
                modalOpen={deleteModalIssuingEdgeOpen}
                handleClose={handleCloseModalDeleteOrdersInIssuingEdge}
                handleSubmit={handleSubmitDeleteIssuingEdge}
                action={"Подтвердить"}
            />
            <ModalConfirm
                title={"Подтвердить поместить в архив"}
                modalOpen={sendArchiveModalOpen}
                handleClose={handleCloseModalSendToArchive}
                handleSubmit={handleSubmitSendToArchive}
                action={"Подтвердить"}
            />
        </>
    );
};

export default OrderTable;