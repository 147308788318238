import React, {useState} from 'react';
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import Typography from "@mui/material/Typography";
import UploadFilesRequestsForm from "./UploadFilesRequestsForm";
import FileService from "../../../../API/orders/orders/organization/FileService";

const RequestsCuttingEstimateItem = ({
                                         getAll,
                                         isOptimization,
                                         isRedirectRequestsCutting,
                                         orderId,
                                         nameOrder,
                                         idHeader,
                                         year,
                                         isOrder,
                                         isButtonAddRequestsCutting,
                                         isEstimate,
                                         isCreateOptimizationCalculation,
                                         isSetForOptimization
                                     }) => {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [change, setChange] = useState(false);

    return (

        <Box sx={{
            '& > *': {
                marginTop: '0px',
            }
        }}>
            <TabContext value={value}>
                <Box sx={{
                    borderBottom: 1, borderColor: 'divider'
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Заявки на раскрой" value='1'/>
                        <Tab label="Импорт" value='2'/>
                    </Tabs>
                </Box>
                <TabPanel value='1'>
                    <RequestsCuttingTable
                        getAll={getAll}
                        isOptimization={isOptimization}
                        isRedirectRequestsCutting={isRedirectRequestsCutting}
                        orderId={orderId}
                        nameOrder={nameOrder}
                        idHeader={idHeader}
                        year={year}
                        isOrder={isOrder}
                        isButtonAddRequestsCutting={isButtonAddRequestsCutting}
                        isEstimate={isEstimate}
                        isCreateOptimizationCalculation={isCreateOptimizationCalculation}
                        isSetForOptimization={isSetForOptimization}
                        hideOrder={true}
                        hideWorkingCuttingMap={true}
                        customer={true}
                    />
                </TabPanel>
                <TabPanel value='2'>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <Typography variant="h6" component="h6">
                            Импорт заявок на раскрой из файлов раскроя базис-мебельщика, без создания материала в смете
                            *.xls (необходимо наличие соотвествующих материалов в смете)
                        </Typography>
                        <UploadFilesRequestsForm
                            orderId={orderId}
                            change={change}
                            setChange={setChange}
                            calculationRequestsCutting={false}
                            upload={FileService.uploadRequestsCuttingFiles}
                        />
                    </Box>
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default RequestsCuttingEstimateItem;