import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";
import ObjectUtil from "../../../../utils/common/ObjectUtil";

const materialOrganizationOptionRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/material-organization-options'

export default class MaterialOrganizationOptionService {


    static async getWithGroupMaterialAndDefaultSupplierById(id) {
        return axios.get(materialOrganizationOptionRestUrl + '/' + id + '/with-group-material-and-default-supplier');
    }

    static async getAllWithGroupMaterialAndUnit() {
        return axios.get(materialOrganizationOptionRestUrl + '/with-group-material-unit');
    }

    static async save(materialId, data) {
        return await axios.post(materialOrganizationOptionRestUrl + '?materialId=' + materialId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addSigJobInMaterialsByMaterialGroup(dataSignJob, dataMaterialGroupIds) {
        let data = ObjectUtil.createRequestTwoObjectIds(dataSignJob, dataMaterialGroupIds);
        return await axios.put(materialOrganizationOptionRestUrl + '/add-sig-job-in-materials-by-material-group' , JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createByMaterial(materialId) {
        return await axios.post(materialOrganizationOptionRestUrl + '/by-material' + '?materialId=' + materialId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getChoiceSlabMaterial(materialId, supplierId) {
        return axios.get(materialOrganizationOptionRestUrl
            + '/get-choice-slab-material' + "?materialId=" + materialId + "&supplierId=" + supplierId);
    }

    static async getWithDefaultSupplier(nameMaterial) {
        return axios.get(materialOrganizationOptionRestUrl + '/with-default-supplier' + "?nameMaterial="
            + nameMaterial);
    }

    static async getWithFilter(typeMaterial, unitId, groupMaterialId, manufacturerId) {
        return axios.get(materialOrganizationOptionRestUrl + '/with-filter'
            + "?typeMaterial=" + typeMaterial + "&unitId=" + unitId
            + "&groupMaterialId=" + groupMaterialId + "&manufacturerId=" + manufacturerId
        );
    }

    static async getForCloudCatalog(materialId) {
        return axios.get(materialOrganizationOptionRestUrl + '/for-cloud-catalog'
            + "?materialId=" + materialId);
    }

    static async updateCurrentOptions(data, supplierId, manufacturerId, groupMaterialId) {
        return await axios.put(materialOrganizationOptionRestUrl + '/' + data.id + "?supplierId="
            + supplierId + "&manufacturerId=" + manufacturerId + "&groupMaterialId=" + groupMaterialId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
