import {Box, Tabs} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {TabContext, TabPanel} from "@mui/lab";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import Tab from "@mui/material/Tab";
import React, {useEffect, useRef, useState} from "react";
import DetailProcessing from "./DetailProcessing/DetailProcessing";
import {constantsErrorMessages} from "../../../Constants";
import DetailProcessingVariables from "./DetailProcessingVariables/DetailProcessingVariables";
import DetailControllerService from "../../../API/details/DetailControllerService";
import {useParams} from "react-router-dom";

const Processing = () => {
    const params = useParams();
    const detailId = params.id
    const orderId = params.orderId

    const refCanvas = useRef()

    const [currTab, setCurrTab] = useState('1');

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [change, setChange] = useState(true);

    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'
    const [detailData, setDetailData] = useState({});
    const [currScale, setCurrScale] = useState({});

    useEffect(() => {
        setIsLoading(true)
        DetailControllerService.getById(orderId, detailId).then(function (response) {
            setDetailData(response.data);
            setIsError(false)
            window.addEventListener("resize", () => resizeHandler(response.data));
            return () => {
                window.removeEventListener("resize", resizeHandler);
            };
        })
            .catch(function (error) {
                handleError(error)
            })
            .finally(() => {
                setIsLoading(false)
            })


    }, [change]);

    useEffect(() => {
        refCanvas.current && resizeHandler(detailData)
    }, [detailData])

    const resizeHandler = (data) => {

        const {clientHeight, clientWidth} = refCanvas.current || {};
        const initScale = 0.005

        const lengthDetailRatio = clientWidth / data.length
        const widthDetailRatio = clientHeight / data.width
        const newCurrScale = lengthDetailRatio < widthDetailRatio ? lengthDetailRatio * initScale : widthDetailRatio * initScale
        setCurrScale(newCurrScale)
    };

    const handleTabsChange = (target) => {
        setCurrTab(target)
    }

    const handleError = (error) => {
        console.log(error)
        setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response?.status] || error.response.data.message || error.message
        })
    }

    return (
        isLoading ?
            <Box sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> :
            <TabContext value={currTab}>
                <ModalAlert data={alertData} onClose={() => setAlertData((alert) => ({...alert, open: false}))}/>
                <Box sx={{
                    borderBottom: 1, borderColor: 'divider',
                }}>
                    <Tabs value={currTab} onChange={(e, target) => handleTabsChange(target)}
                          aria-label="basic tabs example">
                        <Tab label="Обработка" value='1'/>}
                        <Tab label="Переменные" value='2'/>
                    </Tabs>
                </Box>
                <TabPanel value='1' sx={{padding: 0}}>
                    <DetailProcessing detailId={detailId} detailData={detailData} refCanvas={refCanvas}
                                      currScale={currScale} handleError={handleError} setChange={setChange}
                                      orderId={orderId}
                    />
                </TabPanel>
                <TabPanel value='2'>
                    <DetailProcessingVariables/>
                </TabPanel>
            </TabContext>
    )
}
export default Processing
