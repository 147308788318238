import React from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import MenuAppBar from "./components/UI/navbar/MenuAppBar";
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#3B8BEB'
        },
        secondary: {
            main: '#B23850',
        },
        success: {
            main: '#2e7d32',
            light: '#4caf50',
        },
        error: {
            main: '#d32f2f',
            light: '#ef5350',
        },
        pencil: {
            main: '#ea8508'
        },
    },
});

function App() {
    localStorage.setItem("auth", "false")
    return (
        <ThemeProvider theme={theme}>
            <MenuAppBar></MenuAppBar>
            <BrowserRouter>
                <AppRouter></AppRouter>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;