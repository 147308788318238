import Box from "@mui/material/Box";
import CellCheckboxAMPUTFixPrice from "../components/UI/table/warehouse/material/arrival/CellCheckboxAMPUTFixPrice";
import CellCheckboxAMPUTPrice from "../components/UI/table/warehouse/material/arrival/CellCheckboxAMPUTPrice";
import CellWithPairCurrency from "../components/UI/сell/CellWithPairCurrency";
import React, {useMemo} from "react";

export const ArrivalMaterialPriceUnitColumns = ({
                                                    data,
                                                    setData,
                                                    currentCurrency,
                                                    alternativeCurrency,
                                                    baseCurrency,
                                                    slabMaterial
                                                }) => {
    return useMemo(() => {
        const baseColumns = [
            {
                id: 'articleNumberSupplier',
                header: 'Артикул поставщика',
                accessorKey: 'supplierMaterial.article',
                minSize: 200,
                maxSize: 200,
            },
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 200,
                maxSize: 200,
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 300,
                maxSize: 300,
                enableEditing: false,
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'amountInCurrencyArrival',
                header: `Сумма ${currentCurrency}`,
                accessorKey: 'amountInCurrencyArrival',
                minSize: 8,
                maxSize: 8,
            },
            {
                id: 'priceInCurrencyArrival',
                header: `Цена ${currentCurrency}`,
                accessorKey: 'materialPriceInCurrencyArrival.price',
                minSize: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'exchangeRateToBaseCurrencyBaseMaterials',
                header: 'курс БВ',
                accessorKey: 'exchangeRateToBaseCurrencyBaseMaterials',
                minSize: 8,
                maxSize: 8,
                size: 8,
                Header: ({column}) => <Box sx={{whiteSpace: 'pre-wrap', padding: 0}}>{column.columnDef.header}</Box>,
            },
            {
                id: 'priceInCurrencyBase',
                header: `Цена ${baseCurrency}`,
                accessorKey: 'materialPriceInCurrencyBase.price',
                minSize: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'exchangeRateToAlternativeCurrencyBaseMaterials',
                header: 'курс АВ',
                accessorKey: 'exchangeRateToAlternativeCurrencyBaseMaterials',
                minSize: 8,
                maxSize: 8,
                size: 8,
                Header: ({column}) => <Box sx={{whiteSpace: 'pre-wrap', padding: 0}}>{column.columnDef.header}</Box>,
            },
            {
                id: 'priceInCurrencyAlternative',
                header: `Цена ${alternativeCurrency}`,
                accessorKey: 'materialPriceInCurrencyAlternative.price',
                minSize: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'basePrice',
                header: 'Баз. цена',
                accessorKey: 'supplierMaterial.basePrice.price',
                size: 8,
                Cell: ({cell, row}) => {
                    const basePrice = row.original.supplierMaterial?.basePrice || null;
                    return basePrice === null ? '' : (
                        <CellCheckboxAMPUTFixPrice
                            price={basePrice}
                            fixPrice={row.original.supplierMaterial.fixPrice}
                        />
                    );
                },

                minSize: 8,
                maxSize: 8,
            },
            {
                id: 'realBasePrice',
                header: 'Тек. цена',
                accessorKey: 'materialPriceInRealBasePrice.price',
                size: 8,
                Cell: ({row}) => <CellCheckboxAMPUTPrice
                    price={row.original.materialPriceInRealBasePrice}
                />,
                minSize: 8,
                maxSize: 8,
            },
            {
                id: 'priceIncreaseInBasePrice',
                header: 'Расх., %',
                accessorKey: 'priceIncreaseInBasePrice',
                size: 8,
                minSize: 8,
                maxSize: 8,
            },
            {
                id: 'exchangeRateToBasePrice',
                header: 'курс БЦ',
                accessorKey: 'exchangeRateToBasePrice',
                minSize: 20,
                maxSize: 20,
                Header: ({column}) => <Box sx={{whiteSpace: 'pre-wrap', padding: 0}}>{column.columnDef.header}</Box>,
                Cell: ({cell, row}) => cell.getValue() === null ? '' : (<CellWithPairCurrency
                    number={cell.getValue()}
                    currency1={currentCurrency}
                    currency2={row.original.supplierMaterial.basePrice.currency.curAbbreviation}
                />),
            },
            {
                id: 'coefficientCurrencyBasePrice',
                header: 'Кф. курса БЦ',
                accessorKey: 'coefficientCurrencyBasePrice',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'losses',
                header: `Потери ${alternativeCurrency}`,
                accessorKey: 'losses',
                minSize: 8,
                maxSize: 8,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },


        ];

        if (slabMaterial) {
            baseColumns.splice(3, 0, {
                id: 'length',
                header: 'L',
                accessorKey: 'length',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (slabMaterial) {
            baseColumns.splice(4, 0, {
                id: 'height',
                header: 'H',
                accessorKey: 'height',
                minSize: 15,
                maxSize: 15,
            });
        }

        return baseColumns;

    }, [data, setData, currentCurrency, alternativeCurrency, baseCurrency, slabMaterial]);
};