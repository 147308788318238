import React, {useEffect, useState} from 'react';
import OrderTable from "../../../../components/UI/table/orders/organization/order/OrderTable";
import OrderService from "../../../../API/orders/orders/organization/OrderService";
import DataUtil from "../../../../utils/common/DataUtil";
import OrderUtil from "../../../../utils/orders/OrderUtil";
import UserService from "../../../../API/common/UserService";

const Orders = () => {

    const [data, setData] = useState([]);

    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [change, setChange] = useState(null);

    useEffect(() => {
        OrderService.getOrders().then(function (response) {
            let data = response.data;
            setData(data);
            setCustomerList(DataUtil.getListChildField(data, 'customerOrder', 'name'));
            setNameList(DataUtil.getListChildField(data, 'header', 'name'));
            setAttributeList(OrderUtil.getStatuses(data, 'attributes'));
            setPlanningList(DataUtil.getListChildField(data, 'plan', 'name'));
            setStatusList(DataUtil.getListChildField(data, 'status', 'name'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const redirectSettingsOrders = () => {
        UserService.authorized().then(function (response) {
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <div>
            <OrderTable
                data={data}
                setData={setData}
                customerList={customerList}
                nameList={nameList}
                attributeList={attributeList}
                planningList={planningList}
                statusList={statusList}
                enableEditing={true}
                change={change}
                setChange={setChange}
            />
        </div>
    );
};

export default Orders;