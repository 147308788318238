import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import MaterialTree from "../tree/MaterialTree";
import MaterialTable from "../table/warehouse/material/material/MaterialTable";
import MaterialsUtil from "../../../utils/warehouse/MaterialsUtil";
import MaterialOrganizationOptionService
    from "../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import GroupMaterialService from "../../../API/orders/orders/organization/GroupMaterialService";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";
import SignJobService from "../../../API/orders/orders/organization/job/SignJobService";

const MaterialPageItemModule = ({
                                    refMaterial,
                                    pageSize,
                                    addMaterialInArrival,
                                    enableEditing,
                                    isAddMaterial,
                                    isSelectInArrival,
                                    isSelectInEstimate
                                }) => {

    const [filterGroup, setFilterGroup] = useState(new Map());
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);
    const [dataTree, setDataTree] = useState([]);
    const [groupMap, setGroupMap] = useState(null);

    const [rowSelection, setRowSelection] = useState({});
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const [openModalAddSignJob, setOpenModalAddSignJob] = useState(false);
    const [groupNames, setGroupNames] = useState("");
    const [groupIds, setGroupIds] = useState([]);
    const [disabledAddMaterial, setDisabledAddMaterial] = useState(true);

    const handleCloseModalAddSignJob = () => {
        setOpenModalAddSignJob(false)
        setRowSelection({});
        setRowIndexSelection([]);
        setSelectedIds([]);
    };

    const handleSubmitAddSignJobInMaterial = () => {
        MaterialOrganizationOptionService.addSigJobInMaterialsByMaterialGroup(selectedIds, groupIds).then(function () {
            handleCloseModalAddSignJob();
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [change, setChange] = useState(false);
    const [dataSignJob, setDataSignJob] = useState([]);


    useEffect(() => {
        SignJobService.getAll().then(function (response) {
            let data = response.data;
            setDataSignJob(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    useEffect(() => {
        MaterialOrganizationOptionService.getAllWithGroupMaterialAndUnit().then(function (response) {
            setData(response.data);
            setCurrentData(response.data)
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
       if (groupIds.length===0) {
           setDisabledAddMaterial(true)
       }else {
           setDisabledAddMaterial(false)
       }
    }, [groupIds]);

    useEffect(() => {
        setCurrentData(MaterialsUtil.applyFilter(data, filterGroup, setFilterGroup))
        setGroupNames(Array.from(filterGroup, ([key, value]) => value.name).join(', '))
        setGroupIds(Array.from(filterGroup, ([key, value]) => value.id))
    }, [changeFilterGroup]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await GroupMaterialService.getAllByOrganization();
                const buildTreeGroups = GroupMaterialUtil.buildTreeGroups(response.data);
                setDataTree(buildTreeGroups);
                const groupMap = new Map();
                GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
                setGroupMap(groupMap);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '3px',
            "& > *:not(:last-child)": {marginRight: '3px'}
        }}>
            <Box sx={{flexBasis: '50%', maxWidth: '400px'}}>
                <MaterialTree
                    filterGroup={filterGroup}
                    setFilterGroup={setFilterGroup}
                    changeFilterGroup={changeFilterGroup}
                    setChangeFilterGroup={setChangeFilterGroup}
                    data={dataTree}
                    groupMap={groupMap}
                />
            </Box>
            <Box sx={{
                flexBasis: 'auto',
                marginLeft: '3px',
                overflow: 'auto',
                paddingRight: '3px',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px'
            }}>
                <MaterialTable
                    currentData={currentData}
                    setCurrentData={setCurrentData}
                    refMaterial={refMaterial}
                    pageSize={pageSize}
                    addMaterialInArrival={addMaterialInArrival}
                    enableEditing={enableEditing}
                    isAddMaterial={isAddMaterial}
                    isSelectInArrival={isSelectInArrival}
                    isSelectInEstimate={isSelectInEstimate}
                    openModalAddSignJob={openModalAddSignJob}
                    setOpenModalAddSignJob={setOpenModalAddSignJob}
                    handleCloseModalAddSignJob={handleCloseModalAddSignJob}
                    handleSubmitAddSignJobInMaterial={handleSubmitAddSignJobInMaterial}
                    groupNames={groupNames}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    rowIndexSelection={rowIndexSelection}
                    setRowIndexSelection={setRowIndexSelection}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    disabledAddMaterial={disabledAddMaterial}
                    dataSignJob={dataSignJob}
                    setDataSignJob={setDataSignJob}
                    change={change}
                    setChange={setChange}
                    sx={{
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                        maxWidth: '100%',
                    }}/>
            </Box>
        </Box>
    );
};

export default MaterialPageItemModule;