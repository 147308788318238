import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import MaterialTree from "../tree/MaterialTree";
import MaterialsUtil from "../../../utils/warehouse/MaterialsUtil";
import AllMaterialTable from "../table/warehouse/material/material/AllMaterialTable";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";
import UserGroupMaterialMaterialCloudService
    from "../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import UserMaterialMaterialCloudService from "../../../API/material-cloud/user/UserMaterialMaterialCloudService";

const AllMaterialPageItemModule = ({
                                       refMaterial,
                                       pageSize,
                                       setPageSize,
                                       addMaterialInArrival,
                                       enableEditing,
                                   }) => {

    const [filterGroup, setFilterGroup] = useState(new Map());
    const [data, setData] = useState([]);
    const [dataTree, setDataTree] = useState([]);
    const [groupMap, setGroupMap] = useState(null);

    const [currentData, setCurrentData] = useState([]);
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);

    useEffect(() => {
        UserMaterialMaterialCloudService.getAllForCatalog().then(function (response) {
            setData(response.data);
            setCurrentData(response.data)
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UserGroupMaterialMaterialCloudService.getAll();
                let data = response.data;
                GroupMaterialUtil.localizationReplacement(data);
                const buildTreeGroups = GroupMaterialUtil.buildTreeGroups(data);
                setDataTree(buildTreeGroups);
                const groupMap = new Map();
                GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
                setGroupMap(groupMap);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setCurrentData(MaterialsUtil.applyFilter(data, filterGroup, setFilterGroup));
    }, [changeFilterGroup]);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '3px',
            "& > *:not(:last-child)": {marginRight: '3px'}
        }}>
            <Box sx={{flexBasis: '50%', maxWidth: '400px'}}>
                <MaterialTree
                    filterGroup={filterGroup}
                    setFilterGroup={setFilterGroup}
                    changeFilterGroup={changeFilterGroup}
                    setChangeFilterGroup={setChangeFilterGroup}
                    data={dataTree}
                    groupMap={groupMap}
                />
            </Box>
            <Box sx={{
                flexBasis: 'auto',
                marginLeft: '3px',
                overflow: 'auto',
                paddingRight: '3px',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px'
            }}>
                <AllMaterialTable
                    currentData={currentData}
                    setCurrentData={setCurrentData}
                    refMaterial={refMaterial}
                    addMaterialInArrival={addMaterialInArrival}
                    enableEditing={enableEditing}
                    isAddMaterial={false}
                    enableRowSelection={true}
                    sx={{
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                        maxWidth: '100%',
                    }}/>
            </Box>
        </Box>
    );
};

export default AllMaterialPageItemModule;