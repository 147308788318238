import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from "react-router-dom";
import MaterialWarehouseService from "../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import PriceWithCurrency from "./PriceWithCurrency";
import WarehouseMaterialSettingsService
    from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import CellCheckbox from "../../../сheckbox/CellCheckbox";
import CellCheckboxAMPUTFixPrice from "./arrival/CellCheckboxAMPUTFixPrice";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";

const WarehouseMaterialItemTable = ({id, baseUnit, typeMaterial}) => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");

    const navigate = useNavigate();

    useEffect(() => {

        MaterialWarehouseService.getAllByMaterialId(id).then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation)
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation)
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const sumTotalIssued = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.totalIssued, 0),
        [data]
    );

    const sumInReserve = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.inReserve, 0),
        [data]
    );

    const sumFreeQuantity = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.freeQuantity, 0),
        [data]
    );

    const sumArrivalAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.arrivalAmountBasePrice, 0),
        [data]
    );

    const sumArrivalAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.arrivalAmountAlternativePrice, 0),
        [data]
    );

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountBasePrice, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountAlternativePrice, 0),
        [data]
    );

    const columns = useMemo(() => {
        const baseColumns = [
            {
                accessorFn: (row) => {
                    if (row.dateTime === null) {
                    } else {
                        return new Date(row.dateTime)
                    }
                },
                id: 'dateTime',
                header: 'Приход',
                sortingFn: 'dateTime',
                size: 10,
                Cell: ({cell, row}) => (
                    cell.getValue() !== undefined ? (
                        <li key={cell.getValue()}>
                            <Link to={'/organization/materials-price-unit-arrival-item/' + data[row.index].materialPriceUnit.id + '/'
                                + (typeMaterial === 'SLAB') + '/' + baseUnit}>
                                {new Date(cell.getValue()).toLocaleDateString()}
                            </Link>
                        </li>
                    ) : ""
                ),
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'quantity',
                header: `Кол. ${baseUnit}`,
                accessorKey: 'quantity',
                size: 3,
                maxSize: 3,
            },
            {
                id: 'totalIssued',
                header: `Выдано`,
                accessorKey: 'totalIssued',
                size: 3,
                maxSize: 3,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumTotalIssued?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </Stack>
                ),
            },

            {
                id: 'inReserve',
                header: `В резерве`,
                accessorKey: 'inReserve',
                size: 3,
                maxSize: 3,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumInReserve?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'freeQuantity',
                header: `Свободно`,
                accessorKey: 'freeQuantity',
                size: 3,
                maxSize: 3,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumFreeQuantity?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'arrivalAmountBasePrice',
                header: `Сумма прихода в ${baseCurrency}`,
                accessorKey: 'arrivalAmountBasePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumArrivalAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'arrivalAmountAlternativePrice',
                header: `Сумма прихода в ${alternativeCurrency}`,
                accessorKey: 'arrivalAmountAlternativePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumArrivalAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'amountBasePrice',
                header: `Сумма в ${baseCurrency}`,
                accessorKey: 'amountBasePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'amountAlternativePrice',
                header: `Сумма в ${alternativeCurrency}`,
                accessorKey: 'amountAlternativePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'PriceInCurrencyArrival',
                header: 'Цена в валюте прихода',
                accessorKey: 'materialPriceUnit.materialPriceInCurrencyArrival.price',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Cell: ({cell, row}) => <PriceWithCurrency cell={cell} row={row}/>,
            },
            {
                id: 'PriceInCurrencyBase',
                header: `Цена в ${baseCurrency}`,
                accessorKey: 'materialPriceUnit.materialPriceInCurrencyBase.price',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'PriceInCurrencyAlternative',
                header: `Цена в ${alternativeCurrency}`,
                accessorKey: 'materialPriceUnit.materialPriceInCurrencyAlternative.price',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'coefficientCurrencyBasePrice',
                header: `Кф. курса б.цены`,
                accessorKey: 'materialPriceUnit.coefficientCurrencyBasePrice',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'basePrice',
                header: 'Баз. цена',
                accessorKey: 'basePrice.price',
                size: 8,
                Cell: ({row}) => {
                    const basePrice = row.original.materialPriceUnit.materialPriceInRealBasePrice || null;
                    return basePrice === null ? '' : (<CellCheckboxAMPUTFixPrice
                        price={basePrice}
                        fixPrice={row.original.materialPriceUnit.materialPriceInRealBasePrice.fixPrice}
                    />);
                }, minSize: 8, maxSize: 8,
            },
            {
                id: 'totalWrittenOff',
                header: 'Списано',
                accessorKey: 'totalWrittenOff',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                id: 'warehouse.name',
                header: 'Склад',
                accessorKey: 'warehouse.name',
                size: 8,
                maxSize: 8,
                enableEditing: false,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                enableHiding: false,
            },
        ];

        if (typeMaterial === 'SLAB') {
            baseColumns.splice(0, 0, {
                id: 'length',
                header: 'Ширина',
                accessorKey: 'materialPriceUnit.length',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (typeMaterial === 'SLAB') {
            baseColumns.splice(1, 0, {
                id: 'width',
                header: 'Высота',
                accessorKey: 'materialPriceUnit.width',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (typeMaterial === 'SLAB') {
            baseColumns.splice(2, 0, {
                id: 'cutOff',
                header: 'Обрезок',
                accessorKey: 'materialPriceUnit.cutOff',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            });
        }

        return baseColumns;

    }, [data, setData, baseCurrency, alternativeCurrency, typeMaterial, baseUnit]);

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    }
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
            />
        </>
    );
};

export default WarehouseMaterialItemTable;