import React, { useState} from "react";
import * as THREE from "three";
import Layer from "./Layer";
import {COLORS} from "../../../../../../Constants";
import LineElement from "./LineElement";
import Cut from "./Cut";

const Border = ({
                    start, next, prev, editableElement, processingHandle, cutData, scale, position, prevArc
                }) => {
    const [hover, setHover] = useState(null)
    const active = (editableElement.type === "arc" || editableElement.type === "addEdge") && editableElement.id === start.id
    const preview = (editableElement.type === "arc" || editableElement.type === "round") && editableElement.id === start.id && prevArc

    const calcArcPoints = (arcParams) => {
        return new THREE.EllipseCurve(arcParams?.x, arcParams?.y, arcParams?.radius, arcParams?.radius, arcParams?.startAngle, arcParams?.endAngle, arcParams?.clockwise)
            .getPoints(100)
            .map((p) => new THREE.Vector2(p.x, p.y))
    }

    const points = start.arcParams ? calcArcPoints(start?.arcParams) : [new THREE.Vector2(start.x, start.y), new THREE.Vector2(next.x, next.y)]

    const prevLine = preview && calcArcPoints(prevArc)

    return (
        <Layer layer={2}>
            <mesh
                scale={scale}
                position={[position[0], position[1], position[2]]}
                onClick={(event) => {
                    event.stopPropagation()
                    processingHandle("addEdge", start.id)
                }}
                onPointerOver={(event) => setHover(true)}
                onPointerOut={(event) => setHover(false)}>
                {editableElement.type === "cut" && cutData.pointId === start.id &&
                    <Cut scale={scale} data={{prev, start, next}} cutData={cutData}/>
                }
                <LineElement width={4} points={points} color={active || hover ? COLORS[5][600] : "black"}/>
                {preview && <LineElement
                    width={4}
                    points={prevLine}
                    color={COLORS[6][800]}
                    dashSize={0.08 / scale} gapSize={0.08 / scale}/>}
            </mesh>
        </Layer>
    )
}
export default Border
