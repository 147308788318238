import React, {useState} from "react";
import Hole from "./Hole";
import {COLORS} from "../../../../../../Constants";
import LineElement from "./LineElement";
import {calcSidePos} from "../CanvasCalcPositions/calcSidePos";
import {calcSideHolePos} from "../CanvasCalcPositions/calcSideHolePos";
import Layer from "./Layer";

const EndFace = ({
                     side,
                     holes,
                     detailData,
                     selectedHole,
                     hoveredHole,
                     setSelectedHole,
                     setHoveredHole,
                     processingHandle,
                     ...props
                 }) => {
    const [hovered, hover] = useState(false)

    const {xPos, yPos, x, y} = calcSidePos(side, detailData, props.scale)

    const border = [
        [[0, 0], [0, y]],
        [[0, y], [x, y]],
        [[x, y], [x, 0]],
        [[x, 0], [0, 0]],
    ]

    return (
        <Layer layer={0}>
            <mesh
                position={[xPos + props.position[0], yPos + props.position[1], 0]}
                onClick={(event) => {}}
                onPointerOver={(event) => (event.stopPropagation(), hover(true))}
                onPointerOut={(event) => hover(false)}>
                <boxGeometry args={[x, y, 0]}/>
                <meshBasicMaterial color={hovered ? 'grey' : "lightgrey"}/>

                {holes?.map((el, i) => {
                    const {
                        scaleRadius,
                        scaleX,
                        scaleY
                    } = calcSideHolePos(el, side, detailData, props.scale, xPos, yPos, x, y)

                    return <Hole key={el.id}
                                 data={{
                                     id: el.id,
                                     x: scaleX,
                                     y: scaleY,
                                     z: 0,
                                     radius: scaleRadius,
                                     color: COLORS[2][600]
                                 }}
                                 processingHandle={processingHandle}
                                 selectedHole={selectedHole}
                                 setSelectedHole={setSelectedHole}
                                 setHoveredHole={setHoveredHole}/>
                })}
                {border.map((el, i) => {
                    return <LineElement key={i} position={[x / 2 - x, y / 2 - y, 0]} points={[el[0], el[1]]}
                                        color={"black"}/>
                })}

            </mesh>
        </Layer>)
}
export default EndFace
