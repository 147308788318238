import React, {useState} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function RowRadioButtonsGroup({selectedValue, setSelectedValue}) {

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedValue}
                onChange={handleRadioChange}
            >
                <FormControlLabel value="material" control={<Radio/>} label="Материалы организации"/>
                <FormControlLabel value="allMaterial" control={<Radio/>} label="Материалы общей базы"/>
                <FormControlLabel value="all" control={<Radio/>} label="все"/>
            </RadioGroup>
        </FormControl>
    );
}