import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import MaterialPriceUnitService from "../../../../../../API/orders/warehouse/MaterialPriceUnitService";
import MaterialPageItemModule from "../../../../modul/MaterialPageItemModule";
import ModalUpdateOrAddMaterialPriceUnit from "./ModalUpdateOrAddMaterialPriceUnit";
import MaterialPriceUnitUtil from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import UserSupplierMaterialService from "../../../../../../API/orders/warehouse/UserSupplierMaterialService";
import CurrencyNationalBank from "../../../../../../API/orders/warehouse/currency/CurrencyNationalBankService";
import ChoiceSlabMaterialModal from "./slab/ChoiceSlabMaterialModal";
import ChoiceFixedStripeModal from "./slab/ChoiceFixedStripeModal";
import SupplierMaterialUtil from "../../../../../../utils/warehouse/SupplierMaterialUtil";
import MaterialOrganizationOptionService
    from "../../../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";

const UpdateOrAddMaterialPriceUnitModule = ({
                                                data,
                                                setData,
                                                open,
                                                onClose,
                                                currentCurrency,
                                                baseCurrency,
                                                alternativeCurrency,
                                                isBaseCurrency,
                                                isAlternativeCurrency,
                                                supplierId,
                                                updateModalOpen,
                                                setUpdateModalOpen,
                                                onCloseModalOpen,
                                                currentEditRowData,
                                                setCurrentEditRowData,
                                                currentEditRowId,
                                                materialPriceUnits,
                                                arrivalMaterialId,
                                            }) => {


        const [addModalOpen, setAddModalOpen] = useState(false);

        const [values, setValues] = useState(null);
        const [basePriceCurrency, setBasePriceCurrency] = useState(null);
        const [isSlabMaterial, setIsSlabMaterial] = useState(null);
        const [choiceSlabMaterialModalOpen, setChoiceSlabMaterialModalOpen] = useState(false);
        const [currentRow, setCurrentRow] = useState(null);

        const [typeSlabMaterial, setTypeSlabMaterial] = useState(null);
        const [materialFixedStripeId, setMaterialFixedStripeId] = useState(null);
        const [materialFixedStripes, setMaterialFixedStripes] = useState(null);

        const [listChoiceSlabMaterial, setListChoiceSlabMaterial] = useState(null);
        const [choiceFixedStripeModalOpen, setChoiceFixedStripeModalOpen] = useState(false);
        const [currentBasePrice, setCurrentBasePrice] = useState(null);
        const [isFixedStripe, setIsFixedStripe] = useState(null);

        const handleSubmitAdd = () => {
            MaterialPriceUnitUtil.setExchangeRateBasePriceCurrency(baseCurrency, alternativeCurrency, basePriceCurrency, values)
            MaterialPriceUnitService.create(currentCurrency, supplierId, arrivalMaterialId, values,
            ).then(function (response) {
                handleCreateNewRow(response.data);
                onCloseAdd();
                setValues(null);
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
            MaterialPriceUnitUtil.addOrUpdateMaterialPriceUnits(materialPriceUnits, values);
        };

        const handleSubmitUpdate = () => {
            MaterialPriceUnitUtil.setExchangeRateBasePriceCurrency(baseCurrency, alternativeCurrency, currentEditRowData.supplierMaterial.basePrice.currency.curAbbreviation, currentEditRowData)
            MaterialPriceUnitService.update(currentCurrency, supplierId, currentEditRowData,
            ).then(function (response) {
                handleUpdate(response.data);
                onCloseModalOpen();
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleUpdate = (values) => {
            data[currentEditRowId] = values;
            setData([...data]);
            MaterialPriceUnitUtil.addOrUpdateMaterialPriceUnits(materialPriceUnits, values);
        };

        const addMaterialInArrival = async (row) => {

            let idMaterial = row.original.id;
            const isSlab = row.original.typeMaterial === 'SLAB';

            setCurrentBasePrice(null);
            setIsSlabMaterial(isSlab);
            setValues(null);
            if (isSlab) {
                setCurrentRow(row);
                await MaterialOrganizationOptionService.getChoiceSlabMaterial(idMaterial, supplierId)
                    .then(function (response) {
                        const startChoiceSlabMaterial = [];
                        startChoiceSlabMaterial.push('WHOLE')
                        const filteredChoiceSlabMaterialServer = response.data.filter(function (value) {
                            return value !== null;
                        });
                        const combinedChoiceSlabMaterial = startChoiceSlabMaterial.concat(filteredChoiceSlabMaterialServer);
                        setListChoiceSlabMaterial(combinedChoiceSlabMaterial);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                setTypeSlabMaterial(null);
                setChoiceSlabMaterialModalOpen(true);
            } else {
                if (materialPriceUnits.has(idMaterial)) {
                    setUpdateModalOpen(true);
                    setCurrentEditRowData(materialPriceUnits.get(idMaterial));
                } else {
                    await createMaterialPriceUnit(idMaterial, row, false);
                    setAddModalOpen(true);
                }
            }
        }

        const uploadMaterialFixedStripes = async (typeSlabMaterial, idMaterial) => {
            if (typeSlabMaterial === "BY_FIXED_STRIPE")
                await UserSupplierMaterialService.getAllCurrentByMaterialOrganizationOptionIdAndSupplierIdFixedStripe(idMaterial, supplierId)
                    .then(function (response) {
                        setMaterialFixedStripes(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
        }

        const createMaterialPriceUnit = async (idMaterial, row, isByMinStripe) => {

            if (typeSlabMaterial === null && isSlabMaterial) {
                setTypeSlabMaterial("WHOLE");
            }

            let supplierMaterial = null;

            if (isFixedStripe) {
                if (materialFixedStripeId === null) {
                    supplierMaterial = materialFixedStripes[0];
                } else {
                    supplierMaterial = SupplierMaterialUtil.getSupplierMaterialFixedStripe(materialFixedStripeId, materialFixedStripes);
                }
            } else if (isByMinStripe) {
                await UserSupplierMaterialService.getCurrentByMaterialOrganizationOptionIdAndSupplierIdMinStripe(idMaterial, supplierId)
                    .then(function (response) {
                        supplierMaterial = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                await UserSupplierMaterialService.getCurrentByMaterialOrganizationOptionIdAndSupplierId(idMaterial, supplierId)
                    .then(function (response) {
                        supplierMaterial = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }


            let basePrice = supplierMaterial.basePrice;
            setCurrentBasePrice(basePrice)

            let currencyNationalBankBasePrice;

            const BYN = {
                curOfficialRate: 1,
                curScale: 1,
            }

            if (basePrice !== null && basePrice !== undefined) {

                let curAbbreviationBasePrice = basePrice.currency.curAbbreviation;

                setBasePriceCurrency(curAbbreviationBasePrice);

                if (curAbbreviationBasePrice === 'BYN') {
                    currencyNationalBankBasePrice = BYN;
                } else {
                    await CurrencyNationalBank.getByCurAbbreviation(curAbbreviationBasePrice)
                        .then(function (response) {
                            currencyNationalBankBasePrice = response.data;
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            }

            let currencyNationalBankArrivalPrice;

            if (currentCurrency === 'BYN') {
                currencyNationalBankArrivalPrice = BYN;
            } else {
                await CurrencyNationalBank.getByCurAbbreviation(currentCurrency)
                    .then(function (response) {
                        currencyNationalBankArrivalPrice = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

            let currencyNationalBankBaseMaterialPrice;

            if (baseCurrency === 'BYN') {
                currencyNationalBankBaseMaterialPrice = BYN;
            } else {
                await CurrencyNationalBank.getByCurAbbreviation(baseCurrency)
                    .then(function (response) {
                        currencyNationalBankBaseMaterialPrice = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

            let currencyNationalAlternativeBaseMaterialPrice;

            if (alternativeCurrency === 'BYN') {
                currencyNationalAlternativeBaseMaterialPrice = BYN;
            } else {
                await CurrencyNationalBank.getByCurAbbreviation(alternativeCurrency)
                    .then(function (response) {
                        currencyNationalAlternativeBaseMaterialPrice = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

            let length;
            let width;

            if (isFixedStripe) {
                if (supplierMaterial !== null) {
                    length = supplierMaterial.length;
                    width = supplierMaterial.width;
                }
            } else {
                length = row.original.length;
                width = row.original.width;
            }

            let quantity = 0;
            let amountInCurrencyArrival = 0;

            const officialExchangeRateToBaseCurrencyBaseMaterials = MaterialPriceUnitUtil.setExchangeRate
            (currencyNationalBankBaseMaterialPrice, currencyNationalBankArrivalPrice);
            const officialExchangeRateToAlternativeCurrencyBaseMaterials = MaterialPriceUnitUtil.setExchangeRate
            (currencyNationalAlternativeBaseMaterialPrice, currencyNationalBankArrivalPrice);
            const officialExchangeRateToBasePrice = basePrice === null ? null : MaterialPriceUnitUtil.setExchangeRate
            (currencyNationalBankBasePrice, currencyNationalBankArrivalPrice);

            if (isSlabMaterial) {
                quantity = length * width / 1000000
                amountInCurrencyArrival = (basePrice.price / officialExchangeRateToBasePrice * quantity).toFixed(2);
            }

            const materialPriceUnit = {
                supplierMaterial: supplierMaterial,
                amountInCurrencyArrival: amountInCurrencyArrival,
                officialExchangeRateToBaseCurrencyBaseMaterials: officialExchangeRateToBaseCurrencyBaseMaterials,
                exchangeRateToBaseCurrencyBaseMaterials: officialExchangeRateToBaseCurrencyBaseMaterials,
                officialExchangeRateToAlternativeCurrencyBaseMaterials: officialExchangeRateToAlternativeCurrencyBaseMaterials,
                exchangeRateToAlternativeCurrencyBaseMaterials: officialExchangeRateToAlternativeCurrencyBaseMaterials,
                officialExchangeRateToBasePrice: officialExchangeRateToBasePrice,
                exchangeRateToBasePrice: officialExchangeRateToBasePrice,
                length: length,
                width: width,
                quantity: quantity,
                materialOrganizationOption: {
                    id: row.original.id,
                    name: row.original.name,
                    unit: {
                        name: row.original.unit.name
                    }
                }
            };
            setValues(materialPriceUnit)
        };

        const onCloseAdd = () => {
            setAddModalOpen(false)
        };

        const handleSubmitChoiceSlabMaterial = async () => {
            const currentTypeSlabMaterial = typeSlabMaterial === null ? "WHOLE" : typeSlabMaterial;
            const idMaterial = currentRow.original.id
            const isByFixedStripe = currentTypeSlabMaterial === "BY_FIXED_STRIPE";
            const isByMinStripe = currentTypeSlabMaterial === "BY_MIN_STRIPE";
            setIsFixedStripe(isByFixedStripe)

            if (!isByFixedStripe && !isByMinStripe) {
                const materialPriceUnit = MaterialPriceUnitUtil.checkAvailability(materialPriceUnits, idMaterial);
                if (materialPriceUnit === null) {
                    await createMaterialPriceUnit(idMaterial, currentRow, isByMinStripe);
                    setAddModalOpen(true);
                } else {
                    setUpdateModalOpen(true);
                    setCurrentEditRowData(materialPriceUnit);
                }

            } else {
                if (isByFixedStripe) {
                    await uploadMaterialFixedStripes(currentTypeSlabMaterial, idMaterial);
                    setChoiceFixedStripeModalOpen(true)
                } else {
                    await createMaterialPriceUnit(idMaterial, currentRow, isByMinStripe);
                    setChoiceSlabMaterialModalOpen(false);
                    setAddModalOpen(true);
                }
            }
            onCloseChoiceSlabMaterialModalOpen();
        }

        const handleSubmitChoiceFixedStripe = async () => {
            await createMaterialPriceUnit(currentRow.original.id, currentRow, false);
            setChoiceFixedStripeModalOpen(false)
            setAddModalOpen(true);
        };

        const onCloseChoiceSlabMaterialModalOpen = () => {
            setChoiceSlabMaterialModalOpen(false);
        };

        const onCloseChoiceFixedStripeModalOpen = () => {
            setChoiceFixedStripeModalOpen(false);
        };

        return (
            <div>
                <ModalUpdateOrAddMaterialPriceUnit
                    open={updateModalOpen}
                    onClose={onCloseModalOpen}
                    title={'Редактировать приход материала'}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    currentCurrency={currentCurrency}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    basePriceCurrency={currentBasePrice === null ? null : currentBasePrice.currency.curAbbreviation}
                    isBaseCurrency={isBaseCurrency}
                    isAlternativeCurrency={isAlternativeCurrency}
                    labelButtonSSubmit={'Сохранить'}
                    handleSubmit={handleSubmitUpdate}
                    typeSlabMaterial={typeSlabMaterial}
                    isSlabMaterial={isSlabMaterial}
                    isNew={false}
                />
                <ChoiceSlabMaterialModal
                    open={choiceSlabMaterialModalOpen}
                    onClose={onCloseChoiceSlabMaterialModalOpen}
                    value={typeSlabMaterial}
                    setValue={setTypeSlabMaterial}
                    handleSubmit={handleSubmitChoiceSlabMaterial}
                    keyFilter={listChoiceSlabMaterial}
                />
                <ChoiceFixedStripeModal
                    open={choiceFixedStripeModalOpen}
                    onClose={onCloseChoiceFixedStripeModalOpen}
                    value={materialFixedStripeId}
                    setValue={setMaterialFixedStripeId}
                    handleSubmit={handleSubmitChoiceFixedStripe}
                    entityList={materialFixedStripes}
                />

                <Dialog open={open} fullWidth={true} maxWidth='lg'>
                    <DialogTitle textAlign="center">Добавить поступление материала</DialogTitle>
                    <DialogContent>
                        <MaterialPageItemModule
                            refMaterial={false}
                            pageSize={20}
                            addMaterialInArrival={addMaterialInArrival}
                            enableEditing={true}
                            isAddMaterial={false}
                            nameMethodGetMaterial={'with-group-material-unit-supplierMaterials'}
                            supplierId={supplierId}
                            isSelectInArrival={true}
                            isSelectInEstimate={false}

                        />
                        <ModalUpdateOrAddMaterialPriceUnit
                            open={addModalOpen}
                            onClose={onCloseAdd}
                            title={'Добавить приход материала'}
                            values={values}
                            setValues={setValues}
                            currentCurrency={currentCurrency}
                            baseCurrency={baseCurrency}
                            alternativeCurrency={alternativeCurrency}
                            basePriceCurrency={currentBasePrice === null ? null : currentBasePrice.currency.curAbbreviation}
                            isBaseCurrency={isBaseCurrency}
                            isAlternativeCurrency={isAlternativeCurrency}
                            supplierId={supplierId}
                            labelButtonSSubmit={'Добавить'}
                            handleSubmit={handleSubmitAdd}
                            typeSlabMaterial={typeSlabMaterial}
                            isSlabMaterial={isSlabMaterial}
                            isNew={true}
                        />
                    </DialogContent>
                    <DialogActions sx={{p: '1.25rem'}}>
                        <Button onClick={onClose}>Выйти</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
;

export default UpdateOrAddMaterialPriceUnitModule;