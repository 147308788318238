import React, {useMemo} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {Cached, PlaylistAdd} from "@mui/icons-material";
import CellShortenedName from "../../../orders/organization/estimate/CellShortenedName";
import ModalAddSignJob from "./ModalAddSignJob";

const MaterialTable = ({
                           currentData,
                           setCurrentData,
                           refMaterial,
                           pageSize,
                           addMaterialInArrival,
                           addMaterial,
                           enableEditing,
                           isAddMaterial,
                           isSelectInArrival,
                           isSelectInEstimate,
                           selectInEstimate,
                           openModalAddSignJob,
                           setOpenModalAddSignJob,
                           handleCloseModalAddSignJob,
                           handleSubmitAddSignJobInMaterial,
                           groupNames,
                           rowSelection,
                           setRowSelection,
                           rowIndexSelection,
                           setRowIndexSelection,
                           selectedIds,
                           setSelectedIds,
                           disabledAddMaterial,
                           dataSignJob,
                           setDataSignJob,
                           change,
                           setChange
                       }) => {

    const handleOpenModalAddSignJob = () => {
        setOpenModalAddSignJob(true)
    };

    const columns = useMemo(
        () => [
            {
                header: 'Артикул',
                accessorKey: 'articleNumber',
                minSize: 50,
                maxSize: 75,
                Cell: ({cell}) => <CellShortenedName
                    value={cell.getValue()}
                    numberOfCharacters={15}
                />,
            },
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 450,
                Cell: ({cell, row}) => (refMaterial ?
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/materials/' + currentData[row.index].id + '/' + currentData[row.index].unit.name + '/' + currentData[row.index].typeMaterial + '/' + currentData[row.index].materialId}>
                                {cell.getValue()}</Link>
                        </li> : cell.getValue()
                ),
            },
            {
                header: 'Ед. изм.',
                accessorKey: 'unit.name',
                minSize: 15,
            },
            {
                header: 'Группа',
                accessorKey: 'groupMaterial.name',
                minSize: 150,
            },
            {
                header: 'Производитель',
                accessorKey: 'manufacturer.name',
                minSize: 150,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [currentData, setCurrentData, refMaterial],
    );

    return (<div>
        <MaterialReactTable
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: 'lightgray',
                    },
                }),
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                },
            }}
            muiTableBodyRowProps={{
                sx: {
                    height: '10px',
                },
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 120,
                },
            }}
            columns={columns}
            data={currentData}
            initialState={{
                columnVisibility: {
                    id: false,
                },
                isFullScreen: false,
                density: "compact",
                pagination: {pageIndex: 0, pageSize: pageSize},
            }}
            localization={MRT_Localization_RU}
            enableEditing={enableEditing}
            renderRowActions={({row}) => (
                <Box sx={{
                    display: 'flex',
                    gap: '0.1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '50px'
                }}>
                    {isSelectInArrival && <Tooltip arrow placement="left" title="Добавить">
                        <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                onClick={() => addMaterialInArrival(row)}>
                            <PlaylistAdd sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>
                    </Tooltip>}
                    {isSelectInEstimate && <Tooltip arrow placement="left" title="Заменить">
                        <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                onClick={() => selectInEstimate(row)}>
                            <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>
                    </Tooltip>}
                </Box>
            )}
            renderTopToolbarCustomActions={() => (
                <Box
                    sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                >
                    {isAddMaterial && <Button
                        size="small"
                        color="secondary"
                        onClick={addMaterial}
                        variant="contained"
                    >
                        Добавить материал
                    </Button>}
                    {isAddMaterial && <Button
                        size="small"
                        color="secondary"
                        onClick={handleOpenModalAddSignJob}
                        variant="contained"
                        disabled={disabledAddMaterial}
                    >
                        Добавить группе работы
                    </Button>}
                </Box>
            )}
        />
        <ModalAddSignJob
            title={"Добавить работы на группу " + groupNames}
            open={openModalAddSignJob}
            handleClose={handleCloseModalAddSignJob}
            handleSubmit={handleSubmitAddSignJobInMaterial}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            rowIndexSelection={rowIndexSelection}
            setRowIndexSelection={setRowIndexSelection}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            data={dataSignJob}
            setData={setDataSignJob}
            change={change}
            setChange={setChange}
        />
    </div>);
};

export default MaterialTable;