import React from "react";
import Layer from "./Layer";

const Hole = ({data, setSelectedHole, setHoveredHole, processingHandle}) => {
    const onclickHoleHandle = () => {
        processingHandle("editHole", data.id)
        setSelectedHole(data.id)
    }
    return (
        <Layer layer={2}>
            <mesh
                onClick={(event) => (event.stopPropagation(), onclickHoleHandle())}
                onPointerOver={(event) => (event.stopPropagation(), setHoveredHole(`${data.id}${data.name}`))}
                onPointerOut={(event) => (event.stopPropagation(), setHoveredHole(null))}
                position={[data.x, data.y, data.z]}>
                <circleGeometry args={[data.radius]}/>
                <meshBasicMaterial
                    toneMapped={false}
                    color={data.color}/>
                {data.through && <mesh>
                    <circleGeometry args={[data.radius - 2]}/>
                    <meshBasicMaterial toneMapped={false}/>
                </mesh>}
            </mesh>
        </Layer>
    )
}
export default Hole
