import React, {useState} from 'react';
import {Button, Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import PanoramaWideAngleIcon from '@mui/icons-material/PanoramaWideAngle';
import {calcDistance} from "../../../../../../utils/processing/calcDistance";
import {createPointArray} from "../../../../../../utils/processing/createPointArray";

const direction = [
    {value: true, label: "по часовой стрелке"},
    {
        value: false, label: "против часовой стрелки"
    }]

const ArcModalContent = ({
                             type,
                             onClose,
                             onSubmit,
                             detailData,
                             pointId,
                             setEditableElementId,
                             getPrevContour
                         }) => {
    const pointArray = createPointArray(detailData)
    const currEl = pointId ? pointArray.find(el => el.id === pointId) : pointArray[0]

    const initFormState = {
        "radius": currEl?.arcParams ? currEl?.arcParams?.radius : "",
        "clockwise": currEl?.arcParams ? currEl?.arcParams?.clockwise : true,
        "pointId": currEl.id
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "radius": "", "clockwise": "", "pointId": "",
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}

        const pointCoordinate = pointArray.find((el) => el.id === formState.pointId)
        const nextPointCoordinate = pointArray.find((el, i, arr) => arr[i - 1]?.id === formState.pointId||arr[0])
        const d = calcDistance(pointCoordinate, nextPointCoordinate)
        const rMin = Math.floor(d / 2)

        Object.entries(formState).forEach(([name, value]) => {
            if (type === "arc" && name == "radius" && value < rMin) {
                newErrors.radius = `от ${rMin}`
            }
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            console.log(formState.pointId,formState.radius.toString(),formState.clockwise)
            onSubmit({
                pointId: formState.pointId,
                point: {
                    "radius": formState.radius.toString(),
                    "clockwise": formState.clockwise
                }
            })
        }
    }

    const inputHandler = (getData) => {
        const data = getData()
        const name = Object.keys(data)[0]
        const newState = {...formState}

        if (name === "pointId") {
            const currPoint = pointArray.find(el => el.id === data.pointId)

            newState.pointId = data.pointId
            newState.radius = currPoint.arcParams?.radius || ""
            newState.clockwise = currPoint.arcParams ? currPoint.arcParams?.clockwise : true
            setEditableElementId(data.pointId)
        } else if (name === "radius") {
            newState.radius = data.radius

        } else {
            newState.clockwise = data.clockwise
        }

        setFormState(newState)

        getPrevContour({
            pointId: newState.pointId, params: {
                "radius": newState.radius,
                "clockwise": newState.clockwise
            }
        })
    }

    return (<>
        <DialogTitle textAlign="center">{type === "round" ? "Скруглить угол" : "Дуга"}</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                {!pointId && <TextFieldValidation
                    select
                    name="pointId"
                    label="Узел"
                    value={formState.pointId}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {pointArray.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            x= {+option.x.toFixed(2)}, y= {+option.y.toFixed(2)}
                        </MenuItem>))}
                </TextFieldValidation>}
                <TextFieldValidation
                    name={"radius"}
                    value={formState?.radius}
                    label="Радиус"
                    setCurrValues={inputHandler}
                    validationError={validationState?.radius}
                    setValidationState={setValidationState}
                    required={false}
                />
                <TextFieldValidation
                    select
                    required={false}
                    name={"clockwise"}
                    value={formState?.clockwise}
                    label="Hаправление"
                    setCurrValues={inputHandler}
                    validationError={validationState?.clockwise}
                    setValidationState={setValidationState}
                > <MenuItem value={"true"}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <p style={{display: "inline-block", width: 200, margin: 0}}>Выпуклость</p>
                        <PanoramaWideAngleIcon sx={{fontSize: 23}}/>
                    </div>
                </MenuItem>
                    <MenuItem value={"false"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <p style={{display: "inline-block", width: 200, margin: 0}}>Вогнутость</p>
                            <PanoramaHorizontalIcon sx={{fontSize: 23}}/>
                        </div>
                    </MenuItem>
                </TextFieldValidation>
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button size="small" onClick={onClose}> отменить </Button>
            <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                выбрать
            </Button>
        </DialogActions>
    </>)
}
export default ArcModalContent
