import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import {Container} from "@mui/material";
import MainMenu from "./MainMenu";
import UserService from "../../../API/common/UserService";
import {authorization, logout} from "../../../Constants";

let authStorage = localStorage.getItem("auth");
let calculationStorage = localStorage.getItem("calculation");
let customerStorage = localStorage.getItem("customer");
let ordersStorage = localStorage.getItem("orders");
let optimizationStorage = localStorage.getItem("optimization");
let storageStorage = localStorage.getItem("storage");
let cuttingStorage = localStorage.getItem("cutting");
let productionStorage = localStorage.getItem("production");
let economicStorage = localStorage.getItem("economic");
let materialCloudStorage = localStorage.getItem("material-cloud");

const MenuAppBar = () => {

        const [auth, setAuth] = useState(authStorage === "true");
        const [anchorElRight, setAnchorElRight] = useState(null);
        const [anchorElOrder, setAnchorElOrder] = useState(null);
        const [anchorElStorage, setAnchorElStorage] = useState(null);
        const [anchorElOptimization, setAnchorElOptimization] = useState(null);
        const [anchorElCutting, setAnchorElCutting] = useState(null);
        const [anchorElCalculation, setAnchorElCalculation] = useState(null);
        const [anchorElCustomer, setAnchorElCustomer] = useState(null);
        const [anchorElProduction, setAnchorElProduction] = useState(null);
        const [anchorElEconomy, setAnchorElEconomy] = useState(null);
        const [anchorElCloud, setAnchorElCloud] = useState(null);

        const [calculation, setCalculation] = useState(calculationStorage === "true");
        const [calculationRequestsCutting, setCalculationRequestsCutting] = useState(false);
        const [ordersCloud, setOrdersCloud] = useState(false);
        const [calculationOptimizations, setCalculationOptimizations] = useState(false);
        const [calculationWorkingCuttingMaps, setCalculationWorkingCuttingMaps] = useState(false);

        const [customer, setCustomer] = useState(customerStorage === "true");
        const [customerOrders, setCustomerOrders] = useState(false);

        const [orders, setOrders] = useState(ordersStorage === "true");
        const [ordersConstructors, setOrdersConstructors] = useState(false);
        const [ordersOrdersSettings, setOrdersSettings] = useState(false);
        const [ordersOrdersArchive, setOrdersArchive] = useState(false);
        const [ordersOrdersToArchive, setOrdersToArchive] = useState(false);

        const [optimization, setOptimization] = useState(optimizationStorage === "true");
        const [optimizationRequestsCutting, setOptimizationRequestsCutting] = useState(false);
        const [optimizationRequestsCustomizedMaterials, setOptimizationRequestsCustomizedMaterials] = useState(false);
        const [optimizationCustomizedMaterialsDetails, setOptimizationCustomizedMaterialsDetails] = useState(false);
        const [optimizationPlan, setOptimizationPlan] = useState(false);
        const [optimizationOptimization, setOptimizationOptimization] = useState(false);

        const [storage, setStorage] = useState(storageStorage === "true");
        const [storageMaterials, setStorageMaterials] = useState(false);
        const [storageRequestsPurchase, setStorageRequestsPurchase] = useState(false);
        const [storageReservationPieceMaterial, setStorageReservationPieceMaterial] = useState(false);
        const [storageReservationSlabMaterial, setStorageReservationSlabMaterial] = useState(false);
        const [storageStorageReservationLinearMaterial, setStorageStorageReservationLinearMaterial] = useState(false);
        const [storageStorageMaterialsArrival, setStorageStorageMaterialsArrival] = useState(false);
        const [storageStorageCurrentCurrencies, setStorageStorageCurrentCurrencies] = useState(false);
        const [storageStorageSettingsStorage, setStorageSettingsStorage] = useState(false);

        const [cutting, setCutting] = useState(cuttingStorage === "true");
        const [cuttingWorkingCuttingMaps, setCuttingWorkingCuttingMaps] = useState(false);
        const [cuttingQueueCuttingMaps, setCuttingQueueCuttingMaps] = useState(false);
        const [cuttingWarehouseFinishedDetail, setCuttingWarehouseFinishedDetail] = useState(false);

        const [production, setProduction] = useState(productionStorage === "true");
        const [productionProduction, setProductionProduction] = useState(true);
        const [productionIssuingEdges, setProductionIssuingEdges] = useState(true);
        const [productionIssuancePieceMaterials, setProductionIssuancePieceMaterials] = useState(true);

        const [economic, setEconomic] = useState(economicStorage === "true");
        const [economicIssuanceMaterialReserve, setEconomicIssuanceMaterialReserve] = useState(false);
        const [economicInWork, setEconomicInWork] = useState(false);
        const [economicOrders, setEconomicOrders] = useState(false);

        const [materialCloud, setMaterialCloud] = useState(materialCloudStorage === "true");
        const [materialCloudAdminRoles, setMaterialCloudAdminRoles] = useState(false);


        useEffect(() => {
            UserService.authorized().then(function () {
                localStorage.setItem("auth", "true")
                setAuth(true)
            })
                .catch(function (error) {
                    localStorage.setItem("auth", "false")
                    console.log(error);
                });
        }, []);

        useEffect(() => {
            UserService.customer().then(function () {
                localStorage.setItem("customer", "true")
                setCustomer(true)
                setCustomerOrders(true);
            })
                .catch(function (error) {
                    localStorage.setItem("calculation", "false")
                    console.log(error);
                });

            ///
            UserService.calculation().then(function () {
                localStorage.setItem("calculation", "true")
                setCalculation(true);
            })
                .catch(function (error) {
                    localStorage.setItem("calculation", "false")
                    console.log(error);
                });
            UserService.calculationRequestsCutting().then(function () {
                setCalculationRequestsCutting(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            //TODO Сделать запрос на заказы из обалака
            UserService.calculationRequestsCutting().then(function () {
                setOrdersCloud(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.calculationOptimizations().then(function () {
                setCalculationOptimizations(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.calculationWorkingCuttingMaps().then(function () {
                setCalculationWorkingCuttingMaps(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            ///
            UserService.ordersUser().then(function () {
                setOrders(true)
                localStorage.setItem("orders", "true")
            })
                .catch(function (error) {
                    localStorage.setItem("orders", "false")
                    console.log(error);
                });

            UserService.constructorsUser().then(function () {
                setOrdersConstructors(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            UserService.ordersSettings().then(function () {
                setOrdersSettings(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            UserService.ordersArchive().then(function () {
                setOrdersArchive(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            UserService.ordersToArchive().then(function () {
                setOrdersToArchive(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            ///
            UserService.optimization().then(function () {
                setOptimization(true)
                localStorage.setItem("optimization", "true")
            })
                .catch(function (error) {
                    localStorage.setItem("optimization", "false")
                    console.log(error);
                });

            UserService.requestsCutting().then(function () {
                setOptimizationRequestsCutting(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            UserService.requestsCustomizedMaterials().then(function () {
                setOptimizationRequestsCustomizedMaterials(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.customizedMaterialsDetails().then(function () {
                setOptimizationCustomizedMaterialsDetails(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.plan().then(function () {
                setOptimizationPlan(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.optimizationOptimization().then(function () {
                setOptimizationOptimization(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            ///
            UserService.storage().then(function () {
                setStorage(true)
                localStorage.setItem("storage", "true")
            })
                .catch(function (error) {
                    localStorage.setItem("storage", "false")
                    console.log(error);
                });
            UserService.storageMaterials().then(function () {
                setStorageMaterials(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.storageRequestsPurchase().then(function () {
                setStorageRequestsPurchase(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.storageReservationPieceMaterial().then(function () {
                setStorageReservationPieceMaterial(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.storageReservationSlabMaterial().then(function () {
                setStorageReservationSlabMaterial(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.storageReservationLinearMaterial().then(function () {
                setStorageStorageReservationLinearMaterial(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.storageMaterialsArrival().then(function () {
                setStorageStorageMaterialsArrival(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.storageCurrentCurrencies().then(function () {
                setStorageStorageCurrentCurrencies(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.storageSettingsStorage().then(function () {
                setStorageSettingsStorage(true)
            })
                .catch(function (error) {
                    console.log(error);
                });

            ///
            UserService.cutting().then(function () {
                setCutting(true)
                localStorage.setItem("cutting", "true")
            })
                .catch(function (error) {
                    localStorage.setItem("cutting", "false")
                    console.log(error);
                });
            UserService.cuttingWorkingCuttingMaps().then(function () {
                setCuttingWorkingCuttingMaps(true)

            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.cuttingQueueCuttingMaps().then(function () {
                setCuttingQueueCuttingMaps(true)

            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.cuttingWarehouseFinishedDetail().then(function () {
                setCuttingWarehouseFinishedDetail(true)

            })
                .catch(function (error) {
                    console.log(error);
                });

            ///
            UserService.orderProduction().then(function () {
                setProduction(true)
                localStorage.setItem("production", "true")
            })
                .catch(function (error) {
                    localStorage.setItem("production", "false")
                    console.log(error);
                });
            UserService.orderProductionProduction().then(function () {
                setProductionProduction(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.orderProductionIssuingEdges().then(function () {
                setProductionIssuingEdges(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.orderProductionIssuancePieceMaterials().then(function () {
                setProductionIssuancePieceMaterials(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.economic().then(function () {
                setEconomic(true)
                localStorage.setItem("economic", "true")
            })
                .catch(function (error) {
                    localStorage.setItem("economic", "false")
                    console.log(error);
                });
            UserService.economicIssuanceMaterialReserve().then(function () {
                setEconomicIssuanceMaterialReserve(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            //TODO доделать setEconomicOrders
            UserService.economicInWork().then(function () {
                setEconomicInWork(true)
                setEconomicOrders (true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.materialCloud().then(function () {
                setMaterialCloud(true)
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserService.materialCloudAdminRoles().then(function () {

                localStorage.setItem("material-cloud", "true")
                setMaterialCloudAdminRoles(true)
            })
                .catch(function (error) {
                    localStorage.setItem("material-cloud", "false")
                    console.log(error);
                });
        }, []);

        const handleChange = () => {
            if (auth) {
                window.location.href = logout;
                localStorage.setItem("auth", "false")
                localStorage.setItem("calculation", "false")
                localStorage.setItem("customer", "false")
                localStorage.setItem("orders", "false")
                localStorage.setItem("optimization", "false")
                localStorage.setItem("storage", "false")
                localStorage.setItem("cutting", "false")
                localStorage.setItem("production", "false")
                localStorage.setItem("economic", "false")
                localStorage.setItem("material-cloud", "false")
            } else {
                window.location.href = authorization + '/oauth2/authorization/up-me-client-authorization-code'
            }
        }

        const handleMenuRight = (event) => {
            setAnchorElRight(event.currentTarget);
        };

        const handleMenuOrder = (event) => {
            setAnchorElOrder(event.currentTarget);
        };

        const handleMenuStorage = (event) => {
            setAnchorElStorage(event.currentTarget);
        };

        const handleMenuProduction = (event) => {
            setAnchorElProduction(event.currentTarget);
        };

        const handleMenuOptimization = (event) => {
            setAnchorElOptimization(event.currentTarget);
        };

        const handleMenuCalculation = (event) => {
            setAnchorElCalculation(event.currentTarget);
        };

        const handleMenuCustomer = (event) => {
            setAnchorElCustomer(event.currentTarget);
        };

        const handleMenuCutting = (event) => {
            setAnchorElCutting(event.currentTarget);
        };

        const handleMenuEconomy = (event) => {
            setAnchorElEconomy(event.currentTarget);
        };

        const handleMenuCloud = (event) => {
            setAnchorElCloud(event.currentTarget);
        };

        const handleCloseRight = () => {
            setAnchorElRight(null);
        };

        const handleCloseOrder = () => {
            setAnchorElOrder(null);
        };

        const handleCloseStorage = () => {
            setAnchorElStorage(null);
        };

        const handleCloseProduction = () => {
            setAnchorElProduction(null);
        };

        const handleCloseOptimization = () => {
            setAnchorElOptimization(null);
        };

        const handleCloseCutting = () => {
            setAnchorElCutting(null);
        };

        const handleCloseCalculation = () => {
            setAnchorElCalculation(null);
        };

        const handleCloseCustomer = () => {
            setAnchorElCustomer(null);
        };

        const handleCloseEconomy = () => {
            setAnchorElEconomy(null);
        };

        const handleCloseCloud = () => {
            setAnchorElCloud(null);
        };

        const redirectProduction = () => {
            window.location.href = '/organization/orders-production';
        };

        const redirectOrders = () => {
            window.location.href = '/organization/orders';
        };

        const redirectConstructors = () => {
            window.location.href = '/organization/constructors';
        };

        const redirectTechnicalMaps = () => {
            window.location.href = '/organization/technical-map';
        };

        const redirectSettingsOrders = () => {
            window.location.href = '/organization/settings-orders';
        };

        const redirectArchive = () => {
            window.location.href = '/organization/archive';
        };

        const redirectMaterials = () => {
            window.location.href = '/organization/materials';
        };

        const redirectMaterialsArrival = () => {
            window.location.href = '/organization/materials-arrival';
        };

        const redirectSettingsStorage
            = () => {
            window.location.href = '/organization/settings-storage';
        };

        const redirectCurrentCurrencies
            = () => {
            window.location.href = '/organization/current-currencies';
        };

        const redirectRequestsCutting
            = () => {
            window.location.href = '/organization/requests-cutting';
        };

        const redirectCustomizedMaterials
            = () => {
            window.location.href = '/organization/requests-customized-materials';
        };

        const redirectCustomizedMaterialsDetails
            = () => {
            window.location.href = '/organization/requests-customized-materials-details';
        };

        const redirectOptimizations
            = () => {
            window.location.href = '/organization/optimizations';
        };

        const redirectOptimizationsCalculation
            = () => {
            window.location.href = '/organization/optimizations-Calculation';
        };

        const redirectWarehouseFinishedDetail
            = () => {
            window.location.href = '/organization/warehouse-finished-detail';
        };

        const redirectWorkingCuttingMaps
            = () => {
            window.location.href = '/organization/working-cutting-maps';
        };

        const redirectWorkingCuttingMapsCalculation
            = () => {
            window.location.href = '/organization/working-cutting-maps-Calculation';
        };

        const redirectPlans
            = () => {
            window.location.href = '/organization/plans';
        };

        const redirectMaterialsPurchase = () => {
            window.location.href = '/organization/requests-purchase';
        };

        const redirectFormationPurchaseOrders = () => {
            window.location.href = '/organization/formation-purchase-order';
        };

        const redirectReservationPieceMaterial = () => {
            window.location.href = '/organization/reservation-piece-material';
        };

        const redirectReservationSlabMaterial = () => {
            window.location.href = '/organization/reservation-slab-material';
        };

        const redirectReservationLinearMaterial = () => {
            window.location.href = '/organization/reservation-linear-material';
        };

        const redirectRequestsCuttingCalculation = () => {
            window.location.href = '/organization/requests-cutting/Calculation';
        };


        const redirectOrdersCloud = () => {
            window.location.href = '/organization/orders-cloud';
        };

        const redirectCustomerOrders = () => {
            window.location.href = '/customer/orders';
        };

        const redirectQueueCuttingMaps = () => {
            window.location.href = '/organization/queue-cutting-maps';
        };

        const redirectIssuingEdges = () => {
            window.location.href = '/organization/issuing-edges';
        };

        const issuancePieceMaterials = () => {
            window.location.href = '/organization/issuance-piece-materials/';
        };

        const redirectAllMaterials = () => {
            window.location.href = '/organization/all-materials';
        };

        const redirectAllIssue = () => {
            window.location.href = '/organization/issuance-material-reserve';
        };

        const redirectInWork = () => {
            window.location.href = '/organization/in-work';
        };

        const redirectInOrderEconomics = () => {
            window.location.href = '/organization/orders/economic';
        };

        const redirectOrdersForWriteOff = () => {
            window.location.href = '/organization/for-write-off';
        };

        const redirectSettingsCloud = () => {
            window.location.href = '/settings-cloud';
        };

        return (
            <div>
                <Box sx={{width: "100%", flexGrow: 1}}>
                    <AppBar position="static" sx={{width: "100%", flexGrow: 1}}>
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="/"
                                    sx={{
                                        mr: 2,
                                        display: {xs: 'none', md: 'flex'},
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    UpMe
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}>
                                    {customer && <MainMenu
                                        name={'customer'}
                                        handleMenu={handleMenuCustomer}
                                        anchorEl={anchorElCustomer}
                                        handleClose={handleCloseCustomer}
                                        menuItems={[
                                            {
                                                label: 'Заказы',
                                                onClick: redirectCustomerOrders,
                                                allowInDev: customerOrders
                                            },
                                        ]}
                                        labelName={"Мои заказы"}
                                    />}
                                    {calculation && <MainMenu
                                        name={'calculation'}
                                        handleMenu={handleMenuCalculation}
                                        anchorEl={anchorElCalculation}
                                        handleClose={handleCloseCalculation}
                                        menuItems={[
                                            {
                                                label: 'Заказы из облака',
                                                onClick: redirectOrdersCloud,
                                                allowInDev: ordersCloud
                                            },
                                            {
                                                label: 'Заявки на раскрой',
                                                onClick: redirectRequestsCuttingCalculation,
                                                allowInDev: calculationRequestsCutting
                                            },
                                            {
                                                label: 'Оптимизации на просчет',
                                                onClick: redirectOptimizationsCalculation,
                                                allowInDev: calculationOptimizations
                                            },
                                            {
                                                label: 'Карты раскроя на просчет',
                                                onClick: redirectWorkingCuttingMapsCalculation,
                                                allowInDev: calculationWorkingCuttingMaps
                                            },
                                        ]}
                                        labelName={"Расчеты"}
                                    />}
                                    {orders && <MainMenu
                                        name={'order'}
                                        handleMenu={handleMenuOrder}
                                        anchorEl={anchorElOrder}
                                        handleClose={handleCloseOrder}
                                        menuItems={[
                                            {label: 'Заказы', onClick: redirectOrders, allowInDev: orders},
                                            {
                                                label: 'Проектирование',
                                                onClick: redirectConstructors,
                                                allowInDev: ordersConstructors
                                            },
                                            {
                                                label: 'На списание',
                                                onClick: redirectOrdersForWriteOff,
                                                allowInDev: ordersOrdersToArchive
                                            },
                                            {label: 'Архив', onClick: redirectArchive, allowInDev: ordersOrdersArchive},
                                            {
                                                label: 'Настройки',
                                                onClick: redirectSettingsOrders,
                                                allowInDev: ordersOrdersSettings
                                            },
                                        ]}
                                        labelName={"Заказы"}
                                    />}
                                    {optimization && <MainMenu
                                        name={'optimization'}
                                        handleMenu={handleMenuOptimization}
                                        anchorEl={anchorElOptimization}
                                        handleClose={handleCloseOptimization}
                                        menuItems={[
                                            {
                                                label: 'Заявки на раскрой',
                                                onClick: redirectRequestsCutting,
                                                allowInDev: optimizationRequestsCutting
                                            },
                                            {
                                                label: 'Заявки на индивидуальные изделия',
                                                onClick: redirectCustomizedMaterials,
                                                allowInDev: optimizationRequestsCustomizedMaterials
                                            },
                                            {
                                                label: 'Заявки на заказные материалы',
                                                onClick: redirectCustomizedMaterialsDetails,
                                                allowInDev: optimizationCustomizedMaterialsDetails
                                            },
                                            {
                                                label: 'Планы',
                                                onClick: redirectPlans,
                                                allowInDev: optimizationPlan
                                            },
                                            {
                                                label: 'Оптимизации',
                                                onClick: redirectOptimizations,
                                                allowInDev: optimizationOptimization
                                            },
                                        ]}
                                        labelName={"Оптимизация"}
                                    />}

                                    {storage && <MainMenu
                                        name={'storage'}
                                        handleMenu={handleMenuStorage}
                                        anchorEl={anchorElStorage}
                                        handleClose={handleCloseStorage}
                                        menuItems={[
                                            {label: 'Материалы', onClick: redirectMaterials, allowInDev: storageMaterials},
                                            {
                                                label: 'Заявки на закупку',
                                                onClick: redirectMaterialsPurchase,
                                                allowInDev: storageRequestsPurchase
                                            },
                                            {
                                                label: 'Резервирование фурнитуры',
                                                onClick: redirectReservationPieceMaterial,
                                                allowInDev: storageReservationPieceMaterial
                                            },
                                            {
                                                label: 'Резервирование плитных материалов',
                                                onClick: redirectReservationSlabMaterial,
                                                allowInDev: storageReservationSlabMaterial
                                            },
                                            {
                                                label: 'Резервирование погонных материалов',
                                                onClick: redirectReservationLinearMaterial,
                                                allowInDev: storageStorageReservationLinearMaterial
                                            },
                                            {
                                                label: 'Заявки на поставку',
                                                onClick: redirectMaterialsArrival,
                                                allowInDev: storageStorageMaterialsArrival
                                            },
                                            {
                                                label: 'Курсы валют',
                                                onClick: redirectCurrentCurrencies,
                                                allowInDev: storageStorageCurrentCurrencies
                                            },
                                            {
                                                label: 'Настройки',
                                                onClick: redirectSettingsStorage,
                                                allowInDev: storageStorageSettingsStorage
                                            },
                                        ]}
                                        labelName={"Склад"}
                                    />}
                                    {cutting && <MainMenu
                                        name={'cutting'}
                                        handleMenu={handleMenuCutting}
                                        anchorEl={anchorElCutting}
                                        handleClose={handleCloseCutting}
                                        menuItems={[
                                            {
                                                label: 'Карты раскроя',
                                                onClick: redirectWorkingCuttingMaps,
                                                allowInDev: cuttingWorkingCuttingMaps
                                            },
                                            {
                                                label: 'Порядок раскроя',
                                                onClick: redirectQueueCuttingMaps,
                                                allowInDev: cuttingQueueCuttingMaps
                                            },
                                            {
                                                label: 'Склад готовых деталей',
                                                onClick: redirectWarehouseFinishedDetail,
                                                allowInDev: cuttingWarehouseFinishedDetail
                                            },
                                        ]}
                                        labelName={"Раскрой"}
                                    />}
                                    {production && <MainMenu
                                        name={'production'}
                                        handleMenu={handleMenuProduction}
                                        anchorEl={anchorElProduction}
                                        handleClose={handleCloseProduction}
                                        menuItems={[
                                            {
                                                label: 'Производство',
                                                onClick: redirectProduction,
                                                allowInDev: productionProduction
                                            },
                                            {
                                                label: 'Выдача производсвенных материалов',
                                                onClick: redirectIssuingEdges,
                                                allowInDev: productionIssuingEdges
                                            },
                                            {
                                                label: 'Выдача штучных материалов',
                                                onClick: issuancePieceMaterials,
                                                allowInDev: productionIssuancePieceMaterials
                                            },
                                        ]}
                                        labelName={"Производство"}
                                    />}
                                    {economic && <MainMenu
                                        name={'economy'}
                                        handleMenu={handleMenuEconomy}
                                        anchorEl={anchorElEconomy}
                                        handleClose={handleCloseEconomy}
                                        menuItems={[
                                            {
                                                label: 'Заказы',
                                                onClick: redirectInOrderEconomics,
                                                allowInDev: economicOrders
                                            },
                                            {
                                                label: 'Выдачи',
                                                onClick: redirectAllIssue,
                                                allowInDev: economicIssuanceMaterialReserve
                                            },
                                            {label: 'В работе', onClick: redirectInWork, allowInDev: economicInWork},
                                        ]}
                                        labelName={"Экономика"}
                                    />}
                                    {materialCloud && <MainMenu
                                        name={'cloud'}
                                        handleMenu={handleMenuCloud}
                                        anchorEl={anchorElCloud}
                                        handleClose={handleCloseCloud}
                                        menuItems={[
                                            {label: 'Материалы', onClick: redirectAllMaterials, allowInDev: materialCloud},
                                            {
                                                label: 'Настройки',
                                                onClick: redirectSettingsCloud,
                                                allowInDev: materialCloudAdminRoles
                                            },
                                        ]}
                                        labelName={"Облако"}
                                    />}
                                </Box>
                                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={auth}
                                                    onChange={handleChange}
                                                    aria-label="login switch"
                                                />
                                            }
                                            label={auth ? 'Выход' : 'Вход'}
                                        />
                                    </FormGroup>
                                    {auth && (
                                        <div>
                                            {/* <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenuRight}
                                            color="inherit"
                                        >
                                            <AccountCircle/>
                                        </IconButton>*/}
                                            {/* <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElRight}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElRight)}
                                            onClose={handleCloseRight}
                                        >
                                            <MenuItem onClick={handleCloseRight}>Profile</MenuItem>
                                            <MenuItem onClick={redirectProduction}>My account</MenuItem>
                                        </Menu>*/}
                                        </div>
                                    )}
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </Box>
            </div>
        );
    }
;

export default MenuAppBar;
