import Error from "../pages/common/Error";
import TechnicalMap from "../pages/organization/orders/TechnicalMap";
import SettingsOrders from "../pages/organization/orders/SettingsOrders";
import Workers from "../pages/organization/orders/worker/Workers";
import TechnicalMaps from "../pages/organization/orders/TechnicalMaps";
import WorkerJob from "../pages/organization/orders/WorkerJob";
import Orders from "../pages/organization/orders/order/Orders";
import OrderItem from "../pages/organization/orders/order/OrderItem";
import Customers from "../pages/organization/orders/Customers";
import Constructors from "../pages/organization/orders/Constructors";
import PackagingSet from "../pages/organization/model-estimate/packaging/PackagingSet";
import PackagingUnit from "../pages/organization/model-estimate/packaging/PackagingUnit";
import PackagingSetItem from "../pages/organization/model-estimate/packaging/PackagingSetItem";
import PackagingSetItemAddUnit from "../pages/organization/model-estimate/packaging/PackagingSetItemAddUnit";
import Models from "../pages/organization/model-estimate/models/Models";
import ModelHeaders from "../pages/organization/orders/ModelHeaders";
import ModelSetPackagingSet from "../pages/organization/model-estimate/models/ModelSetPackagingSet";
import Attributes from "../pages/organization/orders/Attributes";
import Archive from "../pages/organization/orders/Archive";
import SetAttributeOrder from "../pages/organization/orders/order/SetAttributeOrder";
import React from "react";
import Users from "../pages/common/Users";
import UsersItem from "../pages/organization/orders/UsersItem";
import Paths from "../pages/organization/orders/Paths";
import Materials from "../pages/organization/storage/material/material/Materials";
import Units from "../pages/organization/storage/Units";
import SettingsStorage from "../pages/organization/storage/settings/SettingsStorage";
import CustomerOrderOffers from "../pages/customer/orders/CustomerOrderOffers";
import CurrentCurrencyNationalBank from "../pages/organization/storage/price/CurrentCurrencyNationalBank";
import MaterialItem from "../pages/organization/storage/material/material/MaterialItem";
import ArrivalMaterial from "../pages/organization/storage/material/arrival/ArrivalMaterial";
import ArrivalMaterialPriceUnit from "../pages/organization/storage/material/arrival/ArrivalMaterialPriceUnit";
import SupplierItem from "../pages/organization/storage/supplier/SupplierItem";
import ArrivalMaterialArchive from "../pages/organization/storage/material/arrival/ArrivalMaterialArchive";
import ArrivalMaterialPriceUnitArchive from "../pages/organization/storage/material/arrival/ArrivalMaterialPriceUnitArchive";
import RequestsCuttingsAll from "../pages/organization/optimization/requestscutting/RequestsCuttingsAll";
import WarehouseFinishedDetail from "../pages/organization/cutting/warehousefinisheddetail/WarehouseFinishedDetail";
import WorkingCuttingMaps from "../pages/organization/cutting/workingcuttingmap/WorkingCuttingMaps";
import WorkingCuttingMapItem from "../pages/organization/cutting/workingcuttingmap/WorkingCuttingMapItem";
import RequestCustomizedMaterials from "../pages/organization/optimization/requestcustomizedmaterials/RequestCustomizedMaterials";
import RequestCustomizedMaterialsDetails
    from "../pages/organization/optimization/requestcustomizedmaterialsdetails/RequestCustomizedMaterialsDetails";
import Optimizations from "../pages/organization/optimization/optimization/Optimizations";
import RequestsCuttingOptimization from "../pages/organization/optimization/requestscutting/RequestsCuttingOptimization";
import DetailsRequestsCuttingOptimization
    from "../pages/organization/optimization/requestscutting/details/DetailsRequestsCuttingOptimization";
import DetailsRequestsCutting from "../pages/organization/optimization/requestscutting/details/DetailsRequestsCutting";
import Plans from "../pages/organization/optimization/plans/Plans";
import PlanItem from "../pages/organization/optimization/plans/PlanItem";
import RequestsCuttingPlan from "../pages/organization/optimization/requestscutting/RequestsCuttingPlan";
import EstimateItem from "../pages/organization/orders/estimate/EstimateItem";
import RequestsPurchase from "../pages/organization/storage/purchase/RequestsPurchase";
import FormationRequestsPurchase from "../pages/organization/storage/purchase/FormationRequestsPurchase";
import RequestsPurchaseItem from "../pages/organization/storage/purchase/RequestsPurchaseItem";
import ReservationPieceMaterial from "../pages/organization/orders/reservation/ReservationPieceMaterial";
import ReservationSlabMaterial from "../pages/organization/orders/reservation/ReservationSlabMaterial";
import ArrivalMaterialItem from "../pages/organization/storage/material/arrival/ArrivalMaterialItem";
import SupplierMaterialArchiveItem from "../pages/organization/storage/material/suppliermaterial/SupplierMaterialArchiveItem";
import MaterialFixedStripeArchiveItem from "../pages/organization/storage/material/suppliermaterial/MaterialFixedStripeArchiveItem";
import MaterialMinStripeArchiveItem from "../pages/organization/storage/material/suppliermaterial/MaterialMinStripeArchiveItem";
import ReservationMaterialEstimateUnit from "../pages/organization/orders/reservation/ReservationMaterialEstimateUnit";
import RequestsCuttingCalculation from "../pages/organization/optimization/requestscutting/RequestsCuttingCalculation";
import OptimizationsCalculation from "../pages/organization/optimization/optimization/OptimizationsCalculation";
import WorkingCuttingMapsCalculation from "../pages/organization/cutting/workingcuttingmap/WorkingCuttingMapsCalculation";
import WorkingCuttingMapsCalculationArchive
    from "../pages/organization/cutting/workingcuttingmap/WorkingCuttingMapsCalculationArchive";
import WorkingCuttingMapsArchive from "../pages/organization/cutting/workingcuttingmap/WorkingCuttingMapsArchive";
import ReservationLinerMaterial from "../pages/organization/orders/reservation/ReservationLinerMaterial";
import AllMaterials from "../pages/material-cloud/admin/AllMaterials";
import AllMaterialItem from "../pages/material-cloud/admin/AllMaterialItem";
import OrganizationSupplierMaterialArchiveItem
    from "../pages/organization/storage/material/suppliermaterial/organization/OrganizationSupplierMaterialArchiveItem";
import OrganizationMaterialFixedStripeArchiveItem
    from "../pages/organization/storage/material/suppliermaterial/organization/OrganizationMaterialFixedStripeArchiveItem";
import OrganizationMaterialMinStripeArchiveItem
    from "../pages/organization/storage/material/suppliermaterial/organization/OrganizationMaterialMinStripeArchiveItem";
import RatingsWorker from "../pages/organization/orders/worker/RatingsWorker";
import BasicModels from "../pages/organization/model-estimate/models/BasicModels";
import BasicModelsEstimate from "../pages/organization/model-estimate/estimate/BasicModelsEstimate";
import BasicModelsMaterialEstimateUnit from "../pages/organization/model-estimate/estimate/BasicModelsMaterialEstimateUnit";
import ModelReplacement from "../pages/organization/model-estimate/models/ModelReplacement";
import CutFile from "../pages/organization/cutting/workingcuttingmap/CutFile";
import AllIssuanceMaterialReserve from "../pages/organization/economic/AllIssuanceMaterialReserve";

import SettingsCloud from "../pages/material-cloud/admin/SettingsCloud";
import SettingsCloudLoadGroupMaterial from "../pages/material-cloud/admin/SettingsCloudLoadGroupMaterial";
import SettingsCloudLoadMaterial from "../pages/material-cloud/admin/SettingsCloudLoadMaterial";
import SettingsLoadGroupOrganization from "../pages/organization/storage/settings/SettingsLoadGroupOrganization";
import SettingsLoadSupplierMaterials from "../pages/organization/storage/settings/SettingsLoadSupplierMaterials";
import SettingsLoadMaterialOrganization from "../pages/organization/storage/settings/SettingsLoadMaterialOrganization";
import IssuingEdges from "../pages/organization/prodaction/IssuingEdges";
import IssuingEdge from "../pages/organization/prodaction/IssuingEdge";
import IssuanceWarehouseMaterial from "../pages/organization/prodaction/issuance/IssuanceWarehouseMaterial";
import IssuanceWarehouseMaterialCurrentIssuingEdge
    from "../pages/organization/prodaction/issuance/IssuanceWarehouseMaterialCurrentIssuingEdge";
import IssuanceIssuingEdgesItem from "../pages/organization/prodaction/IssuanceIssuingEdgesItem";
import IssuanceMaterialReserve from "../pages/organization/prodaction/issuance/IssuanceMaterialReserve";
import IssuancePieceMaterials from "../pages/organization/prodaction/issuance/IssuancePieceMaterials";
import IssuancePieceMaterialsItem from "../pages/organization/prodaction/issuance/IssuancePieceMaterialsItem";
import IssuanceReservationMaterialEstimateUnit
    from "../pages/organization/prodaction/issuance/IssuanceReservationMaterialEstimateUnit";
import InWork from "../pages/organization/prodaction/issuance/InWork";

import SignJob from "../pages/organization/prodaction/productioncosts/SignJob";
import OrdersProduction from "../pages/organization/prodaction/OrdersProduction";
import QueueCuttingMaps from "../pages/organization/cutting/workingcuttingmap/QueueCuttingMaps";
import CustomerOrders from "../pages/customer/orders/CustomerOrders";
import CustomerOrderItem from "../pages/customer/orders/CustomerOrderItem";
import OrdersForWriteOff from "../pages/organization/orders/order/OrdersForWriteOff";
import CustomerRequestsCutting from "../pages/customer/details/CustomerRequestsCutting"
import Processing from "../pages/customer/processing/Processing";
import OrdersCloud from "../pages/organization/orders/order-cloud/OrdersCloud";
import CustomerOrganizationEstimateItem from "../pages/customer/orders/CustomerEstimateOrganizationItem";
import OrderCloudItem from "../pages/organization/orders/order-cloud/OrderCloudItem";
import OrdersEconomic from "../pages/organization/economic/OrdersEconomic";

//Calculation
export const Calculation = [
    'ROLE_CALCULATION_USER'
]

export const CalculationRequestsCutting = [
    'ROLE_CALCULATION_REQUESTS_CUTTING_USER'
]

export const CalculationOptimizations = [
    'ROLE_CALCULATION_OPTIMIZATIONS_USER'
]

export const CalculationWorkingCuttingMaps = [
    'ROLE_CALCULATION_WORKING_CUTTING_MAPS_USER'
]

//orders
export const OrdersUser = [
    'ROLE_ORDERS_USER'
]

export const ConstructorsUser = [
    'ROLE_ORDERS_CONSTRUCTOR_USER'
]

export const OrdersArchive = [
    'ROLE_ORDERS_ARCHIVE_USER'
]

export const OrdersToArchive = [
    'ROLE_ORDERS_TO_ARCHIVE_USER'
]

export const OrdersSettings = [
    'ROLE_ORDERS_SETTINGS_USER'
]

//technicalMap
export const OrdersTechnicalMap = [
    'ROLE_TECHNICAL_MAP_USER'
]
export const OrdersWorkerJob = [
    'ROLE_WORKER_JOB_USER'
]

//Estimate
export const OrdersEstimates = [
    'ROLE_ESTIMATE_USER'
]

//Optimization
export const Optimization = [
    'ROLE_OPTIMIZATION_USER'
]

export const RequestsCutting = [
    'ROLE_OPTIMIZATION_REQUESTS_CUTTING_USER'
]

export const RequestsCustomizedMaterials = [
    'ROLE_OPTIMIZATION_REQUESTS_CUSTOMIZED_MATERIALS'
]

export const CustomizedMaterialsDetails = [
    'ROLE_OPTIMIZATION_CUSTOMIZED_MATERIALS_DETAILS_USER'
]

export const Plan = [
    'ROLE_OPTIMIZATION_PLAN_USER'
]

export const OptimizationOptimization = [
    'ROLE_OPTIMIZATION_OPTIMIZATION_USER'
]

//storage
export const Storage = [
    'ROLE_STORAGE_USER'
]

export const StorageMaterials = [
    'ROLE_STORAGE_MATERIALS_USER'
]

export const StorageRequestsPurchase = [
    'ROLE_STORAGE_REQUESTS_PURCHASE_USER'
]

export const StorageReservationPieceMaterial = [
    'ROLE_STORAGE_RESERVATION_PIECE_MATERIAL_USER'
]

export const StorageReservationSlabMaterial = [
    'ROLE_STORAGE_RESERVATION_SLAB_MATERIAL_USER'
]

export const StorageReservationLinearMaterial = [
    'ROLE_STORAGE_RESERVATION_LINEAR_MATERIAL_USER'
]

export const StorageMaterialsArrival = [
    'ROLE_STORAGE_MATERIALS_ARRIVAL_USER'
]

export const StorageCurrentCurrencies = [
    'ROLE_STORAGE_CURRENT_CURRENCIES_USER'
]

export const StorageSettingsStorage = [
    'ROLE_STORAGE_SETTINGS_STORAGE_USER'
]

//Cutting
export const Cutting = [
    'ROLE_CUTTING_USER'
]

export const CuttingWorkingCuttingMaps = [
    'ROLE_CUTTING_WORKING_CUTTING_MAPS_USER'
]

export const CuttingQueueCuttingMaps = [
    'ROLE_CUTTING_QUEUE_CUTTING_MAPS_USER'
]

export const CuttingWarehouseFinishedDetail = [
    'ROLE_WAREHOUSE_FINISHED_DETAIL_USER'
]

//PRODUCTION
export const OrderPRODUCTION = [
    'ROLE_PRODUCTION_USER'
]

export const OrderPRODUCTIONPRODUCTION = [
    'ROLE_PRODUCTION_PRODUCTION_USER'
]

export const OrderPRODUCTIONIssuingEdges = [
    'ROLE_PRODUCTION_ISSUING_EDGES_USER'
]

export const OrderPRODUCTIONIssuancePieceMaterials = [
    'ROLE_PRODUCTION_ISSUANCE-PIECE-MATERIALS_USER'
]

//economic
export const Economic = [
    'ROLE_ECONOMIC_USER'
]

export const EconomicIssuanceMaterialReserve = [
    'ROLE_ECONOMIC_ISSUANCE_MATERIAL_RESERVE_USER'
]

export const EconomicInWork = [
    'ROLE_ECONOMIC_IN_WORK_USER'
]

//Material_cloud
export const MaterialCloudAdminRoles = [
    'ROLE_MATERIAL_CLOUD_SUPER_USER'
]

export const MaterialCloud = [
    'ROLE_MATERIAL_CLOUD_USER'
]

export const CustomerRoles = [
    'CUSTOMER'
]

//Customer_requestsCutting_cutting
export const CustomerRequestsCuttingRoles = [
    'ROLE_CUSTOMER_REQUESTS_CUTTING_USER'
]


export const routes = [

    //calculation
    {
        component: <RequestsCuttingCalculation/>, path: '/organization/requests-cutting/calculation', errorElement: Error,
        roles: CalculationRequestsCutting
    },
    {
        component: <OptimizationsCalculation/>, path: '/organization/optimizations-calculation', errorElement: Error,
        roles: CalculationOptimizations
    },
    {
        component: <WorkingCuttingMapsCalculation/>, path: '/organization/working-cutting-maps-calculation', errorElement: Error,
        roles: CalculationWorkingCuttingMaps
    },

    {component: <OrdersCloud/>, path: '/organization/orders-cloud', errorElement: Error, roles: OrdersUser},
    {component: <OrderCloudItem/>, path: '/organization/orders-cloud/:orderId/:numberCustomer', errorElement: Error, roles: OrdersUser},

    //orders
    //*OrdersUser*
    {component: <Orders/>, path: '/organization/orders', errorElement: Error, roles: OrdersUser},
    {component: <OrdersEconomic/>, path: '/organization/orders/economic', errorElement: Error, roles: OrdersUser},
    {
        component: <SetAttributeOrder/>,
        path: '/organization/orders/set-attribute/:id/:number/:idHeader/:year',
        errorElement: Error,
        roles: OrdersUser
    },

    {component: <Archive/>, path: '/organization/archive', errorElement: Error, roles: OrdersArchive},
    {component: <OrdersForWriteOff/>, path: '/organization/for-write-off', errorElement: Error, roles: OrdersToArchive},

    //*OrdersSettings*
    {component: <Customers/>, path: '/organization/customers', errorElement: Error, roles: OrdersSettings},
    {component: <Constructors/>, path: '/organization/constructors', errorElement: Error, roles: OrdersSettings},
    {component: <Attributes/>, path: '/organization/attributes', errorElement: Error, roles: OrdersSettings},
    {component: <SettingsOrders/>, path: '/organization/settings-orders', errorElement: Error, roles: OrdersSettings},
    //model-estimate
    {component: <PackagingUnit/>, path: '/organization/packaging-units', errorElement: Error, roles: OrdersSettings},
    {component: <PackagingSet/>, path: '/organization/packaging-sets', errorElement: Error, roles: OrdersSettings},
    {component: <Models/>, path: '/organization/models', errorElement: Error, roles: OrdersSettings},
    {component: <BasicModels/>, path: '/organization/basic-models', errorElement: Error, roles: OrdersSettings},
    {
        component: <BasicModelsEstimate/>,
        path: '/organization/basic-models/:id/estimates/',
        errorElement: Error,
        roles: OrdersSettings
    },
    {
        component: <BasicModelsMaterialEstimateUnit/>,
        path: '/organization/basic-models/:id/estimates/:estimateId',
        errorElement: Error,
        roles: OrdersSettings
    },
    {component: <SignJob/>, path: '/organization/PRODUCTIONCosts', errorElement: Error, roles: OrdersSettings},
    {component: <ModelReplacement/>, path: '/organization/models/replacements/:id', errorElement: Error, roles: OrdersSettings},

    //models
    {
        component: <ModelSetPackagingSet/>,
        path: '/organization/models/set-packaging-set/:id/:article',
        errorElement: Error,
        roles: OrdersSettings
    },
    {component: <ModelHeaders/>, path: '/organization/model-headers', errorElement: Error, roles: OrdersSettings},
    {component: <PackagingSetItem/>, path: '/organization/packaging-sets/:id/:article', errorElement: Error, roles: OrdersSettings},
    {
        component: <PackagingSetItemAddUnit/>,
        path: '/organization/packaging-sets-units/:id/:article',
        errorElement: Error,
        roles: OrdersSettings
    },
    {component: <OrderItem/>, path: '/organization/orders/:id/:number/:idHeader/:year', errorElement: Error, roles: OrdersSettings},

    //Users
    {component: <Users/>, path: '/organization/users', errorElement: Error, roles: OrdersSettings},
    {component: <UsersItem/>, path: '/organization/users/:id/:name', errorElement: Error, roles: OrdersSettings},
    {component: <Workers/>, path: '/organization/workers', errorElement: Error, roles: OrdersSettings},
    {
        component: <RatingsWorker/>,
        path: '/organization/workers/:id/ratings/:nameWorker',
        errorElement: Error,
        roles: OrdersSettings
    },
    {component: <Paths/>, path: '/organization/paths', errorElement: Error, roles: OrdersSettings},

    //TechnicalMap
    //*OrdersTechnicalMap*
    {component: <TechnicalMap/>, path: '/organization/technical-map/:id/:order', errorElement: Error, roles: OrdersTechnicalMap},
    {component: <TechnicalMaps/>, path: '/organization/technical-maps', errorElement: Error, roles: OrdersTechnicalMap},
    {
        component: <WorkerJob/>,
        path: '/organization/job-workers/:id/:jobName/:jobMaterial',
        errorElement: Error,
        roles: OrdersWorkerJob
    },

    //Estimate
    //*OrdersEstimates*

    {
        component: <EstimateItem/>,
        path: '/organization/orders/:orderId/:orderNumber/:orderHeaderId/:year/estimates/:estimateId/:isCurrent/:estimateState',
        errorElement: Error,
        roles: OrdersEstimates
    },
    {
        component: <ReservationMaterialEstimateUnit/>,
        path: '/organization/orders/:orderId/:orderNumber/:orderHeaderId/:year/estimates/:estimateId/:isButtonPurchase/reservation/:materialEstimateUnitId/:isSlab/:name/:articleNumber/:type',
        errorElement: Error,
        roles: OrdersEstimates
    },

    //storage
    //*StorageMaterials*
    {component: <Materials/>, path: '/organization/materials', errorElement: Error, roles: StorageMaterials},
    {
        component: <MaterialItem/>,
        path: '/organization/materials/:id/:baseUnit/:typeMaterial/:materialId',
        errorElement: Error,
        roles: StorageMaterials
    },
    //*StorageMaterialsArrival*
    {component: <ArrivalMaterial/>, path: '/organization/materials-arrival', errorElement: Error, roles: StorageMaterialsArrival},
    {
        component: <ArrivalMaterialArchive/>,
        path: '/organization/materials-arrival-archive',
        errorElement: Error,
        roles: StorageMaterialsArrival
    },
    {
        component: <ArrivalMaterialPriceUnit/>,
        path: '/organization/materials-price-unit-arrival/:arrivalMaterialId/:currentCurrency/' +
            ':isBaseCurrency/:isAlternativeCurrency/:baseCurrency/:alternativeCurrency/:supplierId/:warehouseId',
        errorElement: Error,
        roles: StorageMaterialsArrival
    },
    {
        component: <ArrivalMaterialPriceUnitArchive/>,
        path: '/organization/materials-price-unit-arrival-archive/:arrivalMaterialId/:currentCurrency/:baseCurrency/:alternativeCurrency/',
        errorElement: Error,
        roles: StorageMaterialsArrival
    },
    {
        component: <ArrivalMaterialItem/>,
        path: '/organization/materials-price-unit-arrival-item/:id/:isSlabMaterial/:baseUnit',
        errorElement: Error,
        roles: StorageMaterialsArrival
    },

    {
        component: <OrganizationSupplierMaterialArchiveItem/>,
        path: 'organization/supplier-material-archive-item/:materialId/:baseUnit/:typeMaterial',
        errorElement: Error,
        roles: StorageMaterialsArrival
    },
    {
        component: <OrganizationMaterialFixedStripeArchiveItem/>,
        path: 'organization/material-fixed-archive-item/:materialId/:baseUnit/:typeMaterial',
        errorElement: Error,
        roles: StorageMaterialsArrival
    },
    {
        component: <OrganizationMaterialMinStripeArchiveItem/>,
        path: 'organization/material-min-archive-item/:materialId/:baseUnit/:typeMaterial',
        errorElement: Error,
        roles: StorageMaterialsArrival
    },
    //*StorageRequestsPurchase*
    {
        component: <RequestsPurchase/>,
        path: '/organization/requests-purchase',
        errorElement: Error,
        roles: StorageRequestsPurchase
    },
    {
        component: <RequestsPurchaseItem/>,
        path: '/organization/requests-purchase/:estimateId/:orderId/:orderNumber/:status',
        errorElement: Error,
        roles: StorageRequestsPurchase
    },
    {
        component: <FormationRequestsPurchase/>,
        path: '/organization/formation-requests-purchase',
        errorElement: Error,
        roles: StorageRequestsPurchase
    },
    {
        component: <ReservationPieceMaterial/>,
        path: '/organization/reservation-piece-material',
        errorElement: Error,
        roles: StorageReservationPieceMaterial
    },
    {
        component: <ReservationSlabMaterial/>,
        path: '/organization/reservation-slab-material',
        errorElement: Error,
        roles: StorageReservationSlabMaterial
    },
    {
        component: <ReservationLinerMaterial/>,
        path: '/organization/reservation-linear-material',
        errorElement: Error,
        roles: StorageReservationLinearMaterial
    },

    //*StorageSettingsStorage*
    {component: <SettingsStorage/>, path: '/organization/settings-storage', errorElement: Error, roles: StorageSettingsStorage},
    {
        component: <SettingsLoadGroupOrganization/>, path: '/organization/settings-load-group-organization', errorElement: Error,
        roles: StorageSettingsStorage
    },
    {
        component: <SettingsLoadMaterialOrganization/>,
        path: '/organization/settings-load-material-organization',
        errorElement: Error,
        roles: StorageSettingsStorage
    },
    {
        component: <SettingsLoadSupplierMaterials/>,
        path: '/organization/settings-load-supplier-material-organization',
        errorElement: Error,
        roles: StorageSettingsStorage
    },
    {component: <Units/>, path: '/organization/units', errorElement: Error, roles: StorageSettingsStorage},
    {
        component: <SupplierItem/>, path: '/organization/suppliers/:supplierId', errorElement: Error,
        roles: StorageSettingsStorage
    },
    {
        component: <CurrentCurrencyNationalBank/>, path: '/organization/current-currencies', errorElement: Error,
        roles: StorageCurrentCurrencies
    },

    //Optimization
    //*RequestsCutting*
    {
        component: <RequestsCuttingsAll/>, path: '/organization/requests-cutting', errorElement: Error,
        roles: RequestsCutting
    },
    {
        component: <DetailsRequestsCutting/>,
        path: '/organization/requests-cutting/detail/:id/:numberRequestsCutting',
        errorElement: Error,
        roles: RequestsCutting
    },
    //*OptimizationOptimization*
    {
        component: <RequestsCuttingOptimization/>,
        path: '/organization/requests-cutting/optimization/:optimizationId/:name',
        errorElement: Error,
        roles: OptimizationOptimization
    },
    {
        component: <DetailsRequestsCuttingOptimization/>,
        path: '/organization/details/optimization/:optimizationId/:number',
        errorElement: Error,
        roles: OptimizationOptimization
    },
    {
        component: <Optimizations/>, path: '/organization/optimizations', errorElement: Error,
        roles: OptimizationOptimization
    },
    {
        component: <RequestCustomizedMaterials/>, path: '/organization/requests-customized-materials', errorElement: Error,
        roles: CustomizedMaterialsDetails
    },
    {
        component: <RequestCustomizedMaterialsDetails/>,
        path: '/organization/requests-customized-materials-details',
        errorElement: Error,
        roles: CustomizedMaterialsDetails
    },

    //*Plan*
    {
        component: <Plans/>, path: '/organization/plans', errorElement: Error,
        roles: Plan
    },
    {
        component: <RequestsCuttingPlan/>,
        path: '/organization/requests-cutting/plan/:planId/:name',
        errorElement: Error,
        roles: Plan
    },
    {
        component: <PlanItem/>, path: '/organization/plans/:id', errorElement: Error,
        roles: Plan
    },

    //Cutting
    //*CuttingWorkingCuttingMaps*
    {
        component: <WorkingCuttingMaps/>, path: '/organization/working-cutting-maps', errorElement: Error,
        roles: CuttingWorkingCuttingMaps
    },
    {
        component: <WorkingCuttingMapsArchive/>, path: '/organization/working-cutting-maps-archive', errorElement: Error,
        roles: CuttingWorkingCuttingMaps
    },
    {
        component: <WorkingCuttingMapsCalculationArchive/>,
        path: '/organization/working-cutting-maps-calculation-archive',
        errorElement: Error,
        roles: CuttingWorkingCuttingMaps
    },
    {
        component: <WorkingCuttingMapItem/>,
        path: '/organization/working-cutting-map/:workingCuttingMapId/:nameMaterial',
        errorElement: Error,
        roles: CuttingWorkingCuttingMaps
    },
    {
        component: <WarehouseFinishedDetail/>,
        path: '/organization/warehouse-finished-detail',
        errorElement: Error,
        roles: CuttingWorkingCuttingMaps
    },

    //cut-file
    //*CuttingQueueCuttingMaps*
    {
        component: <CutFile/>,
        path: '/organization/working-cutting-map/:workingCuttingMapId/:nameMaterial/cut-files',
        errorElement: Error,
        roles: CuttingQueueCuttingMaps
    },
    {
        component: <QueueCuttingMaps/>,
        path: '/organization/queue-cutting-maps',
        errorElement: Error,
        roles: CuttingQueueCuttingMaps
    },

    //PRODUCTION
    {component: <OrdersProduction/>, path: '/organization/orders-production', errorElement: Error, roles: OrderPRODUCTIONPRODUCTION},

    //*OrderPRODUCTIONIssuingEdges*
    {
        component: <IssuingEdges/>, path: '/organization/issuing-edges', errorElement: Error,
        roles: OrderPRODUCTIONIssuingEdges
    },
    {
        component: <IssuingEdge/>, path: '/organization/issuing-edges/:id', errorElement: Error,
        roles: OrderPRODUCTIONIssuingEdges
    },
    {
        component: <IssuanceWarehouseMaterial/>, path: '/organization/issuance-warehouse-material/:id', errorElement: Error,
        roles: OrderPRODUCTIONIssuingEdges
    },
    {
        component: <IssuanceWarehouseMaterialCurrentIssuingEdge/>,
        path: '/organization/:issuingEdgeId/issuance-warehouse-material/:id',
        errorElement: Error,
        roles: OrderPRODUCTIONIssuingEdges
    },
    {
        component: <IssuanceIssuingEdgesItem/>, path: '/organization/issuance-issuing-edges-item/:id', errorElement: Error,
        roles: OrderPRODUCTIONIssuingEdges
    },
    {
        component: <IssuanceMaterialReserve/>, path: '/organization/issuance-material-reserve/:id', errorElement: Error,
        roles: OrderPRODUCTIONIssuingEdges
    },
    //*OrderPRODUCTIONIssuancePieceMaterials*
    {
        component: <IssuancePieceMaterials/>, path: '/organization/issuance-piece-materials/', errorElement: Error,
        roles: OrderPRODUCTIONIssuancePieceMaterials
    },
    {
        component: <IssuancePieceMaterialsItem/>, path: '/organization/issuance-piece-materials/:id/:order', errorElement: Error,
        roles: OrderPRODUCTIONIssuancePieceMaterials
    },
    {
        component: <IssuanceReservationMaterialEstimateUnit/>,
        path: '/organization/issuance-piece-materials/:orderId/:orderNumber/:eId/:type',
        errorElement: Error,
        roles: OrderPRODUCTIONIssuancePieceMaterials
    },
    //economic
    {
        component: <AllIssuanceMaterialReserve/>, path: '/organization/issuance-material-reserve', errorElement: Error,
        roles: EconomicIssuanceMaterialReserve
    },
    {
        component: <InWork/>,
        path: '/organization/in-work',
        errorElement: Error,
        roles: EconomicInWork
    },

    //material-cloud
    //*Material_Cloud*
    {component: <AllMaterials/>, path: '/all-materials', errorElement: Error, roles: MaterialCloud},
    {
        component: <AllMaterialItem/>,
        path: '/all-materials/:id/:baseUnit/:typeMaterial',
        errorElement: Error,
        roles: MaterialCloud
    },
    {
        component: <SettingsCloud/>,
        path: '/settings-cloud',
        errorElement: Error,
        roles: MaterialCloudAdminRoles
    },
    {
        component: <SettingsCloudLoadGroupMaterial/>,
        path: '/settings-cloud-load-group',
        errorElement: Error,
        roles: MaterialCloudAdminRoles
    },
    {
        component: <SettingsCloudLoadMaterial/>,
        path: '/settings-cloud-load-material',
        errorElement: Error,
        roles: MaterialCloudAdminRoles
    },
    {
        component: <SupplierMaterialArchiveItem/>,
        path: '/supplier-material-archive-item/:materialId/:baseUnit/:typeMaterial',
        errorElement: Error,
        roles: MaterialCloudAdminRoles
    },
    {
        component: <MaterialFixedStripeArchiveItem/>,
        path: '/material-fixed-archive-item/:materialId/:baseUnit/:typeMaterial',
        errorElement: Error,
        roles: MaterialCloudAdminRoles
    },
    {
        component: <MaterialMinStripeArchiveItem/>,
        path: '/organization/material-min-archive-item/:materialId/:baseUnit/:typeMaterial',
        errorElement: Error,
        roles: MaterialCloudAdminRoles
    },

    //{component: <DatabaseOfMaterials/>, path: '/organization/database-of-materials', errorElement: Error, roles: Orders_roles},
    //customer
    {
        component: <CustomerOrders/>,
        path: '/customer/orders/',
        errorElement: Error,
        roles: CustomerRoles
    },
    {
        component: <CustomerOrderItem/>,
        path: '/customer/orders/:id/:customerOrderNumber',
        errorElement: Error,
        roles: CustomerRoles
    },
    {
        component: <CustomerOrderOffers/>,
        path: '/customer/orders/:orderId/:customerOrderNumber/estimates',
        errorElement: Error,
        roles: OrdersEstimates
    },
    {
        component: <CustomerOrganizationEstimateItem/>,
        path: '/customer/orders/:orderId/:customerOrderNumber/estimates/:estimateId',
        errorElement: Error,
        roles: OrdersEstimates
    },
    //customer
    {
        component: <CustomerRequestsCutting/>, path: '/customer/orders/:orderId/requests-cutting/:requestsCuttingId/details', errorElement: Error,
        roles: CustomerRequestsCuttingRoles
    },
    {
        component: <Processing/>, path: '/customer/orders/:orderId/requests-cutting/:requestsCuttingId/details/:id', errorElement: Error,
        roles: CustomerRequestsCuttingRoles
    },



    //{component: <DatabaseOfMaterials/>, path: '/database-of-materials', errorElement: Error, roles: Orders_roles},

]
