import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import SupplierMaterialTable
    from "../../../../../../components/UI/table/warehouse/material/supplier/SupplierMaterialTable";
import UserSupplierMaterialService from "../../../../../../API/orders/warehouse/UserSupplierMaterialService";

const OrganizationSupplierMaterialArchiveItem = () => {

    const [data, setData] = useState([]);
    const params = useParams();

    useEffect(() => {
        UserSupplierMaterialService.getAllByMaterialId(params.materialId)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <SupplierMaterialTable
                data={data}
                isArchive={true}
                materialId={params.materialId}
                baseUnit={params.baseUnit}
                typeMaterial={params.typeMaterial}
            />
        </div>
    );
};

export default OrganizationSupplierMaterialArchiveItem;