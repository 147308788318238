import React, {useMemo} from "react";
import {COLORS} from "../../../../../../Constants";
import Hole from "./Hole";
import Layer from "./Layer";
import NotchRound from "./NotchRound";

const Detail = ({
                    shape,
                    points,
                    holes,
                    sideHoles,
                    detailData,
                    selectedHole,
                    setSelectedHole,
                    setHoveredHole,
                    processingHandle,
                    addEdgeHandle,
                    texture,
                    ...props
                }) => {

    return (
        <Layer layer={0}>
            <mesh
                scale={props.scale}
                position={[props.position[0], props.position[1], props.position[2] - props.scale]}
                onPointerOver={(event) => (console.log())}
                onClick={(event) => {
                    event.stopPropagation()
                }}>

                <extrudeGeometry args={[shape, {
                    bevelEnabled: false,
                }]}/>
                <meshBasicMaterial/>

                {holes?.map((el, i) => {
                        const isHole = el.name.includes("hole")
                        const notThrough = el.depth < detailData.height
                        const holeColor = !notThrough ? COLORS[1][900] : el.contour.position === "BOTTOM" ? COLORS[6][600] : COLORS[5][600]
                        const notchColor = el.contour.position === "TOP" ? "white" : COLORS[6][600]

                        const data = {
                            id: el.id,
                            name: el.name,
                            x: el.center.x,
                            y: el.center.y,
                            z: props.scale,
                            radius: el.radius,
                            color: isHole ? holeColor : notchColor,
                            through: !notThrough,
                        }
                        return (
                            isHole ?
                                <Hole
                                    key={`${el.id}${el.name}`}
                                    data={data}
                                    processingHandle={processingHandle}
                                    selectedHole={selectedHole}
                                    setSelectedHole={setSelectedHole}
                                    setHoveredHole={setHoveredHole}/> :
                                <NotchRound
                                    key={`${el.id}${el.name}`}
                                    data={data}
                                    processingHandle={processingHandle}
                                    selectedHole={selectedHole}
                                    setSelectedHole={setSelectedHole}
                                    setHoveredHole={setHoveredHole}
                                    texture={texture}/>
                        )
                    }
                )
                }
            </mesh>
        </Layer>
    )
}
export default Detail
