import React from 'react';
import {TextField} from "@mui/material";

const TextFieldObjectWithEntityListSetId = ({
                                                label,
                                                setId,
                                                entityList,
                                                disabled,
                                                minWidthXs,
                                                minWidthSm,
                                                minWidthMd,
                                            }) => {

    const currentMinWidthXs = minWidthXs ? minWidthXs : '300px';
    const currentMinWidthSm = minWidthSm ? minWidthSm : '360px';
    const currentMinWidthMd = minWidthMd ? minWidthMd : '400px';

    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={label}
                sx={{
                    width: '100%',
                    minWidth: {xs: currentMinWidthXs, sm: currentMinWidthSm, md: currentMinWidthMd},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                name={label}
                onChange={(e) =>
                    setId(e.target.value)
                }
                defaultValue={0}
                SelectProps={{
                    native: true,
                }}
            >
                {entityList.map((entity) => (
                    <option
                        key={entity.id}
                        value={entity.id}>
                        {entity.name}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default TextFieldObjectWithEntityListSetId;