import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";
import ObjectUtil from "../../../../utils/common/ObjectUtil";

const materialEstimateUnitUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/material-estimate-units'

export default class MaterialEstimateUnitService {

    static async getAllByOrderId(orderId) {
        return axios.get(materialEstimateUnitUrl + '/organization-order' + '?orderId=' + orderId);
    }

    static async delete(id) {
        await axios.delete(materialEstimateUnitUrl + '/' + id);
    }

    static async replacementByMaterialId(materialEstimateUnitId, materialId) {
        return await axios.put(materialEstimateUnitUrl + '/replacement-by-material-id' + '?materialEstimateUnitId=' + materialEstimateUnitId
            + '&materialId=' + materialId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async replacementByMaterialOrganizationOptionId(materialEstimateUnitId, materialOrganizationOptionId) {
        return await axios.put(materialEstimateUnitUrl + '/replacement-by-material-organization-option-id' + '?materialEstimateUnitId=' + materialEstimateUnitId
            + '&materialOrganizationOptionId=' + materialOrganizationOptionId,
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async issues(ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.patch(materialEstimateUnitUrl + '/issues', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async packaging(ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.patch(materialEstimateUnitUrl + '/packaging', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}