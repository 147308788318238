import React from 'react';
import {Box} from "@mui/material";
import TextFieldObjectWithEntityListSetValue
    from "../../../../components/UI/textfield/TextFieldObjectWithEntityListSetValue";
import TextFieldObjectWithEntityListSetId from "../../../../components/UI/textfield/TextFieldObjectWithEntityListSetId";

const ReplacementSimilarMaterialsFilter = ({
                                          typeMaterial,
                                          setTypeMaterial,
                                          listTypeMaterial,
                                          listUnits,
                                          unitId,
                                          setUnitId,
                                          manufacturers,
                                          setManufacturerId,
                                          groupMaterial,
                                          setGroupMaterialId
                                      }) => {

        return (
            <div>
                <Box display="flex" flexDirection="row">
                    <TextFieldObjectWithEntityListSetValue
                        label="Тип материала"
                        value={typeMaterial}
                        setValue={setTypeMaterial}
                        entityList={listTypeMaterial}
                        minWidthXs={275}
                        minWidthSm={275}
                        minWidthMd={275}
                    />
                    <Box sx={{ml: '10px'}}>
                        <TextFieldObjectWithEntityListSetId
                            label="Ед. изм"
                            setId={setUnitId}
                            entityList={listUnits}
                            minWidthXs={275}
                            minWidthSm={275}
                            minWidthMd={275}
                        />
                    </Box>
                    <Box sx={{ml: '10px'}}>
                        <TextFieldObjectWithEntityListSetId
                            label="Группа"
                            setId={setGroupMaterialId}
                            entityList={groupMaterial}
                            minWidthXs={275}
                            minWidthSm={275}
                            minWidthMd={275}
                        />
                    </Box>
                    <Box sx={{ml: '10px'}}>
                        <TextFieldObjectWithEntityListSetId
                            label="Производитель"
                            setId={setManufacturerId}
                            entityList={manufacturers}
                            minWidthXs={275}
                            minWidthSm={275}
                            minWidthMd={275}
                        />
                    </Box>
                </Box>
            </div>
        );
    }
;

export default ReplacementSimilarMaterialsFilter;