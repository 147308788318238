import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const statusRestUrl = gatewayUrl +  nameOrders + apiVersionOrders + '/organizations/statuses'

export default class StatusService {

    static async getAll() {
        return axios.get(statusRestUrl);
    }
}