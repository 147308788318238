import React from "react";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";
import Box from "@mui/material/Box";
import {alpha, SvgIcon} from "@mui/material";
import {styled} from "@mui/material/styles"
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import Collapse from '@mui/material/Collapse';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.5751.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon
            fontSize="inherit"
            color={'#000000'}
            style={{width: 14, height: 14}}
            {...props}
        >
            <path d="M2 2h20v20H2z" stroke="#000000" strokeWidth="2" fill="none"/>
        </SvgIcon>
    );
}

const MaterialTree = ({filterGroup, setFilterGroup, changeFilterGroup, setChangeFilterGroup, data, groupMap}) => {

    const handleToggle = (nodeId) => {

        if (nodeId === "M") {
            GroupMaterialUtil.addToFilterMapGroup(groupMap, filterGroup)
            setFilterGroup(filterGroup);
            setChangeFilterGroup(!changeFilterGroup)
        } else {
            let id = parseInt(nodeId);
            const group = groupMap.get(id);
            if (group !== null) {
                GroupMaterialUtil.addToFilterMapGroup(group, filterGroup)
                setFilterGroup(filterGroup);
                setChangeFilterGroup(!changeFilterGroup)
            }
        }

    };

    const StyledTreeItem = styled((props) => (
        <TreeItem {...props} slots={{groupTransition: TransitionComponent}}/>
    ))(({theme}) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.groupTransition}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }));

    function TransitionComponent(props) {
        return <Collapse {...props} />;
    }

    const renderStyledTreeItem = (groups) => {
        return groups.map((group) => {
            const hasSubgroups = group.subGroups && group.subGroups.length > 0;

            return (
                <StyledTreeItem
                    key={group.id}
                    itemId={`${group.id}`}
                    label={group.name}
                    onClick={() => handleToggle(group.id)}
                >
                    {hasSubgroups && (
                        renderStyledTreeItem(group.subGroups)
                    )}
                </StyledTreeItem>
            );
        });
    }

    return <div>
        <Box sx={{flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}>
            <SimpleTreeView
                aria-label="customized"
                defaultExpandedItems={['M']}
                slots={{
                    expandIcon: PlusSquare,
                    collapseIcon: MinusSquare,
                    endIcon: CloseSquare,
                }}
                sx={{
                    paddingTop: 8,
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: 'auto'
                }}
            >
                <StyledTreeItem
                    itemId="M"
                    label="Материалы"
                    onClick={() => handleToggle('M')}
                >
                    {renderStyledTreeItem(data)}
                </StyledTreeItem>
            </SimpleTreeView>
        </Box>
    </div>;
};

export default MaterialTree;
