import {Line} from "@react-three/drei";
import React from "react";

const LineElement = ({position, scale, points, color, dashSize, width = 1, gapSize = 0}) => {
    return (<mesh scale={scale} position={position}>
        <Line linewidth={width} toneMapped={false} points={points} color={color} dashed
              dashSize={dashSize / scale || dashSize} gapSize={gapSize / scale || gapSize}/>
    </mesh>)
}
export default LineElement
