import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const customerRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/customers'

export default class CustomerService {

    static async getAll() {
        return axios.get(customerRestUrl);
    }

    static async getAllUnable() {
        return axios.get(customerRestUrl + '/unable', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data) {
        await axios.post(customerRestUrl + '/' + +data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(customerRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(customerRestUrl + '/' + id)
    }

    static async unable(id, status) {
        return axios.patch(customerRestUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}