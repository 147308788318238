import React, {useEffect, useState} from 'react';
import IssuingEdgeWareHouseTable from "./IssuingEdgeWareHouseTable";
import MaterialWarehouseService from "../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import WarehouseMaterialSettingsService
    from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";

const ModulAddMaterialIssuingEdge = ({
                                         open,
                                         onClose,
                                         titleModal,
                                         id,
                                         change,
                                         setChange,
                                     }) => {

        const [data, setData] = useState([]);
        const [additionallyColumn, setAdditionallyColumn] = useState([]);

        const [baseCurrency, setBaseCurrency] = useState("");
        const [alternativeCurrency, setAlternativeCurrency] = useState("");

        const [dataMaterialWarehouse, setDataMaterialWarehouse] = useState([]);

        useEffect(() => {

            WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
                let data = response.data;
                setBaseCurrency(data.baseCurrency.curAbbreviation)
                setAlternativeCurrency(data.alternativeCurrency.curAbbreviation)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        useEffect(() => {

                MaterialWarehouseService.getAllByIssuingEdgeId(id).then(function (response) {
                    const data = response.data;
                    setDataMaterialWarehouse(data);
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, [change]
        )
        ;

        return (
            <div>
                <IssuingEdgeWareHouseTable
                    data={dataMaterialWarehouse}
                    setData={setDataMaterialWarehouse}
                    isPriceInCurrencyArrival={true}
                    isPriceInCurrencyAlternative={true}
                    isPriceInCurrencyBase={true}
                    isWarehouse={true}
                    isAddCutOffButton={true}
                    isIndentsButton={true}
                    isActions={true}
                    isColumnCut={true}
                    isColumnReserve={true}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    idIssuingEdge={id}
                    change={change}
                    setChange={setChange}
                />

                {/*<Dialog open={open} fullWidth={true} maxWidth='lg'>
                <DialogTitle textAlign="center">{titleModal}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: '0.2rem'}}>

                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>*/}
            </div>
        );
    }
;

export default ModulAddMaterialIssuingEdge;