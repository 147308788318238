import React from 'react';
import {Tooltip} from "@mui/material";

const CellMaterialEstimateBasePrice = ({value, price, typePrice}) => {

    const exchangeRateBasePrice = price === null ? null : price.exchangeRateBasePrice;

    const title = typePrice === 'ACTUAL' ? (exchangeRateBasePrice === null ? "По приходу" : 'Курс к базовой цене ' + exchangeRateBasePrice) : 'Курс к базовой цене ' + exchangeRateBasePrice;

    return (
        <div>
            <Tooltip arrow placement="right" title={title}>
                <span
                    size="small"
                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                    color="error">
                   {value}
                </span>
            </Tooltip>
        </div>
    );
};

export default CellMaterialEstimateBasePrice;