import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider, Tabs} from "@mui/material";
import FilesTable from "../../../../components/UI/table/common/files/FilesTable";
import FileService from "../../../../API/orders/orders/organization/FileService";
import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import EstimateItem from "../estimate/EstimateItem";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import RequestsCuttingEstimateItem from "../estimate/RequestsCuttingEstimateItem";
import TechnicalMapTable from "../../../../components/UI/table/orders/organization/technicalmaptable/TechnicalMapTable";
import JobService from "../../../../API/orders/orders/organization/job/JobService";

const OrderItem = () => {

        const params = useParams();
        const navigate = useNavigate();

        const [value, setValue] = useState('1');
        const year = params.year;
        const nameOrder = params.number;
        const orderId = params.id;
        const orderIdHeader = params.idHeader;

        const [path, setPath] = useState(null);
        const [change, setChange] = useState(null);

        const [technicalMapData, setTechnicalMapData] = useState([]);

        useEffect(() => {
            JobService.getJobsByOrderId(orderId).then(function (response) {
                setTechnicalMapData(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const redirectTechnicalMap = () => {
            navigate('/organization/technical-map/' + orderId + '/' + nameOrder);
        };

        const redirectOrders = () => {
            navigate('/organization/orders/');
        };

        const redirectSetAttribute = () => {
            navigate('/organization/orders/set-attribute/' + orderId + '/' + nameOrder + '/' + orderIdHeader + '/' + year);
        };

        const redirectOrderEstimates = () => {
            navigate('/organization/orders/' + orderId + '/' + nameOrder + '/' + orderIdHeader + '/' + year + '/estimates');
        };

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        return (
            <div>

                <Typography variant="h6" component="h6">
                    Заказ: {nameOrder}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        paddingLeft: '0.5rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.2rem',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        size="small"
                        color="secondary"
                        onClick={redirectOrders}
                        variant="contained"
                    >
                        Вернуться в заказы
                    </Button>
                    <Button
                        size="small"
                        color="success"
                        onClick={redirectSetAttribute}
                        variant="contained"
                    >
                        Добавить атрибут
                    </Button>
                </Box>
                <TabContext value={value}>
                    <Box sx={{
                        borderBottom: 1, borderColor: 'divider'
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Файлы" value='1'/>
                            <Tab label="Заявки на раскрой" value='3'/>
                            <Tab label="Модули" value='2'/>
                            <Tab label="Смета" value='4'/>
                            <Tab label="Тех карта" value='5'/>
                        </Tabs>
                    </Box>
                    <TabPanel value='1'>
                        <Box
                            sx={{display: ' block', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                            <Divider/>
                            <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'проекта'}
                                label={'Загрузить файлы проекта'}
                                getAllFile={FileService.getAllProjectFileNamesByOrderId}
                                fileNameType={'PROJECT'}
                            />
                            <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'замера'}
                                label={'Загрузить файлы замера'}
                                getAllFile={FileService.getAllMeasuringFileNamesByOrderId}
                                fileNameType={'MEASURING'}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='3'>
                        <RequestsCuttingEstimateItem
                            getAll={RequestsCuttingService.getAllByOrderIdWithMaterial}
                            isOptimization={true}
                            isRedirectRequestsCutting={true}
                            orderId={orderId}
                            nameOrder={nameOrder}
                            idHeader={orderIdHeader}
                            year={year}
                            isOrder={true}
                            isButtonAddRequestsCutting={true}
                            isEstimate={true}
                            isCreateOptimizationCalculation={false}
                            isSetForOptimization={true}
                        />

                    </TabPanel>
                    <TabPanel value='4'>
                        <EstimateItem
                            estimateId={1}
                            orderId={orderId}
                            orderNumber={nameOrder}
                            orderHeaderId={orderIdHeader}
                            year={year}
                            isButtonPurchase={true}
                            enableEditing={true}
                            isActual={true}
                            inWork={true}
                        />
                    </TabPanel>
                    <TabPanel value='5'>
                        <h3>Техническая карта заказа: {params.order}</h3>
                        <TechnicalMapTable
                            data={technicalMapData}
                            setData={setTechnicalMapData}
                            estimateIsCurrent={true}
                        />
                    </TabPanel>
                    <TabPanel value='6'>
                        <h3>Техническая карта заказа: {params.order}</h3>
                        {/*     <ModelsTable
                            data={dataModel}
                            setData={setDataModel}
                            isButtonDelete={true}
                            isButtonBasicModels={true}
                            isButtonCreateModel={false}
                            isButtonSettings={false}
                            isButtonUpdate={true}
                            isColumnUnable={false}
                            isRedirecrt={true}
                            isBasicModel={false}
                            enableRowSelection={false}
                            disableEditing={true}
                        />*/}
                    </TabPanel>
                </TabContext>


            </div>
        );
    }
;

export default OrderItem;