import React, {useEffect, useState} from 'react';
import CustomerService from "../../../../../../API/orders/orders/organization/CustomerService";
import HeaderService from "../../../../../../API/orders/orders/organization/HeaderService";
import StatusService from "../../../../../../API/orders/orders/organization/StatusService";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../../textfield/TextFieldWithSetValue";
import TextFieldObjectWithEntityList from "../../../../textfield/TextFieldObjectWithEntityList";
import OrderDatePicker from "../../../../datepicker/OrderDatePicker";

const UpdateOrderModal = ({
                              open,
                              onClose,
                              onSubmit,
                              values,
                              setValues,
                              change,
                              setChange,
                              dateOfReceipt,
                              setDateOfReceipt,
                              dateOfPlannedProduction,
                              setDateOfPlannedProduction,
                              plannedDateOfShipment,
                              setPlannedDateOfShipment,
                              manufactured,
                              setManufactured,
                              shipped,
                              setShipped
                          }) => {
    const [customerList, setCustomerList] = useState([]);
    const [modelHeaderList, setModelHeaderList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const [customerId, setCustomerId] = useState(null);
    const [modelHeaderId, setModelHeaderId] = useState(null);
    const [statusId, setStatusId] = useState(null);

    useEffect(() => {

        CustomerService.getAllUnable().then(function (response) {
            let data = response.data;
            setCustomerList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        HeaderService.getAllUnable().then(function (response) {
            const data = response.data;
            setModelHeaderList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        StatusService.getAll().then(function (response) {
            const data = response.data;
            setStatusList(data);

        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleSubmit = () => {

        values.dateOfReceipt = dateOfReceipt === null ? null : DateTimeUtil.dateConvert(dateOfReceipt);
        values.dateOfPlannedProduction = dateOfPlannedProduction === null ? null : DateTimeUtil.dateConvert(dateOfPlannedProduction);
        values.plannedDateOfShipment = plannedDateOfShipment === null ? null : DateTimeUtil.dateConvert(plannedDateOfShipment);
        values.manufactured = manufactured === null ? null : DateTimeUtil.dateConvert(manufactured);
        values.shipped = shipped === null ? null : DateTimeUtil.dateConvert(shipped);

        OrderService.update(values, customerId === null ? values.customerOrder.id : customerId,
            modelHeaderId === null ? values.header.id : modelHeaderId,
            statusId === null ? values.status.id : statusId,
        ).then(function (response) {
            onSubmit(response.data);
            setChange(!change)
            onClose();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Редактировать заказ</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        <TextFieldWithSetValue
                            label="Номер заказа"
                            values={values}
                            setValues={setValues}
                            field={'number'}
                        />
                        <TextFieldWithSetValue
                            label="Номер заказчика"
                            values={values}
                            setValues={setValues}
                            field={'numberCustomer'}
                        />
                        <TextFieldObjectWithEntityList
                            label="Заказчик"
                            values={values}
                            field={'customerOrder'}
                            setId={setCustomerId}
                            entityList={customerList}
                        />
                        <TextFieldObjectWithEntityList
                            label="Найменование"
                            values={values}
                            field={'header'}
                            setId={setModelHeaderId}
                            entityList={modelHeaderList}
                        />
                        <OrderDatePicker
                            label={'Дата поступления'}
                            date={dateOfReceipt}
                            setDate={setDateOfReceipt}
                        />
                        <TextFieldObjectWithEntityList
                            label="Статус"
                            values={values}
                            field={'status'}
                            setId={setStatusId}
                            entityList={statusList}
                        />
                        <OrderDatePicker
                            label={'Плановая дата производства'}
                            date={dateOfPlannedProduction}
                            setDate={setDateOfPlannedProduction}
                        />
                        <OrderDatePicker
                            label={'Произведено'}
                            date={manufactured}
                            setDate={setManufactured}
                        />
                        <OrderDatePicker
                            label={'Плановая дата отгрузки'}
                            date={plannedDateOfShipment}
                            setDate={setPlannedDateOfShipment}
                        />
                        <OrderDatePicker
                            label={'Отгружено'}
                            date={shipped}
                            setDate={setShipped}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выход</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateOrderModal;