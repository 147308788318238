import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class PointControllerService {
    static async save(detailId, afterPointId, data) {
        return await axios.post(`${detailControllerRestUrl}1/details/${detailId}/points`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {afterPointId: afterPointId}
        });
    }

    static async delete(detailId, pointID) {
        return await axios.delete(`${detailControllerRestUrl}1/details/${detailId}/points/${pointID}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async patch(detailId, pointID, data) {
        return await axios.patch(`${detailControllerRestUrl}1/details/${detailId}/points/${pointID}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async patchArc(detailId, pointID, data) {
        return await axios.patch(`${detailControllerRestUrl}1/details/${detailId}/points/${pointID}/arc`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async patchRound(detailId, pointID, data) {
        return await axios.put(`${detailControllerRestUrl}1/details/${detailId}/points/${pointID}/round`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async patchCut(detailId, pointID, data) {
        return await axios.put(`${detailControllerRestUrl}1/details/${detailId}/points/${pointID}/cut`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async previewArc(detailId, pointID, data) {
        return await axios.put(`${detailControllerRestUrl}1/details/${detailId}/points/${pointID}/preview-arc`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async previewRound(detailId, pointID, data) {
        return await axios.put(`${detailControllerRestUrl}1/details/${detailId}/points/${pointID}/preview-round-corner`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
